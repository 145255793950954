import { createAction, props } from '@ngrx/store';
import { INuxeoEntity } from '../../../shared/models/nuxeo-entity.model';
import { ILocalPermissionsEntries } from '../models/local-permissions.model';
import { IUser } from '@common/models/users.model';
import { IDocumentEntity } from '@shared/models/document.model';

export const SELECTED_DOCUMENT = '[document] selected document';
export const LOAD_SELECTED_DOCUMENT = '[document] load selected document';

export const SELECTED_USER = '[document] selected user';
export const LOAD_SELECTED_USER = '[document] load selected user';

export const LOCAL_PERMISSIONS = '[permissions] local permissions';
export const LOAD_LOCAL_PERMISSIONS = '[permissions] load local permissions';

export const selectedDocument = createAction(SELECTED_DOCUMENT, props<{ id: string, noNavigate?: boolean }>());
export const loadSelectedDocument = createAction(
  LOAD_SELECTED_DOCUMENT,
  props<{ selectedDocument: IDocumentEntity }>(),
);

export const selectedUser = createAction(SELECTED_USER, props<{ id: string }>());
export const loadSelectedUser = createAction(
  LOAD_SELECTED_USER,
  props<{ selectedUser: IUser }>(),
);

export const localPermissions = createAction(LOCAL_PERMISSIONS, props<{ id: string }>());
export const loadLocalPermissions = createAction(
  LOAD_LOCAL_PERMISSIONS,
  props<{ localPermissions: INuxeoEntity<ILocalPermissionsEntries> }>(),
);
