import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SelectItemState } from './select-items.state';
import { takeDisplayedData } from '../displayed-data/displayed-data.selector';

export const SELECT_ACTION_STATE_NAME = 'select-items';

const takeSelected = createFeatureSelector<SelectItemState>(SELECT_ACTION_STATE_NAME);

export const selectedItems = createSelector(takeSelected, selectActions =>
  selectActions.selectedItems.filter(v => !!v.isSelected),
);

const isSelectAll = createSelector(
  takeSelected,
  selectActions => selectActions.selectAllRows,
);

export const takeIsSelectAllRows = createSelector(
  selectedItems,
  takeDisplayedData,
  isSelectAll,
  (selectedItems, takeDisplayedData, selectAllRows) => {
    if (takeDisplayedData?.length) {
      return selectedItems.length === takeDisplayedData.length;
    } else {
      return selectAllRows;
    }
  },
);

export const isActiveSelectedItem = createSelector(
  selectedItems,
  items => !!items.find(v => v.isSelected),
);
