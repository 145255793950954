import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ISearchData } from '../models/search-data.model';
import { INuxeoEntity } from '../../../shared/models/nuxeo-entity.model';
import { ILocalPermissionsEntries } from '../models/local-permissions.model';
import { IPermissionParams } from '../models/params.model';
import { IUser } from '@common/models/users.model';
import { IDocumentHistory } from '../models/document-history.model';
import {
  DOCUMENT_PROPERTIES_QUERY_PARAMETER,
  IDocument,
  IDocumentEntity,
} from '@shared/models/document.model';
import { createRequestOption } from '@shared/util/request.util';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private nuxeoUrl = `${environment.nuxeoURL}`;
  private nuxeoApiUrl = `${environment.nuxeoApiUrl}`;

  constructor(private http: HttpClient) {}

  getAllDocuments(): Observable<IDocument[]> {
    const params = createRequestOption({
      'enrichers.document': 'audit',
      properties: DOCUMENT_PROPERTIES_QUERY_PARAMETER,
    });

    return this.http.get<IDocument[]>(
      `${this.nuxeoUrl}/search/pp/advanced_document_content/execute`,
      { params },
    );
  }

  getDocumentsPerFile(): Observable<IDocument[]> {
    return this.http.get<IDocument[]>(
      `${this.nuxeoUrl}/search/pp/advanced_document_content/execute?pageSize=0&PageIndex=0&enrichers.document=audit,breadcrumb,thumbnail&properties=uid,image_metadata,file,common,dublincore,relatedtext,picture,facetedTag`,
    );
  }

  getDocumentPermissions(id: string) {
    return this.http.get(
      `${this.nuxeoUrl}/id/${id}?enrichers.document=thumbnail,preview,documentURL,breadcrumb,subtypes,children,hasContent,hasFolderishChild,favorites,collections,tags,firstAccessibleAncestor,acls,permissions,publications,userVisiblePermissions,audit,subscribedNotifications,userprofile&properties=*`,
    );
  }

  getHistoricalDataForDocument(id: string): Observable<IDocumentHistory> {
    const query = {
      currentPageIndex: 0,
      pageSize: 40,
    };

    // https://api-dev-v2.dms.malogica.com/nuxeo/api/v1/id/800c2fd5-4e4f-4f99-b925-50c0c4b05e7b/@audit?currentPageIndex=0&pageSize=40

    return this.http.get<IDocumentHistory>(`${this.nuxeoUrl}/id/${id}/@audit`, {
      params: createRequestOption(query),
    });
  }

  getFavorites(): Observable<IDocument[]> {
    return this.http.get<IDocument[]>(`${this.nuxeoApiUrl}/documents/favorites`);
  }

  getContentsPublishing(id: string) {
    return this.http.get<IDocumentHistory>(
      `${this.nuxeoUrl}/id/${id}/?enrichers.document=thumbnail,preview,documentURL,breadcrumb,subtypes,children,hasContent,hasFolderishChild,favorites,collections,tags,firstAccessibleAncestor,acls,permissions,publications,userVisiblePermissions,audit,subscribedNotifications,userprofile&properties=*`,
    );
  }

  getSearchedData(text: string): Observable<ISearchData[]> {
    return this.http.post<ISearchData[]>(
      `${this.nuxeoUrl}/automation/Search.SuggestersLauncher`,
      { params: { searchTerm: text } },
    );
  }

  getSelectedDocument(id: string): Observable<IDocumentEntity> {
    let params = new HttpParams();

    // to headers
    params = params.append('properties', '*');
    params = params.append('enrichers', 'breadcrumb,hasFolderishChild,children');

    // let headers = new HttpHeaders();
    params = params.append(
      'enrichers-document',
      `hasContent,firstAccessibleAncestor,permissions,breadcrumb,preview,favorites,subscribedNotifications,thumbnail,renditions,pendingTasks,runnableWorkflows,runningWorkflows,collections,audit,subtypes,tags,publications`,
    );
    params = params.append('fetch-directoryentry', 'parent');
    params = params.append('fetch-document', 'properties,lock');
    params = params.append('fetch-task', 'actors');

    return this.http.get<IDocumentEntity>(`${this.nuxeoUrl}/id/${id}`, {
      params,
    });
  }

  getSelectedDocumetBloB(docPath: string, fieldPath: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.nuxeoUrl}/id/${docPath}/@blob/${fieldPath}`, {
      responseType: 'blob' as 'json',
    });
  }

  // getSelectedDocumentByUid(uid: string): Observable<IDocumentHistory> {
  //   return this.http.get<IDocumentHistory>(
  //     `${this.nuxeoUrl}/id/${uid}/@audit?currentPageIndex=0&pageSize=40`,
  //   );
  // }

  getSelectedUser(id: string): Observable<IUser> {
    return this.http.get<IUser>(`${this.nuxeoUrl}/user/${id}`);
  }

  addUserToGroup(userName: string, groupInput: string) {
    const body = {
      params: {
        searchTerm: groupInput,
        searchType: 'GROUP_TYPE',
      },
      context: {},
    };

    return this.http.post<INuxeoEntity<ILocalPermissionsEntries>>(
      // `${this.nuxeoUrl}/automation/Repository.Query`,
      `${this.nuxeoUrl}/user/${userName}/group/${groupInput}`,
      body,
    );
  }

  deleteUserFromGroup(userName: string, groupInput: string) {
    return this.http.delete<INuxeoEntity<ILocalPermissionsEntries>>(
      `${this.nuxeoUrl}/user/${userName}/group/${groupInput}`,
    );
  }

  postLocalPermissions(id: string): Observable<INuxeoEntity<ILocalPermissionsEntries>> {
    const headers = new HttpHeaders({ 'enrichers-document': 'acls' });
    const body = {
      params: {
        // eslint-disable-next-line no-useless-escape
        query: `SELECT * FROM Document WHERE ecm:mixinType != \"HiddenInNavigation\"AND ecm:isProxy = 0 AND ecm:isVersion = 0 AND ecm:isTrashed = 0AND ecm:acl/*1/principal = \"${id}\"`,
      },
    };

    //  check this
    return this.http.post<INuxeoEntity<ILocalPermissionsEntries>>(
      `${this.nuxeoUrl}/automation/Repository.Query`,
      body,
      { headers },
    );
  }

  addLocalPermissions(params: IPermissionParams): Observable<ILocalPermissionsEntries> {
    const body = {
      params,
    };

    return this.http.post<ILocalPermissionsEntries>(
      `${this.nuxeoUrl}/automation/Document.AddPermission`,
      body,
    );
  }

  deleteLocalPermissions(uid: string, id: string) {
    const body = {
      params: {
        id,
      },
      input: uid,
    };

    return this.http.post(`${this.nuxeoUrl}/automation/Document.RemovePermission`, body);
  }

  blockInheritedPermissions(uid: string) {
    const body = {
      params: {},
      input: uid,
      context: {},
    };

    return this.http.post(
      `${this.nuxeoUrl}/automation/Document.BlockPermissionInheritance`,
      body,
    );
  }

  unBlockInheritedPermissions(uid: string) {
    const body = {
      params: {},
      input: uid,
      context: {},
    };

    return this.http.post(
      `${this.nuxeoUrl}/automation/Document.UnblockPermissionInheritance`,
      body,
    );
  }

  addUpdatePermissionsExternalUsers(
    params: IPermissionParams,
    uid: string,
  ): Observable<INuxeoEntity<ILocalPermissionsEntries>> {
    const body = {
      params,
      input: uid,
      context: {},
    };

    return this.http.post<INuxeoEntity<ILocalPermissionsEntries>>(
      `${this.nuxeoUrl}/automation/Document.AddPermission`,
      body,
    );
  }

  sendEmailNotificationExternalUser(uid: string) {
    const body = {
      params: {
        id: '<transient:<email input>:<permission>:true:username of remover:end:begin>',
      },
      input: uid,
      context: {},
    };

    return this.http.post(
      `${this.nuxeoUrl}/Document.SendNotificationEmailForPermission`,
      body,
    );
  }
}
