// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=checkbox].primary {
  min-height: 15px;
  min-width: 15px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  outline: none;
  border-radius: 4px;
  border: 1px solid var(--checkboxChecked);
  accent-color: var(--checkboxChecked);
}
input[type=checkbox].primary:focus {
  outline: 1px solid var(--primary) !important;
}

input[type=checkbox].primary:checked {
  -moz-appearance: auto;
  -webkit-appearance: auto;
  -o-appearance: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/dms-checkbox/dms-checkbox.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,eAAA;EACA,qBAAA;EACA,wBAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,wCAAA;EACA,oCAAA;AACF;AACE;EACE,4CAAA;AACJ;;AAGA;EACE,qBAAA;EACA,wBAAA;EACA,mBAAA;AAAF","sourcesContent":["input[type='checkbox'].primary {\n  min-height: 15px;\n  min-width: 15px;\n  cursor: pointer;\n  -moz-appearance: none;\n  -webkit-appearance: none;\n  -o-appearance: none;\n  outline: none;\n  border-radius: 4px;\n  border: 1px solid var(--checkboxChecked);\n  accent-color: var(--checkboxChecked);\n\n  &:focus {\n    outline: 1px solid var(--primary) !important;\n  }\n}\n\ninput[type='checkbox'].primary:checked {\n  -moz-appearance: auto;\n  -webkit-appearance: auto;\n  -o-appearance: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
