import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { IActiveSearchParam } from '../models/search.model';
import { ICategorizationItem } from '../models/categorization.model';
import { ISavedSearchParams } from '../models/saved-search.model';
import { IDocumentEntity } from '@shared/models/document.model';
import { IPageInfo } from 'src/app/models/app.model';

export const SEARCH_RESULT = '[searchResult] search result';

export const SET_OPTIONS = '[options] set options';
export const SEARCH_DETAILS_OPEN = '[searchResult] search details open';

export const setOptions = createAction(
  SET_OPTIONS,
  props<{ options: Partial<ISavedSearchParams> }>(),
);

export const toggleSearchDetails = createAction(
  SEARCH_DETAILS_OPEN,
  props<{ isOpend: boolean }>(),
);

export const toggleSearchMenu = createAction(
  '[search menu] toggle search menu',
  props<{ isOpend: boolean }>(),
);


export const TagsActions = createActionGroup({
  source: '[tags]',
  events: {
    'Load Tags': props<{ id: string }>(),
    'Load Tags Success': props<{ tags: any }>(),
  },
});

export const CategorizationActions = createActionGroup({
  source: '[categorization]',
  events: {
    'Load Categorization Item': props<{
      itemName: string;
      searchTerm?: string;
      page?: IPageInfo;
    }>(),
    'Load Categorization Item Success': props<{
      categorizationItem: ICategorizationItem[];
      itemName: string;
    }>(),
  },
});

export const ActiveSearchActions = createActionGroup({
  source: '[searchResult]',
  events: {
    'Set Search': props<{ result: IActiveSearchParam }>(),
    'Set Search Success': props<{ result: IActiveSearchParam }>(),
    'Clear Search': emptyProps(),
    'Remove Search': props<{ key: string; paramName: string }>(),
    'Remove Search Item': props<{
      item: Pick<IActiveSearchParam, 'key' | 'paramName' | 'value'>;
    }>(),
    'Load Search': emptyProps(),
    'Load Search Success': props<{ result: IDocumentEntity[] }>(),
  },
});
