import { ComponentType } from '@angular/cdk/portal';
import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ViewContainerRefAdapter {
  private componentsList: ComponentType<any>[] = [];
  private _vcr: ViewContainerRef;

  get vcr() {
    return this._vcr;
  }

  set vcr(containerRef: ViewContainerRef) {
    this._vcr = containerRef;
  }

  createComponent(component: ComponentType<any>) {
    const isPresent = this.componentsList.find(v => v === component);
    if (!isPresent) {
      this.componentsList.push(component);
    }

    return this.vcr.createComponent(component);
  }

  remove(component: ComponentType<any>) {
    console.log(component);
    const index = this.componentsList.findIndex(v => v === component);
    this.componentsList = this.componentsList.filter(v => v !== component);
    if (this.vcr.get(index)) {
      this.vcr.remove(index);
    } else {
      console.error(`Can not get ViewContainer`);
    }
  }

  clear() {
    this.vcr.clear(); // temporarily
  }
}
