export interface IGroupProperties {
  tenantId: any;
  description: string;
  grouplabel: string;
  groupname: string;
}

export interface IGroup {
  'entity-type': string;
  id: string;
  grouplabel: string;
  memberUsers: string[];
  properties?: IGroupProperties;
  groupname?: string;
  avatar?: string;
}

export type UpdatedGroup = Omit<IGroup, 'properties'>;

export class DisplayedGroup {
  constructor(
    public id: string,
    public groupname: string,
    public memberUsers: string[],
    public grouplabel: string,
    public avatar = `<i class="fa-solid fa-user-group"></i>`,
  ) {}
}

export function transformGroupToOldFormat(newGroupData: any): any {
  return newGroupData.map(
    (group: any) =>
      new DisplayedGroup(group.groupName, group.groupName, group.users, group.groupLabel),
  );
}
