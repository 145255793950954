import { Component, EventEmitter, Input, inject } from '@angular/core';
import { AlertService } from '@shared/components/alert/services/alert.service';
import {
  CommonModal,
  ModalParams,
  ModalTheme,
} from '@shared/components/modal/modal.model';
import { ModalService } from '@shared/components/modal/services/modal.service';
import { Alert } from 'src/app/models/alert.model';
import { DocumentActionsComponent } from '../../../document-actions/document-actions.component';
import { InitializeAndUpload } from '@shared/util/file.util';

@Component({
  selector: '[dms-audio]',
  template: `<audio controls>
      <source [src]="fileData.data" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
    <dms-document-actions
      (download)="downloadAudio()"
      (update)="updateVideo($event)"
      [type]="'Audio'"
      [actions]="[
        { key: 'download', title: 'Download' },
        { key: 'update', title: 'Update' }
      ]">
    </dms-document-actions>`,
  imports: [DocumentActionsComponent],
  standalone: true,
})
export class AudioComponent {
  modalService = inject(ModalService);
  alertService = inject(AlertService);
  submit = new EventEmitter();

  private _fileData: any;

  @Input()
  set fileData(value: any) {
    this._fileData = value;
  }

  get fileData(): any {
    return this._fileData;
  }

  downloadAudio() {
    const modalRef = this.openModal();
    modalRef.confirm.subscribe(() => {
      this.download();
      this.alertService.setAlert(
        new Alert(`Download`, `${this.fileData.name} Downloaded successfuly`, 'info'),
      );
    });
  }

  updateVideo(event) {
    const file = event.target.files[0];
    if (file) {
      InitializeAndUpload(file, this.submitVideo);
    }
  }

  submitVideo = (base46: string) => {
    this.submit.emit(base46);
  };

  private download() {
    const a = document.createElement('a');
    a.href = this.fileData.data;
    a.download = this.fileData.name || 'download';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private openModal() {
    return this.modalService.setConfirmModal(
      new CommonModal(
        new ModalParams(
          {
            title: 'Download the audio?',
            description: `
                    Are you sure you want to download this audio?.
                    `,
          },
          'Download',
        ),
        new ModalTheme('info'),
      ),
    );
  }
}
