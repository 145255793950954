// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.light .sidebar-menu-container li.active {
  background: var(--linear-gradient-1) !important;
}
body.light .sidebar-menu-container li.active a {
  color: var(--white) !important;
}
body.light .sidebar-menu-container li a {
  color: var(--primary);
}
@media only screen and (min-width: 850px) {
  body.light .sidebar-menu-container li:hover:not(.active) {
    border-color: var(--linear-gradient-1);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/layout/sidebar/themes/sidebar-light.scss"],"names":[],"mappings":"AAEI;EACE,+CAAA;AADN;AAEM;EACE,8BAAA;AAAR;AAII;EACE,qBAAA;AAFN;AAME;EACE;IACE,sCAAA;EAJJ;AACF","sourcesContent":["body.light {\n  .sidebar-menu-container li {\n    &.active {\n      background: var(--linear-gradient-1) !important;\n      a {\n        color: var(--white) !important;\n      }\n    }\n\n    a {\n      color: var(--primary);\n    }\n  }\n\n  @media only screen and (min-width: 850px) {\n    .sidebar-menu-container li:hover:not(.active) {\n      border-color: var(--linear-gradient-1);\n    }\n  }\n\n  .hidden-sidebar-desktop {\n    // color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
