// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.dark .sidebar-menu-container li.active {
  background: var(--linear-gradient-6) !important;
}
body.dark .sidebar-menu-container li a {
  color: var(--white);
}
@media only screen and (min-width: 850px) {
  body.dark .sidebar-menu-container li:hover:not(.active) {
    border-color: var(--info);
    color: var(--info) !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/layout/sidebar/themes/sidebar-dark.scss"],"names":[],"mappings":"AAEI;EACE,+CAAA;AADN;AAII;EACE,mBAAA;AAFN;AAME;EACE;IACE,yBAAA;IACA,6BAAA;EAJJ;AACF","sourcesContent":["body.dark {\n  .sidebar-menu-container li {\n    &.active {\n      background: var(--linear-gradient-6) !important;\n    }\n\n    a {\n      color: var(--white);\n    }\n  }\n\n  @media only screen and (min-width: 850px) {\n    .sidebar-menu-container li:hover:not(.active) {\n      border-color: var(--info);\n      color: var(--info) !important;\n    }\n  }\n\n  .hidden-sidebar-desktop {\n    // color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
