import { Component, Input } from '@angular/core';

@Component({
  selector: '[dms-artwork]',
  template: `<iframe
    [src]="fileData.data"
    frameborder="0"
    width="800"
    height="600"></iframe>`,
  standalone: true,
})
export class ArtworkComponent {
  @Input() fileData: any;
}
