// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  gap: 70px;
  padding: 150px 100px;
}
.not-found-container h1 {
  font-size: 150px;
  margin-bottom: 0;
  color: var(--info);
}
.not-found-container .warning {
  flex: 3;
  font-size: 20px;
  font-weight: 700;
}
.not-found-container .warning span {
  color: var(--primary);
}
.not-found-container .info {
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/not-found/not-found.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,YAAA;EACA,SAAA;EACA,oBAAA;AACF;AAAE;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;AAEJ;AAAE;EACE,OAAA;EACA,eAAA;EACA,gBAAA;AAEJ;AADI;EACE,qBAAA;AAGN;AAAE;EACE,OAAA;AAEJ","sourcesContent":[".not-found-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n  height: 100%;\n  gap: 70px;\n  padding: 150px 100px;\n  h1 {\n    font-size: 150px;\n    margin-bottom: 0;\n    color: var(--info);\n  }\n  .warning {\n    flex: 3;\n    font-size: 20px;\n    font-weight: 700;\n    span {\n      color: var(--primary);\n    }\n  }\n  .info {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
