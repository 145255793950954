import { Component, EventEmitter, Input, inject } from '@angular/core';
import { AlertService } from '@shared/components/alert/services/alert.service';
import {
  CommonModal,
  ModalParams,
  ModalTheme,
} from '@shared/components/modal/modal.model';
import { ModalService } from '@shared/components/modal/services/modal.service';
import { Alert } from 'src/app/models/alert.model';
import { DocumentActionsComponent } from '../../../document-actions/document-actions.component';
import { InitializeAndUpload } from '@shared/util/file.util';

@Component({
  selector: '[dms-video]',
  template: `<video #videoPlayer controls>
      <source [src]="fileData.data" type="video/mp4" />
      Your browser does not support the video element.
    </video>

    <dms-document-actions
      (view)="switchToViewMode(videoPlayer)"
      (download)="downloadVideo()"
      (update)="updateVideo($event)"
      [type]="'Video'"
      [actions]="[
        { key: 'download', title: 'Download' },
        { key: 'view', title: 'View' },
        { key: 'update', title: 'Update' }
      ]">
    </dms-document-actions>`,
  styles: `
      video {
        min-width: 500px;
        width: 100%;
      }
    `,
  imports: [DocumentActionsComponent],
  standalone: true,
})
export class VideoComponent {
  modalService = inject(ModalService);
  alertService = inject(AlertService);
  submit = new EventEmitter();

  private _fileData: any;

  @Input()
  set fileData(value: any) {
    this._fileData = value;
  }

  get fileData(): any {
    return this._fileData;
  }

  downloadVideo() {
    const modalRef = this.openModal();
    modalRef.confirm.subscribe(() => {
      this.download();
      this.alertService.setAlert(
        new Alert(`Download`, `${this.fileData.name} Downloaded successfuly`, 'info'),
      );
    });
  }

  updateVideo(event) {
    const file = event.target.files[0];
    if (file) {
      InitializeAndUpload(file, this.submitVideo);
    }
  }

  submitVideo = (base46: string) => {
    this.submit.emit(base46);
  };

  switchToViewMode(videoPlayer: HTMLVideoElement) {
    const video = videoPlayer;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if ((video as any).mozRequestFullScreen) {
      /* Firefox */
      (video as any).mozRequestFullScreen();
    } else if ((video as any).webkitRequestFullscreen) {
      /* Chrome, Safari & Opera */
      (video as any).webkitRequestFullscreen();
    } else if ((video as any).msRequestFullscreen) {
      /* IE/Edge */
      (video as any).msRequestFullscreen();
    }
  }

  private download() {
    const a = document.createElement('a');
    a.href = this.fileData.data;
    a.download = this.fileData.name || 'download';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private openModal() {
    return this.modalService.setConfirmModal(
      new CommonModal(
        new ModalParams(
          {
            title: 'Download the video?',
            description: `
                    Are you sure you want to download this video?.
                    `,
          },
          'Download',
        ),
        new ModalTheme('info'),
      ),
    );
  }
}
