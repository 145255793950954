
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'dms-tag',
  templateUrl: './tags.component.html',
  standalone: true,
  imports: [],
  styleUrls: ['./tags.component.scss'],
})
export class DmsTagsComponent {
  @Input() content: string;
  @Input() index: number;
  @Input() styles: any;
  @Input() onlyView: boolean;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('title') displayName: string;

  @Output() clickEvent = new EventEmitter();

  constructor() {}

  emit() {
    this.clickEvent.emit({ id: this.index, value: this.content });
  }
}
