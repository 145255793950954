import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { VIEW_TYPE } from 'src/app/models/app.model';
import { setView } from 'src/app/state/view-mode/view-mode.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { takeViewMode } from 'src/app/state/view-mode/view-mode.selector';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'dms-view-switcher',
  templateUrl: './view-switcher.component.html',
  styleUrls: ['./view-switcher.component.scss'],
  standalone: true,
  imports: [AsyncPipe],
  encapsulation: ViewEncapsulation.None,
})
export class ViewSwitcherComponent implements OnInit {
  view$: Observable<VIEW_TYPE>;
  viewType = VIEW_TYPE;

  constructor(private store: Store) {}

  changeView(view: VIEW_TYPE, event: Event) {
    event.stopPropagation();
    this.store.dispatch(setView({ view }));
  }

  ngOnInit() {
    this.view$ = this.store.select(takeViewMode);
  }
}
