import { createReducer, on } from '@ngrx/store';
import { SelectItemState, initialState } from './select-items.state';
import { setSelectedRows, setSelectedRow } from './select-items.action';

const _selectActionsReducer = createReducer(
  initialState,
  on(setSelectedRows, (state: SelectItemState, { selectedItems }) => {
    const selectAllRows =
      selectedItems.filter(v => v.isSelected).length === selectedItems.length;

    return {
      ...state,
      selectAllRows,
      selectedItems,
    };
  }),

  on(setSelectedRow, (state: SelectItemState, { selectedItem }) => {
    const selectedItems = [
      ...state.selectedItems.filter(v => v.key !== selectedItem.key),
      selectedItem,
    ];

    return {
      ...state,
      selectedItems,
    };
  }),

  // on(selectAllRows, (state: SelectItemState, { isSelectAll }) => ({
  //   ...state,
  //   selectAllRows: isSelectAll,
  // })),
);

export function SelectActionsReducer(state, action) {
  return _selectActionsReducer(state, action);
}
