import { createReducer, on } from '@ngrx/store';
import {
  loadLocalPermissions,
  loadSelectedDocument,
  loadSelectedUser,
} from './home.action';
import { HomeState, initialState } from './home.state';

const _homeReducer = createReducer(
  initialState,
  on(loadSelectedDocument, (state: HomeState, { selectedDocument }) => ({
    ...state,
    selectedDocument,
  })),
  on(loadSelectedUser, (state: HomeState, { selectedUser }) => ({
    ...state,
    selectedUser,
  })),
  on(loadLocalPermissions, (state: HomeState, { localPermissions }) => ({
    ...state,
    localPermissions,
  })),
);

export function HomeReducer(state: any, action: any) {
  return _homeReducer(state, action);
}
