import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, Observable } from 'rxjs';
import { INuxeoEntity } from '@shared/models/nuxeo-entity.model';
import { createRequestOption } from '@shared/util/request.util';
import { IGroup, transformGroupToOldFormat } from '@common/models/groups.model';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  private nuxeoUrl = `${environment.nuxeoURL}`;
  private resourceUrl = `${this.nuxeoUrl}/group`;
  private nuxeoApiUrl = `${environment.nuxeoApiUrl}`;

  constructor(private http: HttpClient) {}

  all(): Observable<INuxeoEntity<IGroup>> {
    return this.http.get<INuxeoEntity<IGroup>>(`${this.nuxeoApiUrl}/user-groups`).pipe(
      map(v => {
        const data = transformGroupToOldFormat(v);
        return data;
      }),
    );
  }

  create(group: IGroup): Observable<IGroup> {
    const params = createRequestOption({ q: '*', 'fetch.group': 'memberUsers' });

    const newGroup = {
      groupName: group['grouplabel'],
      groupLabel: group['grouplabel'],
      users: group['memberUsers'],
      permissions: null
    };
    
    // permissions: [
    //   {
    //     documentId: 'bf756fe0-4fcf-49f8-9f1a-01e7b3bb2d6d',
    //     permissionTypes: ['READ'], //can be READ,WRITE, or EVERYTHING
    //   },
    // ],
    return this.http.post<IGroup>(`${this.nuxeoApiUrl}/user-groups`, newGroup, {
      params,
    });
  }

  update(group: IGroup): Observable<IGroup> {
    const params = createRequestOption({ q: '*', 'fetch.group': 'memberUsers' });
    return this.http.put<IGroup>(`${this.resourceUrl}/${group.groupname}`, group, {
      params,
    });
  }

  delete(groupname: string): Observable<any> {
    return this.http.delete(`${this.nuxeoApiUrl}/user-groups/${groupname}`);
  }
}
