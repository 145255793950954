import {
  Component,
  OnInit,
  inject,
  ChangeDetectionStrategy,
  WritableSignal,
  signal,
  DestroyRef,
  effect,
  computed,
} from '@angular/core';
import { tap } from 'rxjs';
import { Alert } from '../../../models/alert.model';
import { AlertService } from './services/alert.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'dms-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit {
  // Alert to Toast
  alertService = inject(AlertService);
  alerts: WritableSignal<Alert[]>;
  destroyRef = inject(DestroyRef);
  iconByType;

  isEven = computed(() => {
    console.log(`Computed!`);
    return this.alerts().length % 2 === 0;
  });

  constructor() {
    effect(() => {
      console.log(`Effect`, this.alerts().length);
      this.isEven();
    });
  }

  ngOnInit() {
    this.alerts = signal([]);
    this.iconByType = {
      success: 'fa-circle-check',
      error: 'fa-warning',
      info: 'fa-circle-info',
      warning: 'fa-warning',
    };

    this.alertService.alerts$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((alert: Alert) => {
          alert.timeOut = setTimeout(() => {
            this.hideAlertItem(alert, true);
          }, alert.ms);

          this.setAlert(alert);
        }),
      )
      .subscribe();
  }

  setAlert(alert: Alert) {
    this.alerts.update(alerts => [...alerts, alert]);
  }

  hide(alert: Alert) {
    clearTimeout(alert.timeOut);
    this.hideAlertItem(alert, true);
  }

  hideAlertItem(alert: Alert, isMutate: boolean) {
    alert.animateClass = 'out';
    setTimeout(() => {
      if (isMutate) {
        this.alerts.update(alerts => alerts.filter(v => v !== alert));
      } else {
        this.alerts.update(alerts => [...alerts.splice(-1)]);
      }

      if (!this.alerts().length) {
        this.alertService.destroy(AlertComponent);
        // check after Modal
      }
    }, 790);
  }
}
