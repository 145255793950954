import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { DEVICE } from '@shared/models/device.model';
import { DeviceService } from '@shared/services/device.service';
import { currentRoute } from '@state/router-state/router.selector';
import { toggleSidebar } from '@state/sidebar-menu/sidebar-menu.action';
import { filter, tap } from 'rxjs/operators';
import { ISedebarMenu } from './models/sidebar.model';
import { setModal } from '@state/modal/modal.action';
import { Location, CommonModule } from '@angular/common';
import { ToggleSidebarSvgComponent } from './icons/svg.component';
import { LetDirective } from '@shared/directives/let.directive';

@Component({
  selector: 'dms-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: [
    './sidebar.component.scss',
    './themes/sidebar-dark.scss',
    './themes/sidebar-light.scss',
  ],
  standalone: true,
  imports: [CommonModule, LetDirective, ToggleSidebarSvgComponent, RouterModule],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  isOpend = false;
  activeGroup: number;
  DEVICE = DEVICE;
  activeTab: ISedebarMenu = {
    groupUrl: 'home',
    tabUrl: 'dashboard',
    groupClassName: 'home',
  };

  sidebarMenu = [
    {
      groupUrl: 'home',
      tabUrl: 'dashboard',
      groupClassName: 'home',
      iconClassName: 'fa-house',
    },
    {
      groupUrl: 'manage',
      tabUrl: '', // look this set here active path
      groupClassName: 'manage',
      iconClassName: 'fa-folder-closed',
    },
    {
      groupUrl: 'search',
      tabUrl: 'results',
      groupClassName: 'search',
      iconClassName: 'fa-magnifying-glass',
    },
    {
      groupUrl: 'admin',
      tabUrl: 'system-information',
      groupClassName: 'admin',
      iconClassName: 'fa-user-shield',
      tabClassName: 'admin',
    },
  ];

  constructor(
    private router: Router,
    private store: Store,
    public deviceService: DeviceService,
    private location: Location,
  ) {}

  ngOnInit() {
    this.store
      .select(currentRoute)
      .pipe(
        filter(v => !!v?.url),
        tap(route => {
          const groupUrl = route.url.split('/')[1];
          this.activeGroup = this.sidebarMenu.findIndex(url => url.groupUrl === groupUrl);
        }),
      )
      .subscribe();
  }

  toggleDesktopSidebar(device: DEVICE) {
    this.isOpend = !this.isOpend;
    this.store.dispatch(toggleSidebar({ isOpend: !!this.isOpend }));
    this.closeSidebar(device);
  }

  closeSidebar(device: DEVICE) {
    if (device === 'PORTRIAT_TABLET') {
      this.store.dispatch(setModal({ modal: { status: false } }));
    }
  }

  back() {
    this.location.back();
  }

  navigate(menu: ISedebarMenu, index: number) {
    this.activeGroup = index;
    // let url = `${menu.groupUrl}`;

    // if (menu.tabUrl) {
    //   url += `/${menu.tabUrl}`;
    // }

    // if (this.device === DEVICE.TABLET) {
    //   this.router.navigate([{ outlets: { tablet: [url.split(`/`)[0]] } }]);
    // }

    // this.router.navigate([url]);
  }
}
