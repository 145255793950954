// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-actions-wrapper {
  display: flex;
  align-items: center;
  gap: 15px;
}
.document-actions-wrapper .menu {
  margin-left: -10px;
  margin-top: -5px;
}
.document-actions-wrapper i {
  font-size: 20px;
  color: var(--darkGrey);
}
.document-actions-wrapper button {
  background-color: transparent;
  padding: 0;
}
.document-actions-wrapper .button,
.document-actions-wrapper input {
  cursor: pointer;
}
.document-actions-wrapper dialog {
  transition: 0.5s;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  text-align: center;
}
.document-actions-wrapper dialog i {
  position: absolute;
  right: 10px;
  color: var(--danger);
  font-size: 36px;
}
.document-actions-wrapper dialog img {
  height: 100%;
  max-width: 100%;
}
.document-actions-wrapper input[type=file] {
  position: absolute;
  opacity: 0;
  width: 15px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/home/components/document-details/components/document-actions/document-actions.component.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,SAAA;AADF;AAEE;EACE,kBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,eAAA;EACA,sBAAA;AAAJ;AAGE;EACE,6BAAA;EACA,UAAA;AADJ;AAIE;;EAEE,eAAA;AAFJ;AAKE;EACE,gBAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;AAHJ;AAII;EACE,kBAAA;EACA,WAAA;EACA,oBAAA;EACA,eAAA;AAFN;AAII;EACE,YAAA;EACA,eAAA;AAFN;AAME;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,eAAA;AAJJ","sourcesContent":["@import 'src/assets/styles/custom-material.scss';\n\n.document-actions-wrapper {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n  .menu {\n    margin-left: -10px;\n    margin-top: -5px;\n  }\n  i {\n    font-size: 20px;\n    color: var(--darkGrey);\n  }\n\n  button {\n    background-color: transparent;\n    padding: 0;\n  }\n\n  .button,\n  input {\n    cursor: pointer;\n  }\n\n  dialog {\n    transition: 0.5s;\n    position: absolute;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 9;\n    text-align: center;\n    i {\n      position: absolute;\n      right: 10px;\n      color: var(--danger);\n      font-size: 36px;\n    }\n    img {\n      height: 100%;\n      max-width: 100%;\n    }\n  }\n\n  input[type='file'] {\n    position: absolute;\n    opacity: 0;\n    width: 15px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
