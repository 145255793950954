import { createReducer, on } from '@ngrx/store';
import { setView } from './view-mode.actions';
import { initialState, ViewModeState } from './view-mode.state';

const _viewModeReducer = createReducer(
  initialState,
  on(setView, (state: ViewModeState, { view }) => ({
    ...state,
    view,
  })),
);

export function ViewModeReducer(state, action) {
  return _viewModeReducer(state, action);
}
