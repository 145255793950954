// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-container {
  transition: 0.2s;
  color: #000;
  padding: 10px 5px;
  width: 100%;
  border: 1px solid var(--layout);
}
.message-container .day {
  width: 100%;
  text-align: center;
  color: var(--primary);
}
.message-container .message {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  padding: 20px 0;
  font-size: 14px;
}
.message-container .message .username {
  color: var(--primary);
}
.message-container .message .message {
  padding: 10px;
  background-color: #e7e7e7;
  color: var(--darkGrey);
  border-radius: 20px;
  border-top-left-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/common/notifications/messages/chat/chat.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;EACA,iBAAA;EACA,WAAA;EACA,+BAAA;AACF;AAAE;EACE,WAAA;EACA,kBAAA;EACA,qBAAA;AAEJ;AACE;EACE,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;AACJ;AAAI;EACE,qBAAA;AAEN;AAAI;EACE,aAAA;EACA,yBAAA;EACA,sBAAA;EACA,mBAAA;EACA,yBAAA;AAEN","sourcesContent":[".message-container {\n  transition: 0.2s;\n  color: #000;\n  padding: 10px 5px;\n  width: 100%;\n  border: 1px solid var(--layout);\n  .day {\n    width: 100%;\n    text-align: center;\n    color: var(--primary);\n  }\n\n  .message {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 500;\n    padding: 20px 0;\n    font-size: 14px;\n    .username {\n      color: var(--primary);\n    }\n    .message {\n      padding: 10px;\n      background-color: #e7e7e7;\n      color: var(--darkGrey);\n      border-radius: 20px;\n      border-top-left-radius: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
