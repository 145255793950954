import { Pipe, PipeTransform } from '@angular/core';
import { CommonModal } from '../components/modal/modal.model';
import { ComponentType } from '@angular/cdk/portal';

@Pipe({
  name: 'castActionModalType',
})
export class CastActionModalType implements PipeTransform {
  transform(modal: CommonModal | ComponentType<any>): any {
    return modal instanceof CommonModal ? <CommonModal>modal : <ComponentType<any>>modal;
  }
}
