// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  color: var(--folder-color);
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/tablet-navigation/manage-navigation/manage-navigation.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,iBAAA;AACF","sourcesContent":["p {\n  color: var(--folder-color);\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
