import { Component, Input } from '@angular/core';

@Component({
  selector: '[dms-container]',
  template: `<a [href]="fileData.data" download>Download</a>`,
  standalone: true,
})
export class ContainerComponent {
  @Input() fileData: any;
}
