import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empyValue',
})
export class EmptyValuePipe implements PipeTransform {
  public transform(value: any): string {
    return value !== null && value !== undefined ? value : '-';
  }
}
