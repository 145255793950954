import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setModal } from 'src/app/state/modal/modal.action';
import { Message } from './models/message.model';
import { MESSAGES } from './messages';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./../notification-messages.scss', './messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  menuState: 'in' | 'out';
  currentMessage: Message | null;
  messages: Message[] = MESSAGES;

  constructor(
    private store: Store,
    private http: HttpClient,
  ) {}

  ngOnInit() {
    console.log();
    this.http.get(`${environment.nuxeoApiUrl}/notifications`).subscribe(data => {
      console.log(data);
    });
  }

  backToChat() {
    this.currentMessage = null;
  }

  toggleChat() {
    console.log();
  }

  takeCurrentMessage(message) {
    this.currentMessage = message;
  }

  closeModal() {
    this.store.dispatch(setModal({ modal: { status: false } }));
  }

  close() {
    console.log('close!');
  }

  toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
}
