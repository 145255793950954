import { AbstractControl } from '@angular/forms';

export function PasswordValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const password = control.value;

  // Check if password meets the criteria
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const isLengthValid = password.length >= 12;

  // Check if all criteria are met
  const isValid = hasUpperCase && hasLowerCase && hasSpecialCharacter && isLengthValid;

  // Return validation result
  return isValid ? null : { 'invalidPassword': true };
}