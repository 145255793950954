import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { CommonModal } from '../modal.model';
import { ComponentType } from '@angular/cdk/portal';
import { Store } from '@ngrx/store';
import { TableAction } from '@shared/components/workspace/data-table/models/actions/table-action.model';
import { ActionEvent } from '@shared/components/workspace/data-table/models/actions/action-event.model';
import { SelectActionEvent } from '@shared/components/workspace/data-table/models/actions/select-action-event.model';

@Component({
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss'],
})
export class ActionModalComponent {
  @ViewChild('actionTpl', { static: true })
  private actionTpl: TemplateRef<any>;
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();

  action: TableAction;
  modal: CommonModal | ComponentType<any> | undefined;

  constructor(
    private modalService: ModalService,
    private store: Store,
  ) {}

  openActionModal(event: ActionEvent | SelectActionEvent, clearSelected = false) {
    this.action = event.action;
    this.modal = event.action.modal;

    if (this.action.modal instanceof CommonModal) {
      this.modalService.createModalTemplate(
        this.actionTpl,
        event,
        this.action.modal.theme.modalClassName,
        clearSelected,
      );
    } else {
      this.modalService.createModalComponent(
        this.action.modal as ComponentType<any>,
        event,
      );
    }
  }

  openConfirmModal(modal: CommonModal) {
    this.modal = modal;
    this.modalService.createModalTemplate(
      this.actionTpl,
      null,
      modal.theme.modalClassName,
      true,
    );
  }
}
