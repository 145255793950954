/* eslint-disable @angular-eslint/no-input-rename */
import {
  Directive,
  ElementRef,
  Renderer2,
  Input,
  HostListener,
  Injector,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { InputDirevtive } from './util/input-directive';
import { MatTooltip } from '@angular/material/tooltip';

interface PrevPadding {
  left: string;
  right: string;
}

@Directive({
  selector: '[dmsInputIcon]',
  standalone: true,
  providers: [MatTooltip],
})
export class InputIconDirective extends InputDirevtive implements AfterViewInit {
  @Input('icon') iconName: string;
  @Input() disableAnimate: string;
  @Input() tootltip = '';

  @Input() isActive = true;

  // @Input() move = true;
  private inputElement: HTMLInputElement;
  private icon: HTMLElement;
  private isValid = true;
  private X: number;
  private Y: number;
  private ngControl: NgControl;
  private prevPadding: PrevPadding;

  @Output() iconClick = new EventEmitter();

  constructor(
    private el: ElementRef,
    public renderer: Renderer2,
    private injector: Injector,
    private matTooltip: MatTooltip,
  ) {
    super();
    this.inputElement = this.el.nativeElement;
  }

  @HostListener('focus', ['$event'])
  onFocus() {
    if (!this.disableAnimate && this.icon) {
      const contentWidth = this.X - parseInt(this.prevPadding?.right);
      this.move();
      // this.renderer.setStyle(this.inputElement, 'padding-right', this.prevPadding.right);
      this.renderer.setStyle(
        this.icon,
        'transform',
        `translate(${this.X - contentWidth}px, -${this.Y}px)`,
      );
    }
  }

  @HostListener('blur', ['$event'])
  onBlur() {
    if (this.isValid && !this.disableAnimate && this.icon) {
      this.move();
      this.renderer.setStyle(
        this.icon,
        'transform',
        `translate(${this.X}px, -${this.Y}px)`,
      );

      this.renderer.setStyle(this.inputElement, 'padding-left', this.prevPadding.left);
      this.addInputSpaces();
    }
  }

  ngAfterViewInit() {
    // Refactoring all directive
    const iconElement = this.inputElement.nextElementSibling;
    const isExists =
      iconElement &&
      iconElement.tagName === 'I' &&
      iconElement.classList.contains('input-icon');

    if (!this.isActive) {
      return;
    }

    if (!isExists) {
      this.icon = this.renderer.createElement('i');
      this.checkInputControl();
      this.addClass(this.icon, [
        'input-icon',
        'color-primary', // remove this (needs to put colors using ng-deep or from global css)
        'fa-solid',
        `fa-${this.iconName}`,
      ]);
      this.renderer.appendChild(this.inputElement.parentElement, this.icon);
    } else {
      this.icon = iconElement as HTMLElement;
    }

    if (this.tootltip) {
      this.renderer.listen(this.icon, 'mouseenter', () => {
        this.matTooltip.message = this.tootltip;
        this.matTooltip.tooltipClass = 'input-icon-tooltip';
        this.matTooltip.show();
      });
    }

    this.renderer.listen(this.icon, 'click', () =>
      this.iconClick.emit(this.inputElement.value),
    );

    const { X, Y } = this.calculatePosition(this.inputElement, this.icon);
    this.X = X;
    this.Y = Y;

    if (this.X < 0) {
      console.error(`Check input view`);
      this.renderer.removeChild(this.inputElement.parentElement, this.icon);
      return;
    }

    this.renderer.setStyle(
      this.icon,
      'transform',
      `translate(${this.X}px, -${this.Y}px)`,
    );

    this.prevPadding = {
      left: getComputedStyle(this.inputElement).paddingLeft,
      right: getComputedStyle(this.inputElement).paddingRight,
    };

    if (this.disableAnimate) {
      this.renderer.setStyle(this.inputElement, 'padding-right', '35px');
    }
  }

  private checkInputControl() {
    try {
      // ****** For formControl-s
      this.injector.get(NgControl);
      this.ngControl = this.injector.get(NgControl);
      this.ngControl.valueChanges?.subscribe(() => {
        this.isValid = !!this.ngControl.control?.valid;
        if (!this.isValid) {
          this.renderer.setStyle(this.icon, 'color', `var(--danger)`);
        } else {
          this.renderer.removeStyle(this.icon, 'color');
        }
      });
    } catch {
      //
    }
  }

  private addInputSpaces() {
    // this.setInputPadding('padding-right');
    this.renderer.addClass(this.inputElement, 'text-ellipsis');
  }

  private move() {
    this.setInputPadding('padding-left');
    this.renderer.setStyle(this.icon, 'transition', `0.3s`);
  }

  private setInputPadding(paddingPosition = 'padding-left') {
    this.renderer.setStyle(
      this.inputElement,
      paddingPosition,
      `${Math.floor(this.icon?.getBoundingClientRect().width) + 17}px`,
    );
  }
}
