export enum SEARCH_DATA_TYPE {
  USER = 'user',
  DOCUMENT = 'document',
  FILE = 'file',
  AUDIO = 'audio',
  PICTURE = 'picture',
  // group
}

export interface ISearchData {
  highlights: [];
  icon: any;
  id: string;
  label: string;
  thumbnailUrl: string;
  type: string;
  url: string;
}
