import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DocumentActionsComponent } from '../../../../document-actions/document-actions.component';
import { FormsModule } from '@angular/forms';
import { FileData } from '../../models/file-data';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DocumentEditorContainerModule } from '@syncfusion/ej2-angular-documenteditor';
import {
  BeforeSaveEventArgs,
  CellEditEventArgs,
  SaveCompleteEventArgs,
  SpreadsheetAllModule,
  SpreadsheetComponent,
} from '@syncfusion/ej2-angular-spreadsheet';
import { CommonModule } from '@angular/common';
import { ButtonAllModule, RadioButtonAllModule } from '@syncfusion/ej2-angular-buttons';
import { InitializeAndUpload } from '@shared/util/file.util';
import { HeaderService } from '../../../../header/services/header.service';
import { IFileSubmitType } from '../../../models/file-submit-type.model';

@Component({
  selector: '[dms-office]',
  templateUrl: './office.component.html',
  styleUrl: `./office.component.scss`,
  imports: [
    DocumentActionsComponent,
    FormsModule,
    AngularEditorModule,
    NgxDocViewerModule,
    DocumentEditorContainerModule,
    CommonModule,
    SpreadsheetAllModule,
    RadioButtonAllModule,
    ButtonAllModule,
  ],
  standalone: true,
})
export class OfficeComponent implements OnDestroy {
  private _fileData: FileData;

  @ViewChild('editorContainer')
  public spreadsheetObj: SpreadsheetComponent;
  @ViewChild('editorDialogContainer')
  public spreadsheetDialogObj: SpreadsheetComponent;
  @ViewChild('fileDialog')
  public fileDialog: ElementRef<HTMLDialogElement>;

  fileDialogOpen = false;

  @Input()
  set fileData(value: FileData) {
    if (value) {
      this._fileData = value;
      if (!this.isLocalSave) {
        this.processFileData();
      }
    }
  }

  get fileData(): FileData {
    return this._fileData;
  }

  public openUrl =
    'https://services.syncfusion.com/angular/production/api/spreadsheet/open';
  public saveUrl =
    'https://services.syncfusion.com/angular/production/api/spreadsheet/save';
  src: string;
  submit = new EventEmitter<IFileSubmitType>();
  isLocalSave: boolean;
  timeOut: ReturnType<typeof setTimeout> | null = null;

  constructor(private headerService: HeaderService) {}

  processFileData() {
    this.src = this._fileData.data;
    const properties = this._fileData?.currentDocument?.properties;

    if (!properties || !properties['file:content']) {
      console.error('Error: No file content properties available.');
      return;
    }

    const fileContent = properties['file:content'];
    if (!this.fileData.blob) {
      console.error('Error: Blob data is missing.');
      return;
    }

    this.blobToBase64(this.fileData.blob)
      .then(str => {
        const container = this.fileDialogOpen
          ? this.spreadsheetDialogObj
          : this.spreadsheetObj;
        this.src = `data:${fileContent['mime-type']};base64,${str}`;
        container.hideSpinner();

        fetch(this.src)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.blob();
          })
          .then(fileBlob => {
            const file = new File([fileBlob], 'Sample.xlsx');
            container.open({ file: file });
            container.selectRange('A1');
            container.hideSpinner();
          })
          .catch(error => {
            console.error('Error loading file:', error);
          });
      })
      .catch(error => {
        console.error('Error converting blob to base64:', error);
      });
  }

  onCellEditing(cellEditEventArgs: CellEditEventArgs) {
    this.spreadsheetObj.updateCell(cellEditEventArgs, cellEditEventArgs.address);
  }

  // processFileData() {
  //   if (this._fileData) {
  //     this.src = this._fileData.data;
  //     const properties = this._fileData?.currentDocument?.properties;
  //     if (properties && properties['file:content']) {
  //       const fileContent = properties['file:content'];
  //       this.blobToBase64(this.fileData.blob).then(str => {
  //         this.src = `data:${fileContent['mime-type']};base64,${str}`;
  //         this.spreadsheetObj.hideSpinner();

  //         fetch(this.src)
  //           .then(response => response.blob())
  //           .then(fileBlob => {
  //             const file = new File([fileBlob], 'Sample.xlsx');
  //             this.spreadsheetObj.open({ file: file });
  //             this.spreadsheetObj.hideSpinner();
  //           });
  //       });
  //     }
  //   }
  // }

  private blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Extract the Base64 encoded string, remove the prefix (data URL scheme and MIME type)
        const base64Data = (reader as any).result.split(',')[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  saveBuyButton() {
    this.isLocalSave = false;
    this.saveAsBlob();
  }

  public saveAsBlob() {
    this.spreadsheetObj.save({
      url: this.saveUrl,
      fileName: 'ExportedSpreadsheet',
      saveType: 'Xlsx',
    });

    this.spreadsheetObj.hideSpinner();
  }

  openDialog() {
    this.fileDialogOpen = true;
    this.processFileData();
  }

  beforeSave(args: BeforeSaveEventArgs): void {
    args.needBlobData = true;
    args.isFullPost = false;
  }

  saveComplete(args: SaveCompleteEventArgs): void {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(args.blobData);

    reader.onloadend = (e: any) => {
      const base64String = e.target.result;
      const validBase64 = base64String.slice(78, base64String.length - 1);
      this.submitFile(validBase64);
      this.headerService.setLoadingState(false);
    };
  }

  onValueChange(_: any) {
    // if (this.timeOut !== null) {
    //   return;
    // } else {
    //   this.timeOut = setTimeout(() => {
    //     // this.headerService.setLoadingState(true);
    //     this.isLocalSave = true;
    //     this.spreadsheetObj.hideSpinner();
    //     this.saveAsBlob();
    //     this.timeOut = null;
    //   }, 3500);
    // }
  }

  updateFile(event) {
    const file = event.target.files[0];
    if (file) {
      InitializeAndUpload(file, this.submitFile);
    }
  }

  submitFile = (base46: string) => {
    this.submit.emit({ file: base46, isLocalSave: this.isLocalSave });

    if (this.isLocalSave) {
      this.isLocalSave = false;
    }
  };

  ngOnDestroy() {
    setTimeout(() => this.saveAsBlob());
  }
}
