// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-ring {
  z-index: 1;
  justify-content: center;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  color: var(--primary-color);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/loader/loader.component.ts"],"names":[],"mappings":"AACM;EACE,UAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,cAAA;EACA,2BAAA;AAAR;;AAEM;EACE,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,sCAAA;EACA,kBAAA;EACA,8DAAA;EACA,sEAAA;AACR;;AACM;EACE,uBAAA;AAER;;AAAM;EACE,sBAAA;AAGR;;AADM;EACE,uBAAA;AAIR;;AAFM;EACE;IACE,uBAAA;EAKR;EAHM;IACE,yBAAA;EAKR;AACF","sourcesContent":["\n      .lds-ring {\n        z-index: 1;\n        justify-content: center;\n        display: flex;\n        align-items: center;\n        position: absolute;\n        width: 100%;\n        height: 100%;\n        left: 0;\n        top: 0;\n        right: 0;\n        bottom: 0;\n        margin: auto 0;\n        color: var(--primary-color);\n      }\n      .lds-ring div {\n        box-sizing: border-box;\n        display: block;\n        position: absolute;\n        width: 64px;\n        height: 64px;\n        margin: 8px;\n        border: 8px solid var(--primary-color);\n        border-radius: 50%;\n        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n        border-color: var(--primary-color) transparent transparent transparent;\n      }\n      .lds-ring div:nth-child(1) {\n        animation-delay: -0.45s;\n      }\n      .lds-ring div:nth-child(2) {\n        animation-delay: -0.3s;\n      }\n      .lds-ring div:nth-child(3) {\n        animation-delay: -0.15s;\n      }\n      @keyframes lds-ring {\n        0% {\n          transform: rotate(0deg);\n        }\n        100% {\n          transform: rotate(360deg);\n        }\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
