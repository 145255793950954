// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host,
ejs-documenteditorcontainer {
  height: 100% !important;
}

.e-de-ctnr-toolbar {
  display: none !important;
}

.e-de-tool-ctnr-properties-pane {
  height: calc(100% - 45px) !important;
}

::ng-deep .e-de-prop-pane {
  min-height: 450px;
}

:host {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

dialog {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
dialog i {
  cursor: pointer;
  font-size: 29px;
  position: absolute;
  right: 14px;
  top: 15px;
  color: var(--danger);
}
dialog .file-actions {
  position: absolute;
  bottom: 3%;
  right: 50%;
  display: flex;
  align-items: center;
  gap: 10px;
}
dialog .file-actions .last-saved .last-saved-title {
  font-weight: 600;
}
dialog .file-actions ::ng-deep .document-actions-wrapper i {
  font-size: 24px;
  color: var(--black);
}`, "",{"version":3,"sources":["webpack://./src/app/routes/home/components/document-details/components/view-document/file/components/file/file.component.scss"],"names":[],"mappings":"AAAA;;EAEE,uBAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;;AAEA;EACE,eAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AACF;AAAE;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,oBAAA;AAEJ;AACE;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AACJ;AAGM;EACE,gBAAA;AADR;AAKI;EACE,eAAA;EACA,mBAAA;AAHN","sourcesContent":[":host,\nejs-documenteditorcontainer {\n  height: 100% !important;\n}\n\n.e-de-ctnr-toolbar {\n  display: none !important;\n}\n\n.e-de-tool-ctnr-properties-pane {\n  height: calc(100% - 45px) !important;\n}\n\n::ng-deep .e-de-prop-pane {\n  min-height: 450px;\n}\n\n:host {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\ndialog {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 9;\n  i {\n    cursor: pointer;\n    font-size: 29px;\n    position: absolute;\n    right: 14px;\n    top: 15px;\n    color: var(--danger);\n  }\n\n  .file-actions {\n    position: absolute;\n    bottom: 3%;\n    right: 50%;\n    display: flex;\n    align-items: center;\n    gap: 10px;\n\n    .last-saved {\n\n      .last-saved-title {\n        font-weight: 600;\n      }\n    }\n\n    ::ng-deep .document-actions-wrapper i {\n      font-size: 24px;\n      color: var(--black);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
