import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ThemeService } from '@core/theme/theme.service';
import { Store } from '@ngrx/store';
import { MyProfileComponent } from '@routes/home/components/my-profile/my-profile.component';
import { ModalHeaderComponent } from '@shared/components/modal/modal-header/modal-header.component';
import { LetDirective } from '@shared/directives/let.directive';
import { setModal } from '@state/modal/modal.action';
import { LoginService } from 'src/app/auth/componentss/services/login.service';

@Component({
  selector: 'dms-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  imports: [CommonModule, ModalHeaderComponent, LetDirective, MyProfileComponent],
  standalone: true,
})
export class SettingsComponent {
  private themeService = inject(ThemeService);
  private loginService = inject(LoginService);
  private store = inject(Store);

  changeTheme(name) {
    this.themeService.setTheme(name);
    // this.alertService.setAlert(new Alert('Alert', `Message`, 'success', false, 8000));
  }

  close() {
    this.store.dispatch(setModal({ modal: { status: false } }));
  }

  logOut() {
    this.close();
    this.loginService.logOut();
  }
}
