import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { setModal, setModalSuccess } from './modal.action';
import { of } from 'rxjs';
import { takeModal } from './modal.selector';

let idList: any = [];

@Injectable()
export class ModalEffects {
  constructor(private actions$: Actions, private store: Store) {}

  openModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setModal),
      withLatestFrom(this.store.select(takeModal)),
      switchMap(([{ modal }]) => {
        if (!idList.length) {
          idList.push(0);
          return of(setModalSuccess({ modal: { ...modal, id: 0 } }));
        } else {
          if (modal.status) {
            const id = idList[idList.length - 1] + 1;
            idList.push(id);
            return of(setModalSuccess({ modal: { ...modal, id } }));
          } else {
            idList = idList.filter(item => item !== Math.max(...idList));
            if (idList.length) {
              return of(setModalSuccess({ modal: { status: false, id: 1 } }));
            } else {
              return of(setModalSuccess({ modal: { status: false, id: 0 } }));
            }
          }
        }
      }),
    ),
  );
}
