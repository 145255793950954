// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-container {
  padding: 20px;
  height: 100%;
  background-color: var(--layout);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Inter";
  font-weight: 700;
  font-size: 16px;
  color: var(--darkGrey);
  margin: 10px 0;
}
.header-container .header {
  margin: 0 !important;
}

.fa-square-xmark {
  font-size: 25px;
  color: var(--danger);
}`, "",{"version":3,"sources":["webpack://./src/app/common/notifications/notification-messages.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,+BAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,sBAAA;EACA,cAAA;AACF;AAAE;EACE,oBAAA;AAEJ;;AAEA;EACE,eAAA;EACA,oBAAA;AACF","sourcesContent":[".notifications-container {\n  padding: 20px;\n  height: 100%;\n  background-color: var(--layout);\n}\n\n.header-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-family: 'Inter';\n  font-weight: 700;\n  font-size: 16px;\n  color: var(--darkGrey);\n  margin: 10px 0;\n  .header {\n    margin: 0 !important;\n  }\n}\n\n.fa-square-xmark {\n  font-size: 25px;\n  color: var(--danger);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
