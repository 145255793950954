import { IActivatedServicesEntries } from '@routes/home/components/authorized-applications/models/activated-services.model';
import { IGroup } from '@common/models/groups.model';
import { DisplayedUser } from '@common/models/users.model';
import { IDocumentEntity } from '@shared/models/document.model';
import { IDisplayDocumentHistory } from '@routes/home/models/document-history.model';

export enum VIEW_TYPE {
  LIST = 'list',
  TABLE = 'table',
}

// needs to change

export interface IListOptions {
  key: string;
  title: string;
}

export interface IKeyValue {
  key: string;
  value: any;
}

declare global {
  interface ProxyConstructor {
    new <TSource extends object, TTarget extends object>(
      target: TSource,
      handler: ProxyHandler<TSource>,
    ): TTarget;
  }
}

export type DisplayedData =
  | IDocumentEntity
  | DisplayedUser
  | IGroup
  | IActivatedServicesEntries
  | IDisplayDocumentHistory;

export interface Pagination {
  page: number;
  pageSize: number;
}

export interface IPageInfo {
  pageSize: number;
  pageIndex: number;
}

export interface ITemplateValue<T> {
  value: T;
  template: string;
}
