const convertToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      let base64String = reader.result;
      base64String = (base64String as string).split(',')[1];
      resolve(base64String);
    };
    reader.onerror = error => reject(error);
  });

export const InitializeAndUpload = (file: File, submit: (base64: string) => void) => {
  convertToBase64(file).then(base64 => {
    submit(base64);
  });
};
