import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: true,
})
export class SortPipe<T> implements PipeTransform {
  transform(items: Array<T>, key?: string): Array<T> {
    // not working
    if (key) {
      return items.sort((a, b) => {
        if (a[key]) {
          return a[key].localeCompare(b[key]);
        }
      });
    } else {
      return items.sort();
    }
  }
}
