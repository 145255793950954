// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  background-color: var(--primary-tag);
  position: relative;
  border-radius: 48px;
  padding: 5px 10px;
  color: var(--tagText);
}
:host.hidden-close-btn i {
  transition: 0.2s;
  font-size: 0 !important;
}
:host.hidden-close-btn:hover i {
  font-size: 17px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/standalone/tags/tags.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,QAAA;EACA,oCAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,qBAAA;AACF;AACI;EACE,gBAAA;EACA,uBAAA;AACN;AAEM;EACE,0BAAA;AAAR","sourcesContent":[":host {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 5px;\n  background-color: var(--primary-tag);\n  position: relative;\n  border-radius: 48px;\n  padding: 5px 10px;\n  color: var(--tagText);\n  &.hidden-close-btn {\n    i {\n      transition: 0.2s;\n      font-size: 0 !important;\n    }\n    &:hover {\n      i {\n        font-size: 17px !important;\n      }\n    }\n  }\n}\n\n.action {\n  // font-size: 14px;\n}\n\n.content {\n  // min-width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
