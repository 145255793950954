import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { InputDirevtive } from './util/input-directive';

@Directive({
  selector: '[dmsErrorValidation]',
  standalone: true,
  providers: [MatTooltip],
})
export class ErrorValidationDirective
  extends InputDirevtive
  implements OnInit, OnDestroy {
  @Input() errorMessage = 'Field is required';
  private isValid: boolean;
  private errorIcon: HTMLElement | null;
  private mouseenterListener: () => void;

  constructor(
    private el: ElementRef,
    public renderer: Renderer2,
    private ngControl: NgControl,
    private matTooltip: MatTooltip,
  ) {
    super();
  }

  ngOnInit() {
    this.ngControl.valueChanges?.subscribe(() => {
      this.isValid = !!this.ngControl.control?.valid;

      if (this.ngControl.control?.errors?.['whitespace'] || this.ngControl.control?.errors?.['email']) {
        this.errorMessage = 'Field is not valid';
      } else if (this.ngControl.control?.errors?.['required']) {
        this.errorMessage = 'Field is required';
      }

      if (this.ngControl.control?.errors?.['invalidPassword']) {
        this.errorMessage = `
          Minimum 12 characters\n
          At least one Uppercase and Lowercase\n
          At least one special character
        `;
      }

      if (this.ngControl.disabled) {
        return;
      }

      if (!this.isValid) {
        if (!this.errorIcon) {
          this.createErrorIcon();
        }
      } else {
        this.hideErrorIcon();
      }
    });
  }

  private createErrorIcon() {
    const inputElement = this.el.nativeElement;
    this.errorIcon = this.renderer.createElement('i');
    this.setClasses();
    this.setIconPosition(inputElement);

    this.renderer.appendChild(inputElement.parentElement, this.errorIcon);

    this.setTooltip();

    this.mouseenterListener = this.renderer.listen(this.errorIcon, 'mouseenter', () => {
      this.matTooltip.show();
      this.matTooltip.tooltipClass = 'error-tooltip input-icon-tooltip';
    });
  }

  private hideErrorIcon() {
    if (this.errorIcon) {
      const inputElement = this.el.nativeElement;
      this.renderer.removeClass(inputElement, 'error');
      this.renderer.removeChild(inputElement.parentElement, this.errorIcon);
      this.matTooltip.hide();
      // debugger;

      this.errorIcon = null;
      this.removeListenners();
    }
  }

  private removeListenners() {
    if (this.mouseenterListener) {
      this.mouseenterListener();
    }
  }

  private setClasses() {
    this.addClass(this.errorIcon as HTMLElement, [
      'fa-solid',
      'input-icon',
      'error-icon',
      'invalid-input-warning',
      'fa-circle-exclamation',
    ]);
    this.renderer.setAttribute(this.errorIcon, 'data-cy', 'email-input-warning');
  }

  private setIconPosition(inputElement: HTMLElement) {
    const { X, Y } = this.calculatePosition(inputElement, this.errorIcon as HTMLElement);
    this.renderer.setStyle(this.errorIcon, 'transform', `translate(${X}px, -${Y}px)`);
  }

  private setTooltip() {
    this.matTooltip.message = this.errorMessage;
    this.matTooltip.position = 'below';
    this.matTooltip.tooltipClass = 'invalid-input-warning';
  }

  ngOnDestroy() {
    this.removeListenners();
  }
}
