import { AsyncPipe, NgFor } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LetDirective } from '@shared/directives/let.directive';
import { Manage } from '@state/manage/manage.action';
import { currentRoute } from '@state/router-state/router.selector';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'dms-manage-header',
  templateUrl: './manage-header.component.html',
  styleUrls: ['manage-header.component.scss'],
  imports: [NgFor, LetDirective, AsyncPipe],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
})
export class ManageHeaderComponent implements OnInit {
  store = inject(Store);
  cdr = inject(ChangeDetectorRef);
  router = inject(Router);
  route = inject(ActivatedRoute);
  breadcrumbList$: Observable<string[]>;

  ngOnInit() {
    this.breadcrumbList$ = this.store.select(currentRoute).pipe(
      map(({ url }) =>
        decodeURI(url)
          .split('/')
          .filter(v => v.length)
          .join('/')
          .replace('manage', 'Root')
          .split('/'),
      ),
    );
  }

  navigate(breadcrumb: string, breadcrumbList: string[]) {
    const index = breadcrumbList.findIndex(v => v === breadcrumb);
    const path = breadcrumbList.slice(1, index + 1).join('/');
    this.store.dispatch(Manage.loadDataByPath({ path }));
  }
}
