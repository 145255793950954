import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'dms-common-modal-footer',
  template: `
    <div class="footer">
      <button
        class="btn btn-primary"
        type="button"
        (click)="onSave()">
        {{ confirm }}
      </button>
      <button class="btn-sm btn-primary-outline" type="button" (click)="onBack()">
        {{ cancel }}
      </button>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./common-modal-footer.component.scss']
})
export class CommonModalFooterComponent {
  @Input() confirm = 'Confirm';
  @Input() cancel = 'Cancel';

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() back: EventEmitter<any> = new EventEmitter();

  onSave() {
    this.save.emit();
  }

  onBack() {
    this.back.emit();
  }
}
