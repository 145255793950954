import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { currentRoute } from '@state/router-state/router.selector';
import { Subscription, filter, tap } from 'rxjs';

@Component({
  selector: 'dms-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  imports: [CommonModule, RouterModule],
  standalone: true,
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() tablist: { title: string; path: string }[];
  isAdminPage = false;
  sub: Subscription;

  constructor(
    public router: Router,
    private store: Store,
  ) {}

  ngOnInit() {
    this.sub = this.store
      .select(currentRoute)
      .pipe(
        filter(v => !!v?.url),
        tap(route => {
          this.isAdminPage = route.url.split('/')[1].includes('admin');
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
