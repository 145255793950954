import { ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { takeModal } from 'src/app/state/modal/modal.selector';
import { selectAllRows } from 'src/app/state/select-items/select-items.action';
import { ModalService } from './services/modal.service';

@Component({
  selector: 'dms-modal',
  template: ` <ng-container #viewContainer>
    <dms-backdrop *ngIf="order !== 0"></dms-backdrop>
  </ng-container>`,
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('viewContainer', { read: ViewContainerRef, static: true })
  viewContainer: ViewContainerRef;

  @HostBinding('class') className: string;
  sub: Subscription;
  data: any | any[];
  layers: any[] = [];
  order: number;

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.sub = this.store
      .select(takeModal)
      .subscribe(({ status, isClearSelected, id }) => {
        if (typeof id === 'number') {
          this.order = id;
        }

        if (!status) {
          this.className += ' out';
          this.cdr.detectChanges();
          if (isClearSelected) {
            this.store.dispatch(selectAllRows({ isSelectAll: false }));
          }
          setTimeout(() => {
            // this.className = '';
            this.modalService.clear();
          }, 900);
        } else {
          this.className += ' in';
        }
      });
  }

  createComponent(component: ComponentType<any>) {
    return this.viewContainer.createComponent(component);
  }

  createEmbeddedView(template: TemplateRef<any>) {
    return this.viewContainer.createEmbeddedView(template);
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
