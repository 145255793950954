import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'dms-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnChanges {
  @Input() totalElements: number | undefined;
  @Input() pageNumber = 1;
  @Input() maxSize: number;
  @Input() pageSize: number;
  @Input() directionLinks = false;

  @Input() modes: number[] = [];

  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  @Output() modeChange: EventEmitter<number> = new EventEmitter();

  totalPages: number;
  pages: number[] = [];
  startItems: number;
  endItems: number;

  constructor() {}

  ngOnChanges() {
    if (this.pageSize || this.totalElements) {
      this.createPaginator();
    }
  }

  changeMode(pageSize: number) {
    this.pageSize = pageSize;
    this.modeChange.emit(pageSize);
  }

  createPaginator() {
    if (this.totalElements) {
      this.totalPages = Math.ceil(this.totalElements / this.pageSize);
      this.pages = this.paginate(+this.totalPages, +this.pageNumber, +this.maxSize);
      this.startItems = this.pageSize * (this.pageNumber - 1) + 1;
      this.endItems = this.pageSize * this.pageNumber;
      if (this.endItems > this.totalElements) {
        this.endItems = this.totalElements;
      }
    }
  }

  paginate(totalPages: number, pageNumber = 1, maxPages: number): number[] {
    let startPage: number, endPage: number;

    if (totalPages <= maxPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxPagesBeforepageNumber = Math.floor(maxPages / 2);
      const maxPagesAfterpageNumber = Math.ceil(maxPages / 2) - 1;
      if (pageNumber <= maxPagesBeforepageNumber) {
        startPage = 1;
        endPage = maxPages;
      } else if (pageNumber + maxPagesAfterpageNumber >= totalPages) {
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        startPage = pageNumber - maxPagesBeforepageNumber;
        endPage = pageNumber + maxPagesAfterpageNumber;
      }
    }

    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      i => startPage + i,
    );
    return pages;
  }

  first() {
    if (this.pageNumber > 1) {
      this.emit(1);
    } else {
      console.warn(`It is forbidden!`);
    }
  }

  last() {
    if (this.pageNumber < this.totalPages) {
      this.emit(this.totalPages);
    } else {
      console.warn(`It is forbidden!`);
    }
  }

  prev() {
    if (this.pageNumber > 1) {
      this.pageNumber--;
      this.emit();
    } else {
      console.warn(`It is forbidden!`);
    }
  }

  next() {
    if (this.pageNumber < this.totalPages) {
      this.pageNumber++;
      this.emit();
    } else {
      console.warn(`It is forbidden!`);
    }
  }

  setCurrent(event: MouseEvent, page: number) {
    event.preventDefault();
    if (page !== this.pageNumber) {
      this.emit(page);
    }
  }

  emit(pageNumber?: number) {
    if (pageNumber) {
      this.pageNumber = pageNumber;
    }

    this.createPaginator();
    this.pageChange.emit(this.pageNumber);
  }
}
