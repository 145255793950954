import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { setModal } from 'src/app/state/modal/modal.action';
import { takeModal } from 'src/app/state/modal/modal.selector';

@Component({
  selector: 'dms-backdrop',
  template: `<ng-container *ngIf="showBackdrop">
    <div [style.z-index]="positionedOrder" [class]="className" (click)="close()"></div>
  </ng-container> `,
  styles: [
    `
      div {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: rgb(0 0 0 / 44%);
        top: 0;
        left: 0;
      }

      :host .in {
        animation: backdrop-in 1s;
      }

      :host .out {
        animation: backdrop-out 0.9s;
      }

      @keyframes backdrop-in {
        from {
          background: rgb(0 0 0 / 0%);
        }
        to {
          background: rgb(0 0 0 / 44%);
        }
      }
      @keyframes backdrop-out {
        from {
          background: rgb(0 0 0 / 44%);
        }
        to {
          background: rgb(0 0 0 / 0%);
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackdropComponent implements OnInit {
  @Input() positionedOrder = 0;

  className = 'in';
  showBackdrop: boolean;
  timeOut: ReturnType<typeof setTimeout>;

  constructor(private store: Store, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    const setActive = status => {
      this.showBackdrop = status;
      this.cdr.detectChanges();
    };

    this.store.select(takeModal).subscribe(({ status, id, orderPosition }) => {
      this.positionedOrder = orderPosition || 0;
      if (id !== 0) {
        this.showBackdrop = true;
        return;
      }
      if (status) {
        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }

        this.className = 'in';
        setActive(status);
      } else {
        this.className = 'out';
        this.cdr.detectChanges();
        this.timeOut = setTimeout(() => {
          setActive(status);
        }, 800);
      }
    });
  }

  close() {
    if (this.showBackdrop) {
      this.store.dispatch(setModal({ modal: { status: false } }));
    }
  }
}
