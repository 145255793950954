import { Component, Input } from '@angular/core';

@Component({
  selector: '[dms-mail]',
  template: `<div>
    <a [href]="fileData.data" download>Download</a>
  </div>`,
  standalone: true,
})
export class MailComponent {
  @Input() fileData: any;
}
