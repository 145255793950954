import { ArtworkComponent } from '../components/artwork.component';
import { AudioComponent } from '../components/audio.component';
import { ContainerComponent } from '../components/container.component';
import { FileComponent } from '../components/file/file.component';
import { InternetComponent } from '../components/internet.component';
import { MailComponent } from '../components/mail.component';
import { NoteComponent } from '../components/note/note.component';
import { OfficeComponent } from '../components/office/office.component';
import { PdfComponent } from '../components/pdf/pdf.component';
import { PictureComponent } from '../components/picture/picture.component';
import { VideoComponent } from '../components/video.component';

const Picture = {
  'image/jpeg': PictureComponent,
  'image/jpg': PictureComponent,
  'image/png': PictureComponent,
  'image/ps': PictureComponent,
  'image/psd': PictureComponent,
  'image/svg+xml': PictureComponent,
  'image/raw': PictureComponent,
  'image/svg': PictureComponent,
  'image/xmp': PictureComponent,
  'image/tif': PictureComponent,
  'image/tiff': PictureComponent,
  'image/eps': PictureComponent,
  'image/gif': PictureComponent,
  'image/ai': PictureComponent,
  'image/bmp': PictureComponent,
  'image/jp2': PictureComponent,
  'image/emf': PictureComponent,
  'image/cr2': PictureComponent,
  'image/crw': PictureComponent,
  'image/nef': PictureComponent,
  'image/dng': PictureComponent,
};

const Video = {
  'video/avi': VideoComponent,
  'video/flv': VideoComponent,
  'video/m4v': VideoComponent,
  'video/mov': VideoComponent,
  'video/mp4': VideoComponent,
  'video/mpeg': VideoComponent,
  'video/mpg': VideoComponent,
  'video/ogg': VideoComponent,
  'video/ogv': VideoComponent,
  'video/wmv': VideoComponent,
  'video/fla': VideoComponent,
  'video/hdv': VideoComponent,
  'video/hevc': VideoComponent,
  'video/qt': VideoComponent,
  'video/swf': VideoComponent,
};

const Audio = {
  'audio/mp3': AudioComponent,
  'audio/mpga': AudioComponent,
  'audio/mpeg': AudioComponent,
  'audio/mp2': AudioComponent,
  'audio/m3u': AudioComponent,
  'audio/wav': AudioComponent,
  'audio/aif': AudioComponent,
  'audio/aifc': AudioComponent,
  'audio/aiff': AudioComponent,
  'audio/ogg': AudioComponent,
  'audio/oga': AudioComponent,
  'audio/spx': AudioComponent,
  'audio/flac': AudioComponent,
  'audio/ogm': AudioComponent,
  'audio/ogx': AudioComponent,
  'audio/aac': AudioComponent,
};

const Artwork = {
  '.dae': ArtworkComponent,
  '.3ds': ArtworkComponent,
  '.fbx': ArtworkComponent,
  '.ply': ArtworkComponent,
  '.obj': ArtworkComponent,
  '.x3d': ArtworkComponent,
  '.stl': ArtworkComponent,
};

const Office = {
  'application/vnd.ms-excel': OfficeComponent,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': OfficeComponent,
  'application/doc': FileComponent,
  'application/docx': FileComponent,
  'application/odt': FileComponent,
  'application/rtf': FileComponent,
  'application/pdf': PdfComponent,
  'application/ppt': FileComponent,
  'application/pptx': FileComponent,
  'application/xls': FileComponent,
  'application/xlsx': FileComponent,
  'application/txt': FileComponent,
  'application/key': FileComponent,
};

const Container = {
  '.rar': ContainerComponent,
  '.sit': ContainerComponent,
  '.zip': ContainerComponent,
};

const Mail = {
  '.eml': MailComponent,
  '.msg': MailComponent,
};

const Internet = {
  '.css': InternetComponent,
  '.htm': InternetComponent,
  '.html': InternetComponent,
  '.xml': InternetComponent,
  '.js': InternetComponent,
};

export const File = {
  'application/octet-stream': FileComponent,
  'image/x-3ds': FileComponent,
  'application/x-indesign': FileComponent,
  'application/x-idml': FileComponent,
  'application/msword': FileComponent,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    FileComponent,
  'application/vnd.oasis.opendocument.text': FileComponent,
  'application/rtf': FileComponent,
  'application/pdf': PdfComponent,
  'application/vnd.ms-powerpoint': FileComponent,
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    PdfComponent,
  'application/vnd.ms-excel': FileComponent,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': FileComponent,
  'text/plain': FileComponent,
  'application/vnd.apple.keynote': FileComponent,
  'application/x-rar-compressed': FileComponent,
  'application/x-stuffit': FileComponent,
  'application/zip': FileComponent,
  'message/rfc822': FileComponent,
  'text/css': FileComponent,
  'text/html': FileComponent,
  'text/xml': FileComponent,
  'application/javascript': FileComponent,
};

export const FILE_COMPONENTS = {
  ...File,
  ...Picture,
  ...Video,
  ...Audio,
  ...Artwork,
  ...Office,
  ...Container,
  ...Mail,
  ...Internet,
};

export const FILE_TYPES = {
  File: {
    '.dae': 'application/octet-stream',
    '.3ds': 'image/x-3ds',
    '.fbx': 'application/octet-stream',
    '.ply': 'application/octet-stream',
    '.obj': 'application/octet-stream',
    '.x3d': 'application/octet-stream',
    '.stl': 'application/octet-stream',
    '.blend': 'application/octet-stream',
    '.bip': 'application/octet-stream',
    '.ksp': 'application/octet-stream',
    '.mb': 'application/octet-stream',
    '.ixo': 'application/octet-stream',
    '.glTF': 'application/octet-stream',
    '.abc': 'application/octet-stream',
    '.dxf': 'application/octet-stream',
    '.3dm': 'application/octet-stream',
    '.bw': 'application/octet-stream',
    '.zprj': 'application/octet-stream',
    '.u3ma': 'application/octet-stream',
    '.zfab': 'application/octet-stream',
    '.cway': 'application/octet-stream',
    '.dci': 'application/octet-stream',
    '.iges': 'application/octet-stream',
    '.step': 'application/octet-stream',
    '.indd': 'application/x-indesign',
    '.idml': 'application/x-idml',
    '.prt': 'application/octet-stream',
    '.sldprt': 'application/octet-stream',
    '.f3d': 'application/octet-stream',
    '.axf': 'application/octet-stream',
    '.c4d': 'application/octet-stream',
    '.doc': 'application/msword',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.odt': 'application/vnd.oasis.opendocument.text',
    '.rtf': 'application/rtf',
    '.pdf': 'application/pdf',
    '.ppt': 'application/vnd.ms-powerpoint',
    '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.xls': 'application/vnd.ms-excel',
    '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.txt': 'text/plain',
    '.key': 'application/vnd.apple.keynote',
    '.rar': 'application/x-rar-compressed',
    '.sit': 'application/x-stuffit',
    '.zip': 'application/zip',
    '.eml': 'message/rfc822',
    '.msg': 'application/octet-stream',
    '.css': 'text/css',
    '.htm': 'text/html',
    '.html': 'text/html',
    '.xml': 'text/xml',
    '.js': 'application/javascript',
  },
  Folder: {},
  OrderedFolder: {},
  HiddenFolder: {},
  Root: {},
  Relation: {},
  Domain: {},
  WorkspaceRoot: {},
  Workspace: {},
  TemplateRoot: {},
  SectionRoot: {},
  Section: {},
  Document: {},
  Note: {},
  Collection: {},
  Collections: {},
  ManagementRoot: {},
  AdministrativeStatusContainer: {},
  AdministrativeStatus: {},
  PermissionsSearch: {},
  AssetsSearch: {},
  UserInvitation: {},
  UserInvitationContainer: {},
  BasicAuditSearch: {},
  Favorites: {},
  CommentRoot: {},
  Comment: {},
  Annotation: {},
  CommentRelation: {},
  PictureBook: {},
  PublicationRelation: {},
  DefaultRelation: {},
  Tag: {},
  Tagging: {},
  Picture: {
    '.jpeg': 'image/jpeg',
    '.jpg': 'image/jpeg',
    '.png': 'image/png',
    '.ps': 'application/postscript',
    '.psd': 'image/vnd.adobe.photoshop',
    '.raw': 'application/octet-stream',
    '.svg': 'image/svg+xml',
    '.xmp': 'application/octet-stream',
    '.tif': 'image/tiff',
    '.tiff': 'image/tiff',
    '.eps': 'application/postscript',
    '.gif': 'image/gif',
    '.ai': 'application/illustrator',
    '.bmp': 'image/bmp',
    '.jp2': 'image/jp2',
    '.emf': 'application/x-emf',
    '.cr2': 'image/x-canon-cr2',
    '.crw': 'image/x-canon-crw',
    '.nef': 'image/x-nikon-nef',
    '.dng': 'image/x-adobe-dng',
  },
  Audio: {
    '.mp3': 'audio/mpeg',
    '.mpga': 'audio/mpeg',
    '.mp2': 'audio/mpeg',
    '.m3u': 'audio/mpegurl',
    '.wav': 'audio/x-wav',
    '.aif': 'audio/aiff',
    '.aifc': 'audio/aiff',
    '.aiff': 'audio/aiff',
    '.ogg': 'audio/ogg',
    '.oga': 'audio/ogg',
    '.spx': 'application/octet-stream',
    '.flac': 'audio/flac',
    '.ogm': 'application/octet-stream',
    '.ogx': 'application/octet-stream',
    '.aac': 'audio/aac',
  },
  TaskRoot: {},
  TaskDoc: {},
  UserWorkspacesRoot: {},
  Video: {
    '.avi': 'video/avi',
    '.flv': 'video/x-flv',
    '.m4v': 'video/x-m4v',
    '.mov': 'video/quicktime',
    '.mp4': 'video/mp4',
    '.mpeg': 'video/mpeg',
    '.mpg': 'video/mpeg',
    '.ogg': 'video/ogg',
    '.ogv': 'video/ogg',
    '.wmv': 'video/x-ms-wmv',
    '.fla': 'application/octet-stream',
    '.hdv': 'application/octet-stream',
    '.hevc': 'application/octet-stream',
    '.qt': 'video/quicktime',
    '.swf': 'application/x-shockwave-flash',
  },
  AdvancedSearch: {},
  AdvancedContent: {},
  RoutingTask: {},
  DocumentRouteInstancesRoot: {},
  DocumentRouteModelsRoot: {},
  StepFolder: {},
  DocumentRoute: {},
  DocumentRouteStep: {},
  ConditionalStepFolder: {},
  RouteNode: {},
  SavedSearch: {},
  DefaultSearch: {},
  ExpiredSearch: {},
  UserProfile: {},
};

export type FILE_COMPONENT_TYPE =
  | ArtworkComponent
  | AudioComponent
  | ContainerComponent
  | InternetComponent
  | MailComponent
  | OfficeComponent
  | PictureComponent
  | VideoComponent
  | NoteComponent;
