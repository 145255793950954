import { IDocument } from '@shared/models/document.model';

export enum FORM_TYPES {
  DOCUMENT_FOR_COLLECTION_TYPE = 'DOCUMENT FOR COLLECTION',
  DOCUMENT_TYPE = 'DOCUMENT TYPE',
}

export interface ManageCreateState {
  formType: FORM_TYPES;
  collectionTitle: string;
  collectionDocuments: IDocument | any;
}
