import { Injectable } from '@angular/core';
import { ILoading } from '@routes/search/state/search.state';
import { Observable, Subject } from 'rxjs';

interface Loader {
  key: string;
  observ: Subject<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loaders: Loader[] = [];

  setLoading(loading: ILoading): void {
    const { status, key } = loading;
    const existingLoader = this.loaders.find(loader => loader.key === key);

    if (!existingLoader) {
      if (status !== undefined) {
        this.addLoader(key, status);
      } else {
        console.error(`Invalid loader status`);
      }
    } else {
      existingLoader.observ.next(status);
    }
  }

  takeLoading(key: string): Observable<boolean> {
    let loader = this.loaders.find(loader => loader.key === key);
    if (!loader) {
      loader = this.addLoader(key);
    }

    return loader.observ.asObservable();
  }

  private addLoader(key, status = false): Loader {
    const observ = new Subject<boolean>();
    observ.next(status);

    const loader = { key, observ };
    this.loaders.push(loader);
    return loader;
  }
}
