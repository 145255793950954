import { DEVICE } from '@shared/models/device.model';

export interface SidebarMenuState {
  device: DEVICE;
  isOpend: boolean;
}

export const initialState: SidebarMenuState = {
  device: DEVICE.DESKTOP,
  isOpend: false,
};
