export type AlertType = 'success' | 'info' | 'error' | 'warning';

export class Alert {
  public animateClass = 'in';
  public timeOut: ReturnType<typeof setTimeout>;

  constructor(
    public title: string,
    public message: string,
    public type: AlertType,
    public dismissible: boolean = false,
    public ms: number = 3000,
  ) {}
}
