// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-tab-label-container .tablist {
  gap: 15px;
  display: flex;
  align-items: center;
}
.menu-tab-label-container .tablist .tab {
  padding: 10px 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.menu-tab-label-container .tablist .tab a {
  text-decoration: none;
  font-size: 17px;
}
.menu-tab-label-container-admin .tablist {
  gap: 0;
}
.menu-tab-label-container-admin .tablist .tab {
  padding: 10px;
}
.menu-tab-label-container-admin .tablist .tab a {
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/tablet-navigation/shared/components/menu/menu.component.scss"],"names":[],"mappings":"AACE;EACE,SAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AACI;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACN;AAAM;EACE,qBAAA;EACA,eAAA;AAER;AAUE;EACE,MAAA;AARJ;AASI;EACE,aAAA;AAPN;AAQM;EACE,eAAA;AANR","sourcesContent":[".menu-tab-label-container {\n  .tablist {\n    gap: 15px;\n    display: flex;\n    align-items: center;\n    .tab {\n      padding: 10px 14px;\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      font-weight: 500;\n      a {\n        text-decoration: none;\n        font-size: 17px;\n      }\n      &.active {\n        a {\n          // color: var(--white);\n        }\n      }\n    }\n  }\n}\n\n.menu-tab-label-container-admin {\n  .tablist {\n    gap: 0;\n    .tab {\n      padding: 10px;\n      a {\n        font-size: 10px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
