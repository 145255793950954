import { Store } from '@ngrx/store';
import { map, mergeMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@routes/home/services/data.service';
import {
  loadLocalPermissions,
  loadSelectedDocument,
  loadSelectedUser,
  localPermissions,
  selectedDocument,
  selectedUser,
} from './home.action';

@Injectable()
export class homeEffects {
  constructor(
    private actions$: Actions,
    private dataService: DataService,
    private router: Router,
    private store: Store,
  ) {}

  selectedDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectedDocument),
      mergeMap(action =>
        this.dataService.getSelectedDocument(action.id).pipe(
          map(nuxeoDocument => {
            const id = nuxeoDocument.id ? nuxeoDocument.id : nuxeoDocument.uid;
            if(!action.noNavigate) {
              this.router.navigate(['/home/document', `${id}`]);
            }
            return loadSelectedDocument({ selectedDocument: nuxeoDocument });
          }),
        ),
      ),
    ),
  );

  selectedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectedUser),
      mergeMap(action =>
        this.dataService.getSelectedUser(action.id).pipe(
          map(user => {
            this.store.dispatch(localPermissions({ id: user.id }));
            this.router.navigate(['/home/guest-account', `${user.id}`]);
            return loadSelectedUser({ selectedUser: user });
          }),
        ),
      ),
    ),
  );

  localPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(localPermissions),
      mergeMap(action =>
        this.dataService
          .postLocalPermissions(action.id)
          .pipe(
            map(permissions => loadLocalPermissions({ localPermissions: permissions })),
          ),
      ),
    ),
  );
}
