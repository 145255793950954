import { createReducer, on } from '@ngrx/store';
import { setModal, setBackdropOrderPosition } from './modal.action';
import { initialState } from './modal.state';

export const modalReducer = createReducer(
  initialState,
  on(setModal, (state, { modal }) => ({ ...state, status: modal.status })),
  on(setBackdropOrderPosition, (state, { orderPosition }) => ({ ...state, orderPosition }))
);

export function ModalReducer(state, action) {
  return modalReducer(state, action);
}
