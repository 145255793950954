import { Component, Input } from '@angular/core';
import { Message } from '../models/message.model';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class ChatFormComponent {
  @Input() message: Message;
  newMessage!: any;

  constructor() {}

  send(inp: HTMLInputElement) {
    this.newMessage = {};
    inp.value = '';
  }
}
