import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ModalState } from './modal.state';

export const MODAL_STATE_NAME = 'modal';

const modalState = createFeatureSelector<ModalState>(MODAL_STATE_NAME);

export const takeModal = createSelector(modalState, state => state);

export const takeModalOrder = createSelector(modalState, state => state.id);

// export const takeSubmit = createSelector(modalState, state => state?.submit);
