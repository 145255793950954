import { ComponentRef, Injectable, TemplateRef, inject } from '@angular/core';
import { ModalComponent } from '../modal.component';
import { Store } from '@ngrx/store';
import { ComponentType } from '@angular/cdk/portal';
import { setModal } from 'src/app/state/modal/modal.action';
import { CommonModal, ModalTheme } from '../modal.model';
import { ActionModalComponent } from '../action-modal/action-modal.component';
import { ModalState } from '@state/modal/modal.state';
import { SelectActionEvent } from '@shared/components/workspace/data-table/models/actions/select-action-event.model';
import { ActionEvent } from '@shared/components/workspace/data-table/models/actions/action-event.model';
import { ViewContainerRefAdapter } from '@core/services/viewcontainer-adapter.service';

interface IComponentCash<T> {
  component: ComponentType<T>;
  ref: ComponentRef<T>;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private vcrAdapter = inject(ViewContainerRefAdapter);
  private existingComponents: IComponentCash<ModalComponent | ActionModalComponent>[] =
    [];

  isActionModal: boolean; // needs to check
  private counter = 0;

  constructor(private store: Store) {}

  createModalComponent(
    component: ComponentType<any>,
    event: ActionEvent | SelectActionEvent | null = null,
    className = 'right-side',
    isClearSelected = false,
  ) {
    return this.createModal(component, event, className, isClearSelected);
  }

  createModalTemplate(
    template: TemplateRef<any>,
    event: ActionEvent | SelectActionEvent | null = null,
    className: string = new ModalTheme().modalClassName,
    isClearSelected = false,
  ) {
    this.createModal(template, event, className, isClearSelected);
  }

  private createModal(
    view: ComponentType<any> | TemplateRef<any>,
    event: ActionEvent | SelectActionEvent | null,
    className = 'right-side',
    isClearSelected = false,
  ) {
    const modalRef = this.createComponent(ModalComponent);
    let componentRef;

    if (typeof view === 'object') {
      // Same as view instanceof TemplateRef<any>
      componentRef = modalRef.instance.createEmbeddedView(view);
    } else {
      componentRef = modalRef.instance.createComponent(view);
      componentRef.instance.modalData = event?.data;
    }

    modalRef.instance.className = className;
    modalRef.instance.order = this.counter++;
    this.setModal(isClearSelected);
    return componentRef;
  }

  private setModal(isClearSelected: boolean) {
    const modal: ModalState = {
      status: true,
      isClearSelected,
    };

    this.store.dispatch(
      setModal({
        modal,
      }),
    );
  }

  setActionModal(
    event: ActionEvent | SelectActionEvent,
  ): InstanceType<typeof ActionModalComponent> {
    const componentRef = this.createComponent(ActionModalComponent);
    componentRef.instance.openActionModal(event, event instanceof SelectActionEvent);
    this.isActionModal = true;
    return componentRef.instance;
  }

  setConfirmModal(modal: CommonModal): InstanceType<typeof ActionModalComponent> {
    const componentRef = this.createComponent(ActionModalComponent);
    componentRef.instance.openConfirmModal(modal);
    this.isActionModal = true;
    return componentRef.instance;
  }

  clear() {
    if (this.isActionModal) {
      this.existingComponents = [];
      this.vcrAdapter.remove(ActionModalComponent);
      this.vcrAdapter.remove(ModalComponent);
    } else {
      this.existingComponents = this.existingComponents.filter(
        v => v.component !== ModalComponent,
      );

      this.vcrAdapter.remove(ModalComponent);
    }
  }

  private createComponent(component: any): ComponentRef<any> {
    const isExsist = this.existingComponents.find(v => v === component);
    if (!isExsist) {
      const componentRef = this.vcrAdapter.createComponent(component);
      this.existingComponents.push({ component: component, ref: componentRef });
      return componentRef;
    } else {
      return isExsist.ref;
    }
  }
}
