import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ChatFormComponent } from './messages/form/form.component';
import { ChatComponent } from './messages/chat/chat.component';
import { MessagesComponent } from './messages/messages.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationsComponent } from './notifications.component';

@NgModule({
  imports: [SharedModule],
  declarations: [
    NotificationsComponent,
    NotificationComponent,
    ChatComponent,
    MessagesComponent,
    ChatFormComponent,
  ],
  exports: [
    NotificationsComponent,
    NotificationComponent,
    ChatComponent,
    MessagesComponent,
    ChatFormComponent,
  ],
})
export class NotificationsModule {
  constructor() {}
}
