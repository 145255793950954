import { ChangeDetectorRef, Component, EventEmitter, Input } from '@angular/core';
import { DocumentActionsComponent } from '../../../../document-actions/document-actions.component';
import { FormsModule } from '@angular/forms';
import { FileData } from '../../models/file-data';
import {
  LinkAnnotationService,
  ToolbarService,
  MagnificationService,
  NavigationService,
  TextSearchService,
  TextSelectionService,
  PrintService,
  AnnotationService,
  PdfViewerModule,
} from '@syncfusion/ej2-angular-pdfviewer';
import { InitializeAndUpload } from '@shared/util/file.util';

@Component({
  selector: 'dms-pdf',
  template: `
    <ejs-pdfviewer
      #pdfViewer
      [serviceUrl]="
        'https://ej2services.syncfusion.com/production/web-services/api/pdfviewer'
      "
      [documentPath]="src">
    </ejs-pdfviewer>

    <dialog #fileDialog>
      <ejs-pdfviewer
        #pdfViewerDialog
        [serviceUrl]="
          'https://ej2services.syncfusion.com/production/web-services/api/pdfviewer'
        "
        [documentPath]="src">
      </ejs-pdfviewer>
      <i (click)="fileDialog.open = false" class="fa-solid fa-square-xmark"></i>
    </dialog>

    <dms-document-actions
      (view)="openDialog(fileDialog)"
      (update)="updateFile($event)"
      (save)="save(pdfViewer)"
      [type]="'File'"
      [actions]="[
        { key: 'update', title: 'Update' },
        { key: 'view', title: 'View' },
        { key: 'save', title: 'Save' },
      ]">
    </dms-document-actions>
  `,
  imports: [DocumentActionsComponent, FormsModule, PdfViewerModule],
  providers: [
    LinkAnnotationService,
    ToolbarService,
    MagnificationService,
    NavigationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    AnnotationService,
  ],
  standalone: true,
  styleUrls: [`pdf.component.scss`],
})
export class PdfComponent {
  private _fileData: FileData;
  src: string;

  @Input()
  set fileData(value: FileData) {
    this._fileData = value;
    this.processFileData();
  }

  get fileData(): FileData {
    return this._fileData;
  }

  submit = new EventEmitter<string>();

  constructor(private cdr: ChangeDetectorRef) {}

  processFileData() {
    if (this._fileData) {
      const blob = this._fileData.data; //
      fetch(blob)
        .then(response => response.blob())
        .then(blobData => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.src = reader.result as string;
            this.cdr.detectChanges();
          };
          reader.readAsDataURL(blobData);
        });
    }
  }

  save(pdfViewer: any) {
    pdfViewer
      .saveAsBlob()
      .then((blob: Blob) => {
        const blobToBase64 = (blob: Blob) =>
          new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });

        blobToBase64(blob).then((base64String: string) => {
          const validBase64 = base64String.split(',')[1];
          this.submitFile(validBase64);
        });
      })
      .catch(error => {
        console.error('Error saving PDF:', error);
      });
  }

  openDialog(dialog: HTMLDialogElement) {
    if (dialog) {
      this.processFileData();
      dialog.open = true;
    }
  }

  updateFile(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file) {
      InitializeAndUpload(file, this.submitFile);
    }
  }

  submitFile = (base64: string) => {
    this.submit.emit(base64);
  };
}
