// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.dark dms-view-switcher {
  --primarySwithcer: var(--info);
  --defaultSwitcher: var(--white);
}

body.light dms-view-switcher {
  --primarySwithcer: var(--primary);
  --defaultSwitcher: var(--primarySwithcer);
}

.switch-view {
  font-size: 18px;
  color: var(--defaultSwitcher);
  font-size: 18px;
}
.switch-view div {
  cursor: pointer;
  padding: 5px;
}
.switch-view div.active {
  color: var(--primarySwithcer);
  background-color: rgba(118, 132, 255, 0.1);
}

.switch-view {
  display: flex;
  gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/workspace/view-switcher/view-switcher.component.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,+BAAA;AACF;;AAEA;EACE,iCAAA;EACA,yCAAA;AACF;;AAEA;EACE,eAAA;EACA,6BAAA;EACA,eAAA;AACF;AAAE;EACE,eAAA;EACA,YAAA;AAEJ;AAGI;EACE,6BAAA;EACA,0CAAA;AADN;;AASA;EACE,aAAA;EACA,QAAA;AANF","sourcesContent":["body.dark dms-view-switcher {\n  --primarySwithcer: var(--info);\n  --defaultSwitcher: var(--white);\n}\n\nbody.light dms-view-switcher {\n  --primarySwithcer: var(--primary);\n  --defaultSwitcher: var(--primarySwithcer);\n}\n\n.switch-view {\n  font-size: 18px;\n  color: var(--defaultSwitcher);\n  font-size: 18px;\n  div {\n    cursor: pointer;\n    padding: 5px;\n    // i {\n    //   transition: 0.2s;\n    //   font-size: 18px;\n    // }\n    &.active {\n      color: var(--primarySwithcer);\n      background-color: rgba(118, 132, 255, 0.1);\n      // i {\n      //   transition: 0.2s;\n      //   font-size: 14px;\n      // }\n    }\n  }\n}\n.switch-view {\n  display: flex;\n  gap: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
