import { Component, DestroyRef, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { setModal } from 'src/app/state/modal/modal.action';
import { INotification } from '../messages/models/alerts.model';
import { NotificationService } from './services/notifications.service';
import { NOTIFICATION_STATUS, getTypeIcon } from './model/notification.model';
import * as moment from 'moment';
import { Observable, } from 'rxjs';
import { Router } from '@angular/router';
import { selectedDocument } from '@routes/home/state/home.action';
import { takeSelectedDocument } from '@routes/home/state/home.selector';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ModalService } from '@shared/components/modal/services/modal.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./../notification-messages.scss', './notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Output() closeInbox = new EventEmitter();

  alerts: INotification[] = [
    // {
    //   id: 1,
    //   title: 'Modifiaction',
    //   type: 'On document',
    //   description: 'On document',
    //   method: 'marketing',
    //   route: 'Main Repository / wrokspace / marketing',
    //   icon: '<i class="fa fa-info-circle"></i>',
    //   date: '07/27/2022 | 5:43',
    // },
    // {
    //   id: 2,
    //   title: 'Modifiaction',
    //   type: 'On document',
    //   description: 'On document',
    //   method: 'marketing',
    //   route: 'Main Repository / wrokspace / marketing',
    //   icon: '<i class="fa fa-info-circle"></i>',
    //   date: '07/27/2022 | 5:43',
    // },
    // {
    //   id: 3,
    //   title: 'Modifiaction',
    //   type: 'On document',
    //   description: 'On document',
    //   method: 'marketing',
    //   route: 'Main Repository / wrokspace / marketing',
    //   icon: '<i class="fa fa-info-circle"></i>',
    //   date: '07/27/2022 | 5:43',
    // },
    // {
    //   id: 4,
    //   title: 'Modifiaction',
    //   type: 'On document',
    //   description: 'On document',
    //   method: 'marketing',
    //   route: 'Main Repository / wrokspace / marketing',
    //   icon: '<i class="fa fa-info-circle"></i>',
    //   date: '07/27/2022 | 5:43',
    // },
    // {
    //   id: 5,
    //   title: 'Modifiaction',
    //   type: 'On document',
    //   description: 'On document',
    //   method: 'marketing',
    //   route: 'Main Repository / wrokspace / marketing',
    //   icon: '<i class="fa fa-info-circle"></i>',
    //   date: '07/27/2022 | 5:43',
    // },
  ];

  public initals: string;
  public circleColor: string;
  private colors: string[] = ['#EB7181', '#468547', '#FFD558', '#3670B2'];
  private notification: any;
  public usersPicture: any[] = [];
  notification$: Observable<any>;

  constructor(
    private store: Store,
    private notificationService: NotificationService,
    private router: Router,
    private destoyRef: DestroyRef,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.notificationService.notification$.subscribe(data => {
      console.log(data);
    });

    this.notificationService.getAll()
    .pipe(
      // tap((notifications) => {
      //   notifications.forEach((notification) => {
      //     if(!this.usersPicture[notifications.userEmail]) {
      //       this.usersService.getById(notification.userEmail).subscribe((user: IUser) => {
      //         this.usersPicture.push({ email: notification.userEmail, picture: user.picture });
      //       })
      //     }
      //    })
      // })
    )
    .subscribe(data => {
      this.alerts = data.reverse().map(v => {
        const { id, title, description, nuxeoId, documentId, type, userId, status, userEmail, entityId } = v;
        const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));

        return {
          id,
          title,
          fullName: userEmail.split('@')[0],
          description,
          icon: getTypeIcon(v.type),
          date: v.createdOn
            ? moment(v.createdOn).format('MMMM D, YYYY')
            : 'Not Available',
          img: {
            text: this.createInitials(userEmail.split('@')[0] || 'Test user'),
            bgColor: this.colors[randomIndex],
          },
          userEmail: userEmail,
          nuxeoId,
          documentId,
          type,
          userId,
          status,
          entityId
        };
      });
    });

    this.store
      .select(takeSelectedDocument).pipe(takeUntilDestroyed(this.destoyRef)).subscribe((data) => {
        if (!data || !this.notification) return;
        this.router.navigate(['manage', 'document', this.notification.documentId], { state: { comment: this.notification.nuxeoId } });
        this.closeChat();
        this.modalService.clear();
      });
  }

  getFullName(alert): string {
    const parts = alert.title.split(' ');
    const firstName = parts[1];
    
    if (parts[2] && !['was', 'added', 'Edited', 'Removed'].includes(parts[2])) {
      return `${firstName} ${parts[2]}`;
    }
    return firstName;
  }

  getAction(alert): string {
    const title = alert.title;

    if (title.includes('was tagged in the comment')) {
      return 'was tagged in the comment';
    } else if (title.includes('added a comment')) {
      return 'added a comment';
    } else if (title.includes('Edited')) {
      return 'Edited';
    } else if (title.includes('Removed')) {
      return 'Removed';
    }
    return '';
  }

  getCurrentDocumentTitle(alert): string {
    const parts = alert.title.split(' ');
    
    const action = this.getAction(alert);

    if (action === 'Edited') {
      return parts.slice(4, -2).join(' ');
    } else if (action === 'Removed') {
      return parts.slice(4, -1).join(' ');
    }
    
    return '';
  }

  closeChat() {
    this.store.dispatch(setModal({ modal: { status: false } }));
  }

  onUserClick(userId: string | undefined) { 
    this.router.navigate(['home', 'profile', userId]);
    this.closeChat();
    this.modalService.clear();
  }

  onNotificationClick(notification) {
    // if(notification.type !== 'comment') return;
  
    this.store.dispatch(selectedDocument({ id: notification.documentId, noNavigate: true }));
    this.notification = notification;
    if(notification.status === NOTIFICATION_STATUS.UNSEEN) {
      this.notificationService.markAsSeen(notification).pipe(takeUntilDestroyed(this.destoyRef)).subscribe();
    }
  }

  onMarkAllRead(): void {
    this.notificationService.markAllRead().pipe(takeUntilDestroyed(this.destoyRef)).subscribe();
    this.closeChat();
  }

  private createInitials(name): string {
    let initals = '';

    for (let i = 0; i < name.length; i++) {
      if (name.charAt(i) === ' ') continue;

      if (name.charAt(i) === name.charAt(i).toUpperCase()) {
        initals += name.charAt(i);
        if (initals.length == 2) break;
      }
    }
    return initals;
  }

  openInfo(index) {
    // make it readed
    // if it's readed then change border  color
    this.alerts[index].opend = !this.alerts[index].opend;
  }
}
