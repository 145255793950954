// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  gap: 30px;
  color: var(--primaryBg3);
}
:host i {
  color: inherit;
}
:host div {
  cursor: pointer;
  font-size: 25px;
}
:host .notifications {
  position: relative;
}
:host .notifications .notify {
  position: absolute;
  color: var(--danger);
  left: 5.5px;
  top: 10px;
}
:host .notifications i.animate {
  display: inline-block;
  animation: swing 0.3s linear 6;
}
@keyframes swing {
  0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
}
@media only screen and (max-width: 850px) {
  :host {
    color: var(--layout);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/common/notifications/notifications.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,wBAAA;AACF;AAAE;EACE,cAAA;AAEJ;AAAE;EACE,eAAA;EACA,eAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;AADI;EACE,kBAAA;EACA,oBAAA;EACA,WAAA;EACA,SAAA;AAGN;AAAI;EACE,qBAAA;EACA,8BAAA;AAEN;AAEE;EACE;IAEE,uBAAA;EADJ;EAGE;IACE,yBAAA;EADJ;EAGE;IACE,wBAAA;EADJ;AACF;AAIE;EAvCF;IAwCI,oBAAA;EADF;AACF","sourcesContent":[":host {\n  display: flex;\n  gap: 30px;\n  color: var(--primaryBg3);\n  i {\n    color: inherit;\n  }\n  div {\n    cursor: pointer;\n    font-size: 25px;\n  }\n  .notifications {\n    position: relative;\n    .notify {\n      position: absolute;\n      color: var(--danger);\n      left: 5.5px;\n      top: 10px;\n    }\n\n    i.animate {\n      display: inline-block;\n      animation: swing 0.3s linear 6;\n    }\n  }\n\n  @keyframes swing {\n    0%,\n    100% {\n      transform: rotate(0deg);\n    }\n    25% {\n      transform: rotate(-15deg);\n    }\n    75% {\n      transform: rotate(15deg);\n    }\n  }\n\n  @media only screen and (max-width: 850px) {\n    color: var(--layout);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
