import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { ImportFilesComponent } from '@routes/manage/components/workspace/components/content/components/create-files/components/import-files/import-files.component';
import { ManageCreateStore } from '@routes/manage/components/workspace/components/content/components/create-files/store/create-store';
import { DmsTypeaheadTagsComponent } from '@shared/components/standalone/typeahead/dms-typeahead-tags/typeahead-tags.component';
import { ErrorValidationDirective } from '@shared/directives/input/error-validation.directive';
import { InputIconDirective } from '@shared/directives/input/input-icon.directive';
import { SharedModule } from '@shared/shared.module';
import { LoginService } from '../services/login.service';
import { IAccountRegister } from '../../models/account.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GroupsService } from '@common/services/groups/groups.service';
import * as moment from 'moment';
import { PasswordValidator } from '../../util/password.validator';
import { Alert } from 'src/app/models/alert.model';
import { AlertService } from '@shared/components/alert/services/alert.service';

@Component({
  selector: 'dms-register',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorValidationDirective,
    InputIconDirective,
    RouterModule,
    DmsTypeaheadTagsComponent,
    SharedModule,
    ImportFilesComponent,
  ],
  providers: [ManageCreateStore],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss',
})
export class RegisterComponent {
  private store = inject(Store);
  private formBuilder = inject(FormBuilder);
  private loginService = inject(LoginService);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private alert = inject(AlertService);
  private groupService = inject(GroupsService);

  public passwordHintTooltip = `
  Minimum 12 characters\n
  At least one Uppercase and Lowercase\n
  At least one special character
`;
  public userGroups = [];
  public groups: any;

  public startDate: Date = moment(new Date()).subtract(18, 'years').toDate();

  public registerForm = this.formBuilder.group({
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    company: [''],
    birthdate: ['', [Validators.required]],
    groups: [[]],
    password: ['', [Validators.required, PasswordValidator]],
    base64ProfileUserImage: [''],
  });

  constructor() {}

  public onSubmit(form: FormGroup): void {
    const user = this.formValueToAccountModel(form.value);
    this.loginService
      .register(user)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((_: string) => {
        this.alert.setAlert(
          new Alert(
            'Success',
            `User ${form.value.email} successfully registered`,
            'success',
          ),
        );
        this.router.navigate(['login']);
      });
  }

  public onFileEmit(file) {
    const emittedFile = file[0];
    this.imageUploaded(emittedFile);
  }

  public dateFilter(date: Date | null): boolean {
    const maxDate = new Date();
    const minDate = moment(maxDate).subtract(18, 'years').toDate();
    return moment(date).isSameOrBefore(minDate);
  }

  private formValueToAccountModel(formValue): IAccountRegister {
    return {
      firstName: formValue.firstname,
      lastName: formValue.lastname,
      company: formValue.company,
      birthdate: formValue.birthdate
        ? moment(formValue.birthdate).utc().format('YYYY-MM-DD')
        : undefined,
      groups: formValue.groups,
      email: formValue.email,
      username: formValue.email,
      credentials: [
        {
          type: 'credentials',
          temporary: false,
          value: formValue.password,
        },
      ],
      base64ProfileUserImage: formValue.base64ProfileUserImage,
    };
  }

  private imageUploaded(file) {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data: any = reader.result;
      const base64String = base64Data.substring(base64Data.indexOf(',') + 1);
      this.registerForm.patchValue({ base64ProfileUserImage: base64String });
    };

    reader.readAsDataURL(file);
  }
}
