import { Component, ViewContainerRef, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ViewContainerRefAdapter } from './core/services/viewcontainer-adapter.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor() {
    inject(ViewContainerRefAdapter).vcr = inject(ViewContainerRef);
    const translate = inject(TranslateService);
    translate.setDefaultLang('en');
    translate.use('en');

    // function filterAndDoubleEvenNumbers(): OperatorFunction<number, number> {
    //   return source$ =>
    //     source$.pipe(
    //       filter(value => value % 2 === 0),
    //       map(value => value * 2),
    //     );
    // }

    // const numbers$ = new Observable<number>(subscriber => {
    //   [1, 2, 3, 4, 5].forEach(value => subscriber.next(value));
    //   subscriber.complete();
    // });

    // numbers$.pipe(filterAndDoubleEvenNumbers()).subscribe(console.log);
  }
}
