import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { MeService } from '@routes/home/components/my-account/services/me.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable, map, switchMap } from 'rxjs';
import { FusionAuthService } from './fusion-auth.service';
import { IAccountRegister } from '../../models/account.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  meService = inject(MeService);
  fusionAuthService = inject(FusionAuthService);
  cookieService = inject(CookieService);
  router = inject(Router);
  http = inject(HttpClient);

  registerAPI = environment.register;

  logIn(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    });

    this.setCookies(`${username}`, `${password}`);

    return this.meService
      .getMe(headers)
      .pipe(
        switchMap(account =>
          this.fusionAuthService.logIn(username, password).pipe(map(_ => account)),
        ),
      );
  }

  register(user: IAccountRegister): Observable<any> {
    return this.http.post(`${this.registerAPI}`, user);
  }

  getUserInfoFromCookies(): string {
    return this.cookieService.get('user');
  }

  getFusionToken() {
    const token = localStorage.getItem('token');
    // const token = this.cookieService.get('fusion_token');

    if (token) {
      return token;
    }

    return '';
  }

  getFusionRefreshToken() {
    const token = localStorage.getItem('refresh_token');
    // const token = this.cookieService.get('fusion_token');

    if (token) {
      return token;
    }

    return '';
  }

  private setCookies(username: string, password: string) {
    this.cookieService.set('user', `${username}:${password}`);
  }

  logOut() {
    this.cookieService.deleteAll();
    this.router.navigate(['/login']);
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token');
  }
}
