import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'dms-checkbox',
  templateUrl: './dms-checkbox.component.html',
  styleUrls: ['./dms-checkbox.component.scss'],
  standalone: true,
  imports: [FormsModule],
})
export class DmsCheckboxComponent {
  @Input() control: boolean;
  @Input() id: string | number = 0;
  @Output() setChacked = new EventEmitter();

  constructor() {}

  onChangeSelected(chacked: boolean) {
    return this.setChacked.emit(chacked);
  }
}
