import { IDocumentEntity } from '@shared/models/document.model';
import * as moment from 'moment';

export const convertDocumentToView = (documentsList: IDocumentEntity[]) =>
  documentsList.map(v => {
    if (v.properties) {
      const lastContributor = v.properties['dc:lastContributor'];
      const version = v.properties['uid:major_version'];
      const lastModified = moment(v.lastModified).format('DD/MM/YYYY');
      return {
        ...v,
        lastModified,
        lastContributor,
        version,
      };
    } else {
      return v;
    }
  });
