import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  inject,
} from '@angular/core';
import { DocumentActionsComponent } from '../../../../document-actions/document-actions.component';
import { NgxPhotoEditorModule, NgxPhotoEditorService } from 'ngx-photo-editor';
import { ModalService } from '@shared/components/modal/services/modal.service';
import {
  CommonModal,
  ModalParams,
  ModalTheme,
} from '@shared/components/modal/modal.model';
import { AlertService } from '@shared/components/alert/services/alert.service';
import { Alert } from 'src/app/models/alert.model';
import { FILE_TYPES } from '../../models/file-type';
import { InitializeAndUpload } from '@shared/util/file.util';

@Component({
  selector: '[dms-picture]',
  template: `<img [src]="fileData.data" [alt]="fileData.name" />

    <dms-document-actions
      (view)="pictureDialog.open = true"
      (download)="downloadPicture()"
      (update)="changePicture($event)"
      [type]="'Picture'"
      [actions]="[
        { key: 'download', title: 'Download' },
        { key: 'view', title: 'View' },
        { key: 'update', title: 'Update' }
      ]">
    </dms-document-actions>

    <dialog #pictureDialog>
      <img [src]="fileData.data" [alt]="fileData.name" />
      <i (click)="pictureDialog.open = false" class="fa-solid fa-square-xmark"></i>
    </dialog>`,
  imports: [DocumentActionsComponent, NgxPhotoEditorModule],
  styleUrls: ['./picture.component.scss'],
  standalone: true,
})
export class PictureComponent {
  private _fileData: any;
  service = inject(NgxPhotoEditorService);
  modalService = inject(ModalService);
  alertService = inject(AlertService);
  updatedPicture: string;
  acceptTypes = Object.keys(FILE_TYPES.Picture).join();
  currentDoc;
  selectedFile = '';
  destroyRef = inject(DestroyRef);

  submit = new EventEmitter();

  @Input()
  set fileData(value: any) {
    this._fileData = value;
  }

  get fileData(): any {
    return this._fileData;
  }

  constructor() {}

  edit() {
    this.service
      .open(this.fileData.data, {
        aspectRatio: 4 / 3,
        autoCropArea: 1,
      })
      .subscribe(data => {
        if (data.base64) {
          this.updatedPicture = data.base64;
          this.fileData.data = data.base64;
        } else {
          console.warn(`Something went wrong`);
        }
      });
  }

  changePicture(event) {
    const file = event.target.files[0];
    if (file) {
      InitializeAndUpload(file, this.submitPicture);
    }
  }

  submitPicture = (base46: string) => {
    this.submit.emit(base46);
  };

  openPictureModal(event) {
    console.log(event);
  }

  editMock() {
    this.service
      .open(
        `https://media.gettyimages.com/id/1389170684/pt/foto/ziggurat-of-ur.jpg?s=2048x2048&w=gi&k=20&c=za04jh8M0PjEWnybMiIuDMvvOZN3UgoHNplnmsHvKhY=`,
        {
          aspectRatio: 4 / 3,
          autoCropArea: 1,
        },
      )
      .subscribe(data => {
        if (data.base64) {
          this.updatedPicture = data.base64;
          this.fileData.data = data.base64;
        } else {
          console.warn(`Something went wrong`);
        }
      });
  }

  downloadPicture() {
    const modalRef = this.openModal();
    modalRef.confirm.subscribe(() => {
      this.download();
      this.alertService.setAlert(
        new Alert(`Download`, `${this.fileData.name} Downloaded successfuly`, 'info'),
      );
    });
  }

  private download() {
    const a = document.createElement('a');
    a.href = this.fileData.data;
    a.download = this.fileData.name || 'download';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  private openModal() {
    return this.modalService.setConfirmModal(
      new CommonModal(
        new ModalParams(
          {
            title: 'Download the pictrue?',
            description: `
                    Are you sure you want to download this picture?.
                    `,
          },
          'Download',
        ),
        new ModalTheme('info'),
      ),
    );
  }
}
