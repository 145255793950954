import { createAction, props } from '@ngrx/store';
import { SelectedRows } from '@shared/components/workspace/data-table/models/data-table.model';

export const SELECTED_ROWS = '[select actions] selected rows';
export const SET_SELECTED_ROWS = '[select actions] selected rows';

export const SELECT_ACTIONS = '[select actions] select actions';
export const SELECT_ALL_ROWS = '[select actions] select all rows';

export const SELECT_ROW = '[select actions] select row';
export const SET_SELECT_ROW = '[select actions] select row';

export const IS_SELECT_ALL = '[select actions] is select all';

export const selectRows = createAction(
  SELECTED_ROWS,
  props<{ selectedItems: SelectedRows[] }>(),
);

export const setSelectedRows = createAction(
  SET_SELECTED_ROWS,
  props<{ selectedItems: SelectedRows[] }>(),
);

export const selectAllRows = createAction(
  SELECT_ALL_ROWS,
  props<{ isSelectAll: boolean }>(),
);

export const setSelectedRow = createAction(
  SELECT_ROW,
  props<{ selectedItem: SelectedRows }>(),
);

// export const isSelectAllRows = createAction(
//   IS_SELECT_ALL,
//   props<{ isSelectAll: boolean }>(),
// );
