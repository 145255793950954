import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { setModal } from 'src/app/state/modal/modal.action';
import { ModalParams } from '../modal.model';

@Component({
  selector: 'dms-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss'],
})
export class CommonModalComponent implements OnInit, OnDestroy {
  @Input() params: ModalParams;
  @Input() isConfirmModal: boolean;
  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();
  private timeOut: ReturnType<typeof setTimeout>;

  constructor(private store: Store) {}

  ngOnInit() {
    const time = this.params?.timeout;
    if (time) {
      this.timeOut = setTimeout(() => {
        this.closeModal();
      }, time);
    }
  }

  closeModal() {
    if (!this.isConfirmModal) {
      this.store.dispatch(setModal({ modal: { status: false } }));
    } else {
      this.cancel.emit();
    }
  }

  submit() {
    this.confirm.emit();
    this.closeModal();
  }

  ngOnDestroy() {
    clearTimeout(this.timeOut);
  }
}
