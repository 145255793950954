import { INuxeoEntity } from '../../../shared/models/nuxeo-entity.model';
import { ILocalPermissionsEntries } from '../models/local-permissions.model';
import { IUser } from '@common/models/users.model';
import { IDocumentEntity } from '@shared/models/document.model';

export interface HomeState {
  selectedDocument: IDocumentEntity | null | any;
  selectedUser: IUser | null;
  localPermissions: INuxeoEntity<ILocalPermissionsEntries> | null;
}

export const initialState: HomeState = {
  selectedDocument: null,
  selectedUser: null,
  localPermissions: null,
};
