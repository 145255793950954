import { Component } from '@angular/core';

@Component({
  selector: 'dms-admin-navigation',
  templateUrl: './admin-navigation.component.html',
  styleUrls: ['./admin-navigation.component.scss'],
})
export class AdminNavigationComponent {
  tablist = [
    { title: 'System Information', path: '/admin/system-information' },
    { title: 'Activity', path: '/admin/activity' },
    { title: 'Users and Groups', path: '/admin/users-and-groups' },
    { title: 'Announcements', path: '/admin/announcements' },
    { title: 'Monitoring', path: '/admin/monitoring' },
    { title: 'Permissions', path: '/admin/permissions' },
    { title: 'Cloud Services', path: '/admin/cloud-services' },
    { title: 'Workflow', path: '/admin/workflow-models' },
  ];
}
