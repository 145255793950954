import { HttpHeaders, HttpParams } from '@angular/common/http';

export const createRequestOption = (req?): HttpParams => {
  let options: HttpParams = new HttpParams();
  if (req) {
    Object.keys(req).forEach(key => {
      options = options.set(key, req[key]);
    });
  }

  return options;
};

export const createRequestHeaders = (headers?) => {
  let options: HttpHeaders = new HttpHeaders();
  if (headers) {
    Object.keys(headers).forEach(key => {
      options = options.set(key, headers[key]);
    });
  }

  return options;
};
