import { catchError, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { ActionEvent } from '@shared/components/workspace/data-table/models/actions/action-event.model';
import { setModal } from '@state/modal/modal.action';
import { DisplayedDataFilter, deleteDisplayedData } from './displayed-data.action';
import { deleteSuccess } from './util/delete-success';
import { dummyAction } from '@state/app.actions';
import { LoaderService } from '@shared/components/loader/loader.service';
import * as moment from 'moment';
import { takeDisplayedData } from './displayed-data.selector';
import { FilteredDisplayedData } from './models/filtered-displayed-data.model';

@Injectable()
export class DisplayedDataEffects {
  constructor(
    private actions$: Actions,
    private injector: Injector,
    private store: Store,
    private loader: LoaderService,
  ) {}

  deleteData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteDisplayedData),
      mergeMap(({ event, service }) => {
        let deleteMethod: Observable<null | null[]> = of();
        const _service = this.injector.get(service);

        if (event instanceof ActionEvent) {
          const id = event.data.uid;
          deleteMethod = _service[event.action.key](id);
        } else {
          const removedItems: Array<Observable<null>> = [];
          event.data.forEach(v => removedItems.push(_service[event.action.key](v.id)));
          deleteMethod = forkJoin(removedItems);
        }

        return deleteMethod.pipe(
          deleteSuccess(event),
          tap(_ => this.store.dispatch(setModal({ modal: { status: false } }))),
          catchError(() => {
            this.store.dispatch(setModal({ modal: { status: false } }));
            return of(dummyAction());
          }),
        );
      }),
    ),
  );

  filter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DisplayedDataFilter.filterByDate),
      withLatestFrom(this.store.select(takeDisplayedData)),
      mergeMap(([{ range }, displayedData]) => {
        if (range) {
          const { start, end } = range;

          if (start && end) {
            this.loader.setLoading({ key: 'manage-workspace', status: true });

            const hasDateProperty = (entity): entity is FilteredDisplayedData =>
              'date' in entity;

            const filteredDisplayedData = displayedData.filter(item => {
              if (hasDateProperty(item) && moment(item.date).isValid()) {
                const itemDate = moment(item.date);
                return itemDate.isBetween(start, end, null, '[]');
              } else {
                return false;
              }
            }) as FilteredDisplayedData[];

            this.loader.setLoading({ key: 'manage-workspace', status: false });

            return of(
              DisplayedDataFilter.filterByDateSuccess({
                filteredDisplayedData,
                range,
              }),
            );
          }
        } else {
          this.loader.setLoading({ key: 'manage-workspace', status: false });
          return of(/* some default action or value */);
        }

        return of(dummyAction());
      }),
    ),
  );
}
