import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { DisplayedData, Pagination } from 'src/app/models/app.model';
import { IFilteredDisplayedData } from './models/filtered-displayed-data.model';

export interface DisplayedDataState {
  displayData: EntityState<DisplayedData>;
  pagination: Pagination;
  filters?: IFilteredDisplayedData;
}

export const displayedDataAdapter = createEntityAdapter<DisplayedData>({
  selectId: displayedData => (displayedData.id ? String(displayedData.id) : ''),
});

export const initialState: DisplayedDataState = {
  displayData: displayedDataAdapter.getInitialState(),
  pagination: {
    page: 1,
    pageSize: 5,
  },
};
