import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
} from '@angular/core';
import { NotificationsModule } from 'src/app/common/notifications/notifications.module';
import { DmsTypeaheadComponent } from '@shared/components/standalone/typeahead/dms-typeahead/typeahead.component';
import { SafePipe } from '../../../pipes/safe.pipe';
import { DataService } from '@routes/home/services/data.service';
import { Store } from '@ngrx/store';
import { SEARCH_DATA_TYPE } from '@routes/home/models/search-data.model';
import { selectedDocument, selectedUser } from '@routes/home/state/home.action';
import { getDocumentIcon } from '@icons/file/icons';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs';
import { GetDocumentService } from '@routes/home/components/document-details/components/view-document/services/get-document.service';

@Component({
  selector: 'dms-quick-search',
  templateUrl: `./quick-search.component.html`,
  standalone: true,
  styleUrls: ['./quick-search.component.scss'],
  imports: [DmsTypeaheadComponent, CommonModule, NotificationsModule, SafePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickSearchComponent {
  _items: any[] = [];
  isResult = false;

  private dataService = inject(DataService);
  private store = inject(Store);
  private cdr = inject(ChangeDetectorRef);
  private router = inject(Router);
  private getDocumentService = inject(GetDocumentService);

  getItems(value: string) {
    this._items = this._items.filter(v => v !== +value);
    if (value) {
      this.getSearchedData(value);
      this.isResult = true;
    } else if (this._items) {
      this._items = [];
    }
  }

  searchByItem(value) {
    if (value.type === SEARCH_DATA_TYPE.USER) {
      this.store.dispatch(selectedUser({ id: value.id }));
    } else {
      this.store.dispatch(selectedDocument({ id: value.id, noNavigate: true }));
      this.router.navigate(['manage', 'document', value.id]);
    }
  }

  private getSearchedData(text: string) {
    this.dataService
      .getSearchedData(text)
      .pipe(
        switchMap(data => {
          const ids = data.map(doc => doc.id);
          return this.getDocumentService.getDocumentsByIds(ids);
        }),
      )
      .subscribe(data => {
        if (data) {
          this._items = this.getSearchDataIcon(data);
          this.cdr.markForCheck();
        }
      });
  }

  private getSearchDataIcon(data) {
    return data.map(v => {
      if (v) {
        const type = v.type?.toLowerCase();
        return type !== 'user' ? this.createDocIcon(v, type) : v;
      }
    });
  }

  private createDocIcon(v, type) {
    v.icon = {
      template: getDocumentIcon(v.extraProperties.documentProperties), // hesa
      value: type,
    };
    return v;
  }
}
