import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderComponent } from '@shared/components/loader/loader.component';
import { LoaderService } from '@shared/components/loader/loader.service';

@Directive({
  selector: '[dmsLoader]',
  standalone: true,
})
export class LoaderDirective implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input() set dmsLoader(key: string | null) {
    if (key) {
      this.setEmbededView();
      const loading$ = this.loaderService.takeLoading(key);

      loading$.pipe(takeUntil(this.destroy$)).subscribe((isLoading: boolean) => {
        if (!isLoading) {
          this.setEmbededView();
        } else {
          this.viewContainer.clear();
          this.viewContainer.createComponent(LoaderComponent);
        }

        this.cdr.detectChanges();
      });
    }
  }

  private setEmbededView() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
