import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MeService } from '../my-account/services/me.service';
import { map, Observable } from 'rxjs';
import { LetDirective } from '@shared/directives/let.directive';
import { SharedModule } from '@shared/shared.module';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from '@shared/components/alert/services/alert.service';
import { Alert } from 'src/app/models/alert.model';
import { LoaderDirective } from '@shared/directives/loader.directive';
import { LoaderService } from '@shared/components/loader/loader.service';

@Component({
  selector: 'dms-my-profile',
  standalone: true,
  imports: [CommonModule, LetDirective, SharedModule, LoaderDirective],
  templateUrl: './my-profile.component.html',
  styleUrl: './my-profile.component.scss',
})
export class MyProfileComponent implements OnInit {
  private usersService = inject(MeService);
  account$: Observable<any>;

  constructor(
    private sanitizer: DomSanitizer,
    private alert: AlertService,
    private loader: LoaderService,
  ) {}

  ngOnInit() {
    this.loader.setLoading({ key: 'file-view-loader', status: true });
    this.loadProfile();
  }

  private convertBase64ToImage(base64String: string, contentType = 'image/png') {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });

    const imageUrl = URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  loadProfile() {
    this.account$ = this.usersService.getCurrentUserProfile().pipe(
      map(user => {
        if (user) {
          const pic = this.convertBase64ToImage(user.base64ProfileImage);
          this.loader.setLoading({ key: 'file-view-loader', status: false });

          return {
            id: user.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            username: user.username,
            groups: user.nuxeoProperties?.groups,
            birthDate: user.birthdate,
            base64Src: pic,
          };
        } else {
          throw new Error('User is not define');
        }
      }),
    );
  }

  uploadImage(id: string, fileInp: HTMLInputElement) {
    if (fileInp && fileInp.files && fileInp.files[0]) {
      const file = fileInp.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Image = (reader.result as string).split(',')[1];
        this.usersService.changeProfilePicture(id, base64Image).subscribe(_ => {
          this.loadProfile();
          this.loader.setLoading({ key: 'file-view-loader', status: true });

          this.alert.setAlert(
            new Alert('Success', 'Profile Picture Changed Successfuly', 'success'),
          );
        });
      };
    }
  }

  deleteImage(): void {
    this.usersService.deleteUserProfileImage().subscribe(response => {
      console.log('Image deleted successfully', response);
      this.loadProfile();
    });
  }
}
