import { createReducer, on } from '@ngrx/store';
import { toggleSidebar } from './sidebar-menu.action';
import { initialState, SidebarMenuState } from './sidebar-menu.state';

const _sidebarMenuReducer = createReducer(
  initialState,
  on(toggleSidebar, (state: SidebarMenuState, { isOpend }) => ({
    ...state,
    isOpend,
  })),
);

export function SidebarMenuReducer(state, action) {
  return _sidebarMenuReducer(state, action);
}
