import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private loginService: LoginService,
    private http: HttpClient,
  ) {}

  isAuthenticated(): boolean {
    return !!this.loginService.getUserInfoFromCookies();
  }

  get account(): string {
    return this.loginService.getUserInfoFromCookies();
  }

  get fusionToken() {
    return this.loginService.getFusionToken();
  }
}
