import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, catchError, tap, throwError } from 'rxjs';
import { WebSocketService } from './ws.service';
import { NOTIFICATION_STATUS } from '../model/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private nuxeoApiUrl = `${environment.nuxeoApiUrl}`;
  // private socket$: WebSocketSubject<any>;
  notification$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private wsService: WebSocketService,
  ) {
    // this.socket$ = webSocket('ws:///topic/queue/notification');
    // this.socket$.subscribe(grso => {
    //   console.clear();
    //   console.log(grso);
    // });
    // ws:///topic/queue/notification
  }

  getAll() {
    // this.socket$.subscribe(datra => {
    //   console.log(datra);
    // });
    return this.http.get<any>(`${this.nuxeoApiUrl}/notifications/by-current-user`).pipe(
      tap(v => {
        console.log(v);
      }),
    );
  }

  create(body: any) {
    return this.http.post<any>(`${this.nuxeoApiUrl}/notifications`, body).pipe(
      tap(notification => {
        this.notification$.next(notification);
      }),
      catchError(error => {
        this.notification$.next(body);
        return throwError(() => error);
      }),
    );
  }

  markAsSeen(notification: any) {
    return this.http.patch<any>(`${this.nuxeoApiUrl}/notifications/${notification.id}`, {
      status: NOTIFICATION_STATUS.DISMISSED,
    });
  }

  markAllRead() {
    return this.http.post<any>(`${this.nuxeoApiUrl}/notifications/mark-read`, {});
  }

  getMessages() {
    // return this.socket$.asObservable();
    return this.getAll();
  }
}
