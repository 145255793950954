import { getDocumentIcon } from '@icons/file/icons';
import * as _ from 'lodash';

export class ManageMenuTree {
  constructor(
    public name: string,
    public level: number = 1,
    public isFolder: boolean, // hasChild
    public loading: boolean,
    public uid: string,
    public type: string,
    public expand = false,
    public active = false,
    public icon?: string,
  ) {}
}

export class NewManageMenuTree {
  parent: string;
  icon: string;

  constructor(
    public name: string,
    public isFolder: boolean,
    public uid: string,
    public type: string,
    public children: NewManageMenuTree[] = [],
    public loading = false,
    public expand = false,
    public level = 0,
    public emptyFolder = false,
    public active = false,
  ) {
    if (this.children.length) {
      this.expand = true;
      this.children.forEach(child => {
        child.parent = this.uid;
        child.calculateLevel(this.level + 1);
      });
    }

    this.icon = getDocumentIcon(this.type.toLowerCase()); // hesa 
  }

  calculateLevel(parentLevel: number) {
    this.level = parentLevel + 1;
    if (this.children.length) {
      this.children.forEach(child => {
        child.calculateLevel(this.level + 1);
      });
    }
  }
}

export function transformToMenuTree(
  menu: NewManageMenuTree,
  children?: NewManageMenuTree[],
): NewManageMenuTree {
  const childrenMenu = children
    ? _.cloneDeep(children)
    : (menu.children || []).map(child => transformToMenuTree(child));
  const { name, isFolder, uid, type, loading, expand, level, emptyFolder, active } = menu;

  return new NewManageMenuTree(
    name,
    isFolder,
    uid,
    type,
    childrenMenu,
    loading,
    expand,
    level,
    emptyFolder,
    active,
  );
}

export interface IDateFilter {
  start: string;
  end: string;
}

export interface IActiveFilter {
  date: IDateFilter;
}
