import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IDocumentEntity } from '@shared/models/document.model';
import { NewManageMenuTree } from '@state/manage/manage.model';
import { convertDocumentToNewMenuTree } from '../util/manage.util';
import { SearchDocumentService } from './search-document.service';
import { ManageState } from '@state/manage/manage.state';
import { Pagination } from 'src/app/models/app.model';

@Injectable({ providedIn: 'root' })
export class SourceDataServiceLastet {
  nuxeoUrl = `${environment.nuxeoURL}`;
  private levelCount = 0;
  private firstCall = true;

  constructor(
    private http: HttpClient,
    private dataBaseService: SearchDocumentService,
  ) {}

  initialData(path = '', pagination: Pagination): Observable<ManageState> {
    return this.dataBaseService.getDocumentByPath(path, pagination).pipe(
      map(({ mainDocument, childrenDocuments }) => {
        let menuTree: NewManageMenuTree[] = [];
        this.levelCount = 0;
        const root = { ...mainDocument, expand: true };

        menuTree.push(this.setMenu(root));
        menuTree = [
          ...menuTree.filter(menu => menu.uid !== root.uid),
          this.setMenu(root, childrenDocuments),
        ];

        const documentsList = childrenDocuments;
        const currentDocument = mainDocument;

        return {
          menuTree,
          documentsList,
          currentDocument,
        };
      }),
    );
  }

  private setMenu(
    nuxeoDocument: IDocumentEntity,
    childrenDocuments: IDocumentEntity[] = [],
  ) {
    const menuTree = convertDocumentToNewMenuTree(nuxeoDocument, childrenDocuments);
    return menuTree;
  }
}
