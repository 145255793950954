import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { LoginService } from '../services/login.service';
import { Router, RouterModule } from '@angular/router';
import { AlertService } from '@shared/components/alert/services/alert.service';
import { convertUserToDisplayedUser } from '@common/services/users/users.util';
import { HttpErrorResponse } from '@angular/common/http';
import { IUser } from '@common/models/users.model';
import { ErrorValidationDirective } from '@shared/directives/input/error-validation.directive';
import { USER } from '@icons/dms-fontawesome-icons';
import { InputIconDirective } from '@shared/directives/input/input-icon.directive';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'dms-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ErrorValidationDirective,
    InputIconDirective,
    RouterModule
  ],
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  userIcon = USER; // objectov ara vor sax impoet
  loginService = inject(LoginService);
  router = inject(Router);
  alertService = inject(AlertService);

  loginForm = this.formBuilder.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  constructor(private formBuilder: FormBuilder) {}

  onSubmit() {
    const { username, password } = this.loginForm.value;
    if (this.loginForm.valid && username && password) {
      this.loginService.logIn(username, password).subscribe(
        (user: IUser) => {
          const fullName = convertUserToDisplayedUser(user).fullName;
          this.alertService.setAlert(
            new Alert('Success Login', `Welcome ${fullName}`, 'success'),
          );
          this.router.navigate(['/home/dashboard']);
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        },
      );
    } else {
      console.log('Form is invalid'); // alert...
    }
  }
}
