import { createFeatureSelector, createSelector } from '@ngrx/store';
import { HomeState } from './home.state';

export const HOME_STATE_NAME = 'home';
const homeState = createFeatureSelector<HomeState>(HOME_STATE_NAME);

export const takeSelectedDocument = createSelector(
  homeState,
  state => state.selectedDocument,
);

export const takeSelectedUser = createSelector(homeState, state => state.selectedUser);

export const takeLocalPermissions = createSelector(
  homeState,
  state => state?.localPermissions,
);
