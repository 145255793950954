import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { currentRoute } from '@state/router-state/router.selector';
import { filter, tap } from 'rxjs';

@Component({
  selector: 'dms-tablet-navigation',
  templateUrl: './tablet-navigation.component.html',
  styleUrls: ['./tablet-navigation.component.scss'],
})
export class TabletNavigationComponent implements OnInit {
  activeUrl: string;
  constructor(private store: Store) {}

  ngOnInit() {
    this.store
      .select(currentRoute)
      .pipe(
        filter(v => !!v?.url),
        tap(route => {
          this.activeUrl = route.url.split('/')[1];
        }),
      )
      .subscribe();
  }
}
