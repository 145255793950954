import { NgIf, NgTemplateOutlet, NgFor } from '@angular/common';
import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  Output,
  ChangeDetectorRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  TemplateRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { InputIconDirective } from '@shared/directives/input/input-icon.directive';
import { TypeahedDetails } from '@shared/components/standalone/typeahead/models/typeahed.model';
import { DisplayedValue } from '@shared/pipes/display-name.pipe';
import { ClickOutsideDirective } from '@shared/directives/click-outside.directive';
import { SortPipe } from './pipes/sort.pipe';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'dms-typeahead',
  templateUrl: './typeahead.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    NgFor,
    InputIconDirective,
    DisplayedValue,
    ClickOutsideDirective,
    SortPipe,
    FormsModule,
    ReactiveFormsModule,
  ],
  styleUrls: ['./typeahead.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DmsTypeaheadComponent<T> implements OnInit, OnDestroy {
  @ViewChild('searchInp') searchInp: ElementRef;
  private _items: T[];
  @Input() set items(_items: T[]) {
    this._items = [..._items];
    this.filteredItems = [..._items];
  }
  get items() {
    return this._items;
  }

  private _details: TypeahedDetails = {
    placeholder: 'Find Member...',
    value: '',
    local: true,
    isSelectable: false,
    ms: 400,
    hide: true,
    pure: false,
    isOpen: false,
    preLoad: true,
    sort: false,
    icon: '',
  };

  @Input() set details(typeahedDetails: TypeahedDetails) {
    this._details = {
      ...this._details,
      ...typeahedDetails,
    };
  }

  get details() {
    return this._details;
  }

  @Input() displayName: string;
  @Input() tpl: TemplateRef<any>;
  @Input() className = 'input-md';
  @Input() formcontrol: any = new FormControl();

  @Output() selectItem = new EventEmitter<T>();
  @Output() searchValue = new EventEmitter<string>();

  @Output() missingValue = new EventEmitter<string>();

  private search$: Subject<string> = new Subject();

  show: boolean;
  filteredItems: T[] = [];
  activeItem: number | null;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.details.preLoad) {
      this.searchValue.emit('');
    } else if (this.details?.defaultValue) {
      this.searchValue.emit(this.details.defaultValue);
      this.details.value = this.details?.defaultValue;
    }
    if (this.details?.isOpen) {
      this.showItems();
    }

    this.search$
      .pipe(distinctUntilChanged(), debounceTime(this.details?.ms ?? 0))
      .subscribe((value: string) => {
        if (this.details?.local) {
          this.search(value.toString().trim());
        } else {
          this.searchValue.emit(value);
        }
      });
  }

  keyup(input: HTMLInputElement, event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.enterValue(input);
      input.value = '';
      return;
    }

    const value = input.value;
    const positions = {
      38: 'up',
      39: 'right',
      40: 'down',
      37: 'left',
    };

    const key = positions[event.keyCode];

    if (event && key) {
      if (!this.activeItem) {
        this.activeItem = 0;
      }

      const isPositive = key === 'down' || key === 'right';
      this.setActiveItem(isPositive);
    } else {
      this.search$.next(value);
      this.activeItem = null;
    }
  }

  search(value: string) {
    if (value.length !== 0) {
      const filterItms = item => {
        const lowerCase = str => `${str}`.toLowerCase();
        const lowerCaseItem = lowerCase(this.displayName ? item[this.displayName] : item);
        const lowerCaseValue = lowerCase(value);

        return this.details.pure
          ? lowerCaseItem === lowerCaseValue
          : lowerCaseItem.indexOf(lowerCaseValue) !== -1;
      };

      this.filteredItems = [...this.items.filter(filterItms)];
    } else {
      this.filteredItems = [...this.items];
    }

    if (this.filteredItems.length === 1) {
      this.activeItem = 0;
    }
    this.cdr.detectChanges();
  }

  setActiveItem(isPositive: boolean) {
    if (this.activeItem !== null && this.activeItem === 0 && !isPositive) {
      this.activeItem = this.filteredItems.length;
    }

    if (this.activeItem === this.filteredItems.length - 1 && isPositive) {
      this.activeItem = -1;
    }

    if (this.activeItem !== null) {
      if (isPositive) {
        this.activeItem++;
      } else {
        this.activeItem--;
      }
    }
  }

  enterValue(input: HTMLInputElement) {
    if (this.activeItem === null) {
      this.missingValue.emit(input.value);
      input.value = '';
      this.filteredItems = [...this.items];
      input.focus();
      return;
    }

    if (this.filteredItems.length === 1) {
      input.blur();
      this.setItem(this.filteredItems[0]);
    } else if (this.activeItem !== null) {
      const item = this.filteredItems[this.activeItem];
      this.setItem(item);
    }
  }

  setItem(item: T, event?: any) {
    event.stopPropagation();

    this.details.value = item[this.displayName];
    this.selectItem.emit(item);

    if (!this.details?.isSelectable) {
      this.hideItems();
    }
  }

  showItems() {
    this.show = true;
  }

  toggleItems(searchInpEl: HTMLInputElement) {
    if (this.show) {
      this.hideItems();
    } else {
      searchInpEl.focus();
    }
  }

  hideItems() {
    this.activeItem = null;
    this.show = false;
  }

  ngOnDestroy() {
    this.search$.complete();
  }
}
