import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'dms-modal-header',
  template: `
    <h2>{{ title }}</h2>
    <div (click)="closeModal.emit()" class="close">
      <i class="fa-solid fa-angle-right"></i>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
      h2 {
        font-size: 24px;
      }
      .close {
        font-size: 30px;
        color: var(--danger);
        cursor: pointer;
        padding: 6px 15px;
        border-radius: 5px;
        transition: 0.2s;
        * {
          transition: 0.2s;
        }
        &:hover {
          background: linear-gradient(
            135deg,
            rgba(219, 106, 106, 0.15) 0%,
            rgba(133, 39, 39, 0.15) 100%
          );
          i {
            transition: 0.2s;
            transform: scale(0.8);
          }
        }
      }
    `,
  ],
  standalone: true,
})
export class ModalHeaderComponent {
  @Input() title = 'card';
  @Output() closeModal = new EventEmitter();
}
