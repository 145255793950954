import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, mergeMap, withLatestFrom, repeat } from 'rxjs/operators';
import { selectAllRows, setSelectedRows } from './select-items.action';

import { SelectedRows } from '@shared/components/workspace/data-table/models/data-table.model';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { isActiveSelectedItem } from './select-items.selector';
import { of } from 'rxjs';
import { takeDisplayedData } from '@state/displayed-data/displayed-data.selector';

@Injectable()
export class SelectActionEffects {
  constructor(private actions$: Actions, private store: Store) {}

  selectAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectAllRows),
      mergeMap(({ isSelectAll }) =>
        this.store.select(takeDisplayedData).pipe(
          map((data: any[]) => {
            const selectedItems: SelectedRows[] = [];
            data.map(v => selectedItems.push({ key: v.id, isSelected: isSelectAll }));

            return setSelectedRows({ selectedItems });
          }),
        ),
      ),
    ),
  );

  // selectRow$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(setSelectedRows),
  //     mergeMap(({ selectedItems }) =>
  //       this.store.select(displayData).pipe(
  //         map((data: any[]) => {
  //           const selectedItems: SelectedRows[] = [];
  //           data.map((v, i) => selectedItems.push({ key: v.id, isSelected: true }));

  //           debugger;
  //           return setSelectedRows({ selectedItems });
  //         }),
  //       ),
  //     ),
  //   ),
  // );

  clearSelected$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_NAVIGATION),
        withLatestFrom(this.store.select(isActiveSelectedItem)),
        mergeMap(([{ payload }, isSelected]) => {
          if (payload['event'] && isSelected) {
            this.store.dispatch(selectAllRows({ isSelectAll: false }));
          }
          return of();
        }),
        repeat(),
      ),
    { dispatch: false },
  );
}
