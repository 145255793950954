import { Injectable, inject } from '@angular/core';
import { ViewContainerRefAdapter } from '@core/services/viewcontainer-adapter.service';
import { BehaviorSubject } from 'rxjs';
import { Alert } from 'src/app/models/alert.model';
import { AlertComponent } from '../alert.component';
import { ComponentType } from '@angular/cdk/portal';

@Injectable({ providedIn: 'root' })
export class AlertService {
  alerts$: BehaviorSubject<Alert> = new BehaviorSubject(new Alert('', '', 'info'));
  private alertList: Alert[] = [];
  private vcrAdapter = inject(ViewContainerRefAdapter);
  private isExists = false;

  setAlert(alert: Alert) {
    if (!this.isExists) {
      this.createAlertComponent();
      this.isExists = true;
    }

    this.alertList.push(alert);
    this.alerts$.next(alert);
  }

  private createAlertComponent() {
    this.vcrAdapter.createComponent(AlertComponent);
  }

  destroy(component: ComponentType<any>) {
    this.vcrAdapter.remove(component);
    this.isExists = false;
  }
}
