import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FusionAuthService {
  private cookieService = inject(CookieService);
  private http = inject(HttpClient);
  private newToken = environment.newToken;
  private nuxeoApiUrl = environment.nuxeoApiUrl;
  private nuxeoLoginUrl = environment.login;

  logIn(username: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = JSON.stringify({
      username: username,
      password: password,
    });
    return this.http
      .post<any>(`${this.nuxeoLoginUrl}`, body, {
        headers: headers,
      })
      .pipe(
        tap(response => {
          if (response && response.access_token) {
            localStorage.setItem('token', response.access_token);
            localStorage.setItem('refresh_token', response.refresh_token);

            this.cookieService.set('fusion_token', response.access_token);
          } else {
            console.error(`Can't log in`);
          }
        }),
      );
  }

  getRefreshToken(): Observable<any> {
    return this.http.post(this.newToken, {
      refresh_token: localStorage.getItem('refresh_token'),
    });
  }

  getUserInfoFromCookies(): string {
    return this.cookieService.get('user');
  }

  logOut() {
    this.cookieService.deleteAll();
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token');
  }
}
