import { createReducer, on } from '@ngrx/store';
import {
  DisplayedDataFilter,
  deleteDisplayedDataSuccess,
  displayDataChange,
  setDisplayData,
} from './displayed-data.action';
import { displayedDataAdapter, initialState } from './displayed-data.adapter';

const _displayedDataReducer = createReducer(
  initialState,
  on(setDisplayData, (state, { displayData, pagination }) => ({
    ...state,
    ...(pagination && { pagination }),
    displayData: displayedDataAdapter.setAll(displayData, state.displayData),
  })),
  on(deleteDisplayedDataSuccess, (state, { id }) => {
    const remove: any = Array.isArray(id)
      ? displayedDataAdapter.removeMany
      : displayedDataAdapter.removeOne;

    return {
      ...state,
      displayData: remove(id, state.displayData),
    };
  }),
  on(
    DisplayedDataFilter.filterByDateSuccess,
    (state, { filteredDisplayedData, range }) => ({
      ...state,
      filters: {
        filteredDisplayedData,
        range,
      },
    }),
  ),
  on(DisplayedDataFilter.clearFilter, state => ({
    ...state,
    filters: {
      filteredDisplayedData: [],
      range: { start: '', end: '' },
    },
  })),
  on(displayDataChange, (state, { change }) => ({
    ...state,
    pagination: {
      page: change.page,
      pageSize: change.pageSize,
    },
  })),
);

export function DisplayedDataReducer(state, action) {
  return _displayedDataReducer(state, action);
}
