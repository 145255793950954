import { NgFor, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { DmsTypeaheadComponent } from '../dms-typeahead/typeahead.component';
import { DmsTagsComponent } from '@shared/components/standalone/tags/tags.component';

export type ITEM_TYPE = 'rounded';

@Component({
  selector: 'dms-typeahead-tags',
  templateUrl: './typeahead-tags.component.html',
  standalone: true,
  imports: [DmsTypeaheadComponent, DmsTagsComponent, NgIf, NgFor],
  styleUrls: ['./typeahead-tags.component.scss'],
})
export class DmsTypeaheadTagsComponent<T> {
  @Input() onlyView: boolean;
  @Input() chosenItems: any[];
  @Input() items: T[];
  @Input() tpl: TemplateRef<T>;
  @Input() local = true;
  @Input() label: string;
  @Input() hiddenCloseBtn: boolean;
  @Input() isSelectable: boolean;
  @Input() formcontrol: any;
  @Input() item_type: ITEM_TYPE = 'rounded';

  @Output() chose = new EventEmitter();
  @Output() searchValue = new EventEmitter();


  takeSelectedItem(chosen: T) {
    this.chosenItems.push(chosen);
    this.items = [...this.items.filter(v => v !== chosen)];
    this.chose.emit(this.chosenItems);
  }

  remove(chosen: T) {
    this.chosenItems = [...this.chosenItems.filter(v => v !== chosen)];
    this.items = [chosen, ...this.items];
    this.chose.emit(this.chosenItems);
  }

  searchValueEmitter(val: string) {
    this.searchValue.emit(val);
  }
}
