import { createAction, createActionGroup, emptyProps, props } from '@ngrx/store';
import { ActionEvent } from '@shared/components/workspace/data-table/models/actions/action-event.model';
import { SelectActionEvent } from '@shared/components/workspace/data-table/models/actions/select-action-event.model';
import { IDateFilter } from '@state/manage/manage.model';
import { DisplayedData, Pagination } from 'src/app/models/app.model';
import { FilteredDisplayedData } from './models/filtered-displayed-data.model';

export const SET_DISPLAY_DATA = '[displayedData] set display data';

export const setDisplayData = createAction(
  SET_DISPLAY_DATA,
  props<{ displayData: DisplayedData[]; pagination?: Pagination }>(),
);

export const CREATE_DISPLAY_DATA = '[displayedData] create display data';
export const createDisplayedData = createAction(
  CREATE_DISPLAY_DATA,
  props<{ displayData: DisplayedData[] }>(),
);

export const UPDATE_DISPLAY_DATA = '[displayedData] update display data';
export const upodateDisplayedData = createAction(
  UPDATE_DISPLAY_DATA,
  props<{ displayData: DisplayedData[] }>(),
);

export const DELETE_DISPLAY_DATA = '[displayedData] delete display data';
export const deleteDisplayedData = createAction(
  DELETE_DISPLAY_DATA,
  props<{ service: any; event: ActionEvent | SelectActionEvent }>(),
);

export const DELETE_DISPLAY_DATA_SUCCESS = '[saveSearch] delete displayed data success';
export const deleteDisplayedDataSuccess = createAction(
  DELETE_DISPLAY_DATA_SUCCESS,
  props<{ id: string | string[] }>(),
);

export const DisplayedDataFilter = createActionGroup({
  source: '[displayedData filter]',
  events: {
    'Clear Filter': emptyProps(),
    'Filter By Date': props<{ range: IDateFilter }>(),
    'Filter By Date Success': props<{
      filteredDisplayedData: FilteredDisplayedData[];
      range: IDateFilter;
    }>(),
  },
});

export const DISPY_DATA_CHANGE = '[displayData] change';
export const displayDataChange = createAction(
  DISPY_DATA_CHANGE,
  props<{ change: Pagination }>(),
);
