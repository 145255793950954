import { Routes } from '@angular/router';
import { NotFoundComponent } from './layout/not-found/not-found.component';
import { LoginComponent } from './auth/componentss/login/login.component';
import { MainComponent } from './main/main.component';
import { RegisterComponent } from './auth/componentss/register/register.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    // canActivate: [AuthLoginGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    // canActivate: [AuthLoginGuard],
  },
  {
    path: '',
    redirectTo: 'home/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'manage',
        loadChildren: () =>
          import('./routes/manage/manage.module').then(m => m.ManageModule),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./routes/search/search.module').then(m => m.SearchModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./routes/admin/admin.module').then(m => m.AdminModule),
      },
    ],
  },
  { path: '**', component: NotFoundComponent, pathMatch: 'full' },
];
