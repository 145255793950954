import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subscription, filter, interval, map, switchMap, tap } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { DeviceService } from '../../shared/services/device.service';
import { DEVICE } from '@shared/models/device.model';
import { ISearchData } from '../../routes/home/models/search-data.model';
import { ThemeService } from 'src/app/core/theme/theme.service';
import { QuickSearchComponent } from '@shared/components/standalone/quick-search/quick-search.component';
import { CommonModule } from '@angular/common';
import { NotificationsModule } from 'src/app/common/notifications/notifications.module';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { ModalService } from '@shared/components/modal/services/modal.service';
import { NotificationComponent } from '@common/notifications/notification/notification.component';
import { MessagesComponent } from '@common/notifications/messages/messages.component';
import { ComponentType } from '@angular/cdk/portal';
import { SettingsComponent } from './settings/settings.component';
import { NotificationService } from '@common/notifications/notification/services/notifications.service';
import { MeService } from '@routes/home/components/my-account/services/me.service';
import { INotification } from '@common/notifications/notification/model/notification.model';

type NotificationType = ComponentType<NotificationComponent | MessagesComponent>;
const notificationComponents = {
  message: 'MessagesComponent',
  notification: 'NotificationComponent',
};

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  imports: [QuickSearchComponent, CommonModule, NotificationsModule],
  standalone: true,
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('notificationContainerRef', { read: ViewContainerRef, static: true })
  viewContainer: ViewContainerRef;
  themes = [
    {
      value: 'light',
    },
    {
      value: 'dark',
    },
  ];

  data: ISearchData[] = [];
  isFullHeader = {
    isFull: true,
    isShowSearch: true,
  };

  showSettings: boolean;
  device$: Observable<DEVICE>;
  sub: Subscription = new Subscription();
  notification$: Observable<INotification | null>;

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private themeService: ThemeService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private meService: MeService,
  ) {}

  ngOnInit() {
    this.device$ = this.deviceService.deviceName;
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        map((event: NavigationStart | any) => {
          let isFullHeader = {
            isFull: true,
            isShowSearch: true,
          };

          if (event.url !== '/') {
            isFullHeader = {
              isFull: event.url.split('/')[1] === 'home',
              isShowSearch: event.url.split('/')[2] === 'dashboard',
            };
          }

          this.isFullHeader = isFullHeader;
        }),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.notification$ = this.meService.getCurrentUserProfile().pipe(
      switchMap(userProfile =>
        interval(3000).pipe(
          switchMap(() => this.notificationService.notification$), // Fetch notifications
          tap(_ => {
            // console.log('notification', notification?.userId);
            // console.log('userProfile', userProfile?.id);
          }),
          filter(
            notification =>
              notification &&
              notification.userId &&
              notification.userId === userProfile.id,
          ),
          tap(_ => {
            console.log('Notify!!!');
          }),
        ),
      ),
    );
  }

  onOpenSidebar() {
    this.modalService.createModalComponent(SidebarComponent, null, 'left-side');
  }

  openNotificationModal(name: string) {
    const componentName = notificationComponents[name];
    this.viewContainer.clear();
    let _component: NotificationType;
    if (componentName === 'MessagesComponent') {
      _component = MessagesComponent;
    } else {
      _component = NotificationComponent;
    }
    this.modalService.createModalComponent(_component);
  }

  openSettings() {
    this.modalService.createModalComponent(SettingsComponent, null, 'right-side');

    this.showSettings = !this.showSettings;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
