import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MaterialModule } from '@shared/material.module';
import { FILE_TYPES } from '../view-document/file/models/file-type';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'dms-document-actions',
  templateUrl: './document-actions.component.html',
  styleUrls: ['./document-actions.component.scss'],
  standalone: true,
  imports: [MaterialModule, CommonModule, MatTooltipModule],
})
export class DocumentActionsComponent {
  private _type = 'Picture'; // Default type
  @Input() actions: { key: string; title: string; disabled?: boolean }[] = [];
  @Input()
  set type(value: string) {
    this._type = value;
    this.acceptTypes = Object.keys(FILE_TYPES[this._type]).join();
  }

  get type(): string {
    return this._type;
  }

  acceptTypes = '';

  @Output() view = new EventEmitter<void>();
  @Output() download = new EventEmitter<void>();
  @Output() update = new EventEmitter<Event>();
  @Output() save = new EventEmitter<Event>();
}
