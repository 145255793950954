import { Component } from '@angular/core';

@Component({
  selector: 'dms-home-navigation',
  templateUrl: './home-navigation.component.html',
  styleUrls: ['./home-navigation.component.scss'],
})
export class HomeNavigationComponent {
  constructor() {}

  tablist = [
    { title: 'Dashboard', path: '/home/dashboard' },
    { title: 'Authorized Applications', path: '/home/authorized-applications' },
    { title: 'Users and Groups', path: '/home/users-and-groups' },
  ];
}
