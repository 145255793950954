import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FILE_TYPES } from '@routes/home/components/document-details/components/view-document/file/models/file-type';
import { AlertService } from '@shared/components/alert/services/alert.service';
import { DragDropDirective } from '@shared/directives/drag-drop.directive';
import { Alert } from 'src/app/models/alert.model';
import { ManageCreateStore } from '../../store/create-store';
import { FormGroup } from '@angular/forms';
import { FORM_TYPES } from '../../store/create-state';
import { CollectionTypeComponent } from './collection-types/collection-types.component';

@Component({
  selector: 'dms-import-files',
  templateUrl: './import-files.component.html',
  styleUrls: ['./import-files.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DragDropDirective,
    MatFormFieldModule,
    CollectionTypeComponent,
    MatSelectModule,
  ],
})
export class ImportFilesComponent implements OnInit {
  @Input() fileType: string;
  @Input() form: FormGroup;
  @Input() isValidForm: boolean;
  @Input() label = 'Content';
  @Input() isMultiple = true;
  @Output() emitImportedFile = new EventEmitter<File[]>();

  private alert = inject(AlertService);
  private store = inject(ManageCreateStore);

  formats = ['HTML', 'XML', 'Text', 'Markdown'];
  isFile = true;
  acceptTypes = '*';
  files: File[] = [];
  fileTypes: { [type: string]: string };

  ngOnInit(): void {
    if (this.fileType === 'Note') {
      this.isFile = false;
    }
    this.fileTypes = FILE_TYPES[this.fileType];
    if (this.fileTypes) {
      this.acceptTypes = Object.keys(this.fileTypes).join();
    }
  }

  public onFileDropped($event) {
    this.prepareFilesList($event);
  }

  public fileBrowseHandler(event) {
    if (event.target?.files) {
      this.prepareFilesList(event.target.files);
    } else {
      this.emitImportedFile.emit(event.value);
    }
  }

  public deleteFile(index: number) {
    this.emitImportedFile.emit();
    this.files.splice(index, 1);
  }

  private prepareFilesList(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (
        this.fileTypes &&
        !Object.values(this.fileTypes).includes(file.type) &&
        this.fileType !== 'File'
      ) {
        this.alert.setAlert(
          new Alert(
            'Unacceptable File Type',
            `The selected file type is not supported. Please upload a file in ${Object.keys(
              this.fileTypes,
            ).join()} format`,
            'error',
          ),
        );

        return;
      }

      if (!this.isMultiple) {
        this.files.length = 0;
      }
      this.files.push(file);
    }

    this.emitImportedFile.emit(this.files);
  }

  openCreateDocument() {
    if (this.form.value) {
      this.store.setCollectionTitle('New Header');
      this.store.setFormType(FORM_TYPES.DOCUMENT_FOR_COLLECTION_TYPE);
    } else {
      console.log(`Form is Invalid`);
    }
  }
}
