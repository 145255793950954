// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.typehead-container i {
  position: absolute;
  color: var(--indigo);
  padding: 13px 0 0 16px;
}

.chosen-items-container {
  display: flex;
  height: 140px;
  gap: 5px;
  align-items: flex-end;
  flex-wrap: wrap;
  flex: 2;
  margin-top: 15px;
  padding-bottom: 10px;
  max-height: 300px;
  overflow: auto;
}

.chosen-item {
  font-size: 12px;
  border: 1px solid var(--primary);
  border-radius: 4px;
  background-color: var(--layout);
  padding: 2px 5px;
  color: var(--primary);
  justify-content: space-between;
  font-weight: bold;
  font-size: 15px;
  max-height: 25px;
}
.chosen-item.rounded {
  max-height: 40px;
  border-radius: 48px;
  border: none;
  background-color: var(--primary-tag);
  color: var(--white);
  font-size: 15px;
  font-weight: 500;
  padding: 6px 15px;
}
.chosen-item.rounded .action {
  display: flex;
}
.chosen-item ::ng-deep .action i {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/standalone/typeahead/dms-typeahead-tags/typeahead-tags.component.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;EACA,oBAAA;EACA,sBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,aAAA;EACA,QAAA;EACA,qBAAA;EACA,eAAA;EACA,OAAA;EACA,gBAAA;EACA,oBAAA;EACA,iBAAA;EACA,cAAA;AADF;;AAIA;EACE,eAAA;EACA,gCAAA;EACA,kBAAA;EACA,+BAAA;EACA,gBAAA;EACA,qBAAA;EACA,8BAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AADF;AAGE;EACE,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,oCAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAGI;EACE,aAAA;AADN;AAMI;EACE,eAAA;AAJN","sourcesContent":[".typehead-container {\n  i {\n    position: absolute;\n    color: var(--indigo);\n    padding: 13px 0 0 16px;\n  }\n}\n\n.chosen-items-container {\n  display: flex;\n  height: 140px;\n  gap: 5px;\n  align-items: flex-end;\n  flex-wrap: wrap;\n  flex: 2;\n  margin-top: 15px;\n  padding-bottom: 10px;\n  max-height: 300px;\n  overflow: auto;\n}\n\n.chosen-item {\n  font-size: 12px;\n  border: 1px solid var(--primary);\n  border-radius: 4px;\n  background-color: var(--layout);\n  padding: 2px 5px;\n  color: var(--primary);\n  justify-content: space-between;\n  font-weight: bold;\n  font-size: 15px;\n  max-height: 25px;\n\n  &.rounded {\n    max-height: 40px;\n    border-radius: 48px;\n    border: none;\n    background-color: var(--primary-tag);\n    color: var(--white);\n    font-size: 15px;\n    font-weight: 500;\n    padding: 6px 15px;\n\n    .action {\n      display: flex;\n    }\n  }\n\n  ::ng-deep {\n    .action i {\n      font-size: 14px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
