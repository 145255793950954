import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { DisplayedDataReducer } from './displayed-data/displayed-data.reducer';
import { DISPLAYED_DATA_STATE_NAME } from './displayed-data/displayed-data.selector';
import { ModalReducer } from './modal/modal.reducer';
import { MODAL_STATE_NAME } from './modal/modal.selector';
import { ModalState } from './modal/modal.state';
import { SelectActionsReducer } from './select-items/select-items.reducer';
import { SELECT_ACTION_STATE_NAME } from './select-items/select-items.selector';
import { SelectItemState } from './select-items/select-items.state';
import { ViewModeReducer } from './view-mode/view-mode.reducer';
import { VIEW_MODE_STATE_NAME } from './view-mode/view-mode.selector';
import { ViewModeState } from './view-mode/view-mode.state';
import { DisplayedDataState } from './displayed-data/displayed-data.adapter';
import { SidebarMenuState } from './sidebar-menu/sidebar-menu.state';
import { SidebarMenuReducer } from './sidebar-menu/sidebar-menu.reducer';
import { SIDEBAR_MENU_STATE_NAME } from './sidebar-menu/sidebar-menu.selector';
import { MANAGE_STATE_NAME } from './manage/manage.selector';
import { ManageState } from './manage/manage.state';
import { ManageReducer } from './manage/manage.reducer';
import { HomeReducer } from '../routes/home/state/home.reducer';
import { HOME_STATE_NAME } from '@routes/home/state/home.selector';
import { HomeState } from '@routes/home/state/home.state';

export interface AppState {
  [MODAL_STATE_NAME]: ModalState;
  [MANAGE_STATE_NAME]: ManageState;
  [VIEW_MODE_STATE_NAME]: ViewModeState;
  [DISPLAYED_DATA_STATE_NAME]: DisplayedDataState;
  [SELECT_ACTION_STATE_NAME]: SelectItemState;
  [SIDEBAR_MENU_STATE_NAME]: SidebarMenuState;
  [HOME_STATE_NAME]: HomeState;
  router: RouterReducerState | any; // needs to check
}

export const appReducer = {
  [MODAL_STATE_NAME]: ModalReducer,
  [MANAGE_STATE_NAME]: ManageReducer,
  [VIEW_MODE_STATE_NAME]: ViewModeReducer,
  [DISPLAYED_DATA_STATE_NAME]: DisplayedDataReducer,
  [SELECT_ACTION_STATE_NAME]: SelectActionsReducer,
  [SIDEBAR_MENU_STATE_NAME]: SidebarMenuReducer,
  [HOME_STATE_NAME]: HomeReducer,
  router: routerReducer,
};
