// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.alert-container {
  background-color: var(--layout);
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px 1px var(--primary-tag);
  padding: 10px 30px 10px 10px;
  color: var(--basic-color-50);
  animation-duration: 0.8s;
  position: relative;
  max-width: 330px;
  width: 250px;
}
.alert-container.in {
  animation-name: alert-in;
}
.alert-container.out {
  animation-name: alert-out;
}
.alert-container .dismiss {
  position: absolute;
  top: 40%;
  right: 10px;
  color: var(--danger);
}

.alert-header {
  display: flex;
  gap: 5px;
}

.message {
  color: var(--basic-color-50);
}

@keyframes alert-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes alert-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/alert/alert.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;AACF;;AAEA;EACE,+BAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;EACA,8CAAA;EACA,4BAAA;EACA,4BAAA;EACA,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,YAAA;AACF;AAAE;EACE,wBAAA;AAEJ;AAAE;EACE,yBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,oBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,QAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE;IACE,2BAAA;EACF;EACA;IACE,wBAAA;EACF;AACF;AAEA;EACE;IACE,wBAAA;EAAF;EAEA;IACE,2BAAA;EAAF;AACF","sourcesContent":[":host {\n  position: fixed;\n  right: 20px;\n  bottom: 40px;\n  z-index: 999;\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n\n.alert-container {\n  background-color: var(--layout);\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  border-radius: 6px;\n  box-shadow: 0px 0px 5px 1px var(--primary-tag);\n  padding: 10px 30px 10px 10px;\n  color: var(--basic-color-50);\n  animation-duration: 0.8s;\n  position: relative;\n  max-width: 330px;\n  width: 250px;\n  &.in {\n    animation-name: alert-in;\n  }\n  &.out {\n    animation-name: alert-out;\n  }\n  .dismiss {\n    position: absolute;\n    top: 40%;\n    right: 10px;\n    color: var(--danger);\n  }\n}\n\n.alert-header {\n  display: flex;\n  gap: 5px;\n}\n\n.message {\n  color: var(--basic-color-50);\n}\n\n@keyframes alert-in {\n  from {\n    transform: translateX(100%);\n  }\n  to {\n    transform: translateX(0);\n  }\n}\n\n@keyframes alert-out {\n  from {\n    transform: translateX(0);\n  }\n  to {\n    transform: translateX(100%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
