import { createReducer, on } from '@ngrx/store';
import { ManageState, initialState } from './manage.state';
import { Manage } from './manage.action';

const _manageReducer = createReducer(
  initialState,
  on(
    Manage.loadDataByPathSuccess,
    (state: ManageState, { menuTree, documentsList, currentDocument }) => ({
      ...state,
      menuTree,
      documentsList,
      currentDocument,
      filters: {
        date: {
          start: '',
          end: '',
        },
      },
    }),
  ),
  on(
    Manage.setActiveDocumentsListSucccess,
    (state: ManageState, { documentsList, currentDocument }) => ({
      ...state,
      documentsList,
      currentDocument,
    }),
  ),
  on(Manage.toggleFolderSuccess, (state: ManageState, { menuTree }) => {
    console.table(menuTree);
    return {
      ...state,
      menuTree,
    };
  }),
  on(
    Manage.filterActiveDocumentListSuccess,
    (state: ManageState, { filteredDocumentsList, range }) => ({
      ...state,
      documentsList: filteredDocumentsList,
      filters: {
        date: range,
      },
    }),
  ),
  on(Manage.toggleMneu, (state: ManageState, { open }) => ({
    ...state,
    isMenuOpen: open,
  })),
  on(Manage.clear, (state: ManageState) => ({
    ...state,
    currentDocument: null,
  })),
);

export function ManageReducer(state, action) {
  return _manageReducer(state, action);
}
