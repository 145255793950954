// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-document-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 35%;
}
.add-document-container p {
  color: var(--basic-color-reversed);
}
.add-document-container .create-icon.disabled:hover .create-tooltip {
  display: block;
}
.add-document-container .create-icon {
  position: relative;
  cursor: pointer;
  padding: 2px 6px;
  font-size: 14px;
  border: 4px solid var(--primary-color);
  border-radius: 100%;
  color: var(--primary-color);
}
.add-document-container .create-icon.disabled i {
  cursor: not-allowed !important;
  opacity: 0.5;
}
.add-document-container .create-icon.disabled .create-tooltip {
  display: none;
  position: absolute;
  top: -14px;
  right: -230px;
  width: 220px;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.431372549);
  padding: 5px 8px;
  border-radius: 7px;
  color: var(--danger);
}

.collections-container {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-items: start;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/manage/components/workspace/components/content/components/create-files/components/import-files/collection-types/collection-types.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,UAAA;AACF;AACE;EACE,kCAAA;AACJ;AACE;EACE,cAAA;AACJ;AACE;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,sCAAA;EACA,mBAAA;EACA,2BAAA;AACJ;AAEM;EACE,8BAAA;EACA,YAAA;AAAR;AAEM;EACE,aAAA;EAEA,kBAAA;EACA,UAAA;EACA,aAAA;EACA,YAAA;EACA,eAAA;EACA,4CAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;AADR;;AAMA;EACE,aAAA;EACA,QAAA;EACA,eAAA;EACA,oBAAA;AAHF","sourcesContent":[".add-document-container {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  width: 35%;\n\n  p {\n    color: var(--basic-color-reversed);\n  }\n  .create-icon.disabled:hover .create-tooltip {\n    display: block;\n  }\n  .create-icon {\n    position: relative;\n    cursor: pointer;\n    padding: 2px 6px;\n    font-size: 14px;\n    border: 4px solid var(--primary-color);\n    border-radius: 100%;\n    color: var(--primary-color);\n\n    &.disabled {\n      i {\n        cursor: not-allowed !important;\n        opacity: 0.5;\n      }\n      .create-tooltip {\n        display: none;\n\n        position: absolute;\n        top: -14px;\n        right: -230px;\n        width: 220px;\n        font-size: 13px;\n        background-color: #0000006e;\n        padding: 5px 8px;\n        border-radius: 7px;\n        color: var(--danger);\n      }\n    }\n  }\n}\n.collections-container {\n  display: flex;\n  gap: 5px;\n  flex-wrap: wrap;\n  justify-items: start;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
