// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  width: 100%;
  position: relative;
}

input {
  height: 30px;
  border: 0.5px solid #219ebc;
  border-radius: 7px;
  padding: 2px;
  box-sizing: content-box;
  width: 98%;
}

.send {
  position: absolute;
  right: 10px;
  top: 8px;
}
.send .btn {
  padding: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/notifications/messages/form/form.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,2BAAA;EACA,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,UAAA;AACF;;AAEA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;AACF;AAAE;EACE,eAAA;AAEJ","sourcesContent":[".form-container {\n  width: 100%;\n  position: relative;\n}\n\ninput {\n  height: 30px;\n  border: 0.5px solid #219ebc;\n  border-radius: 7px;\n  padding: 2px;\n  box-sizing: content-box;\n  width: 98%;\n}\n\n.send {\n  position: absolute;\n  right: 10px;\n  top: 8px;\n  .btn {\n    padding: 0 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
