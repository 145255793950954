// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-info {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.user-info .my-account {
  background-color: var(--primary);
  color: var(--layout);
  border-radius: 3px;
  font-weight: 700;
  font-size: 11px;
  padding: 4px 0;
}
.user-info ul {
  display: flex;
  flex-direction: column;
}
.user-info ul.settings-container {
  gap: 35px;
}
.user-info h1 {
  color: var(--titlePrimary2);
  font-size: 5em;
}
.user-info ul {
  margin-top: 15px !important;
  gap: 10px;
  text-align: left;
}
.user-info ul .property {
  display: flex;
  justify-content: space-between;
  color: var(--basic-color-reversed);
}
.user-info ul .property .label {
  flex: 1;
}
.user-info ul .property .value {
  flex: 2;
  color: var(--darkGreySecond);
}
.user-info .actions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.user-info .actions-container img {
  width: 100%;
}
.user-info .actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  position: relative;
}
.user-info .actions input {
  opacity: 0;
  width: 15px;
  position: absolute;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/home/components/my-profile/my-profile.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,gCAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AACJ;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;AACI;EACE,SAAA;AACN;AAGE;EACE,2BAAA;EACA,cAAA;AADJ;AAGE;EACE,2BAAA;EACA,SAAA;EACA,gBAAA;AADJ;AAEI;EACE,aAAA;EACA,8BAAA;EACA,kCAAA;AAAN;AAEM;EACE,OAAA;AAAR;AAEM;EACE,OAAA;EACA,4BAAA;AAAR;AAKE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AAHJ;AAME;EACE,WAAA;AAJJ;AAOE;EACE,aAAA;EACA,SAAA;EACA,gBAAA;EACA,kBAAA;AALJ;AAMI;EACE,UAAA;EACA,WAAA;EACA,kBAAA;AAJN","sourcesContent":[".user-info {\n  display: flex;\n  flex-direction: column;\n  gap: 25px;\n\n  .my-account {\n    background-color: var(--primary);\n    color: var(--layout);\n    border-radius: 3px;\n    font-weight: 700;\n    font-size: 11px;\n    padding: 4px 0;\n  }\n\n  ul {\n    display: flex;\n    flex-direction: column;\n    &.settings-container {\n      gap: 35px;\n    }\n  }\n\n  h1 {\n    color: var(--titlePrimary2);\n    font-size: 5em;\n  }\n  ul {\n    margin-top: 15px !important;\n    gap: 10px;\n    text-align: left;\n    .property {\n      display: flex;\n      justify-content: space-between;\n      color: var(--basic-color-reversed);\n\n      .label {\n        flex: 1;\n      }\n      .value {\n        flex: 2;\n        color: var(--darkGreySecond);\n      }\n    }\n  }\n\n  .actions-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  .actions-container img {\n    width: 100%;\n  }\n\n  .actions {\n    display: flex;\n    gap: 10px;\n    margin-top: 10px;\n    position: relative;\n    input {\n      opacity: 0;\n      width: 15px;\n      position: absolute;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
