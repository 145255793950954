export enum NOTIFICATION_TYPE {
  COMMENT = 'comment',
  MESSAGE = 'message',
  DELETE = 'delete',
  EDIT = 'edit'
}

export enum NOTIFICATION_STATUS {
  UNSEEN = 'UNSEEN',
  DISMISSED = 'DISMISSED',
  READ = 'read',
}

export const getTypeIcon = (type: string) => {
  switch (type) {
    case NOTIFICATION_TYPE.COMMENT:
      return '<i class="fas fa-comment"></i>';
    case NOTIFICATION_TYPE.MESSAGE:
      return '<i class="fas fa-envelope"></i>';
    default:
      return '<i class="fa fa-info-circle"></i>';
  }
};

export interface INotification {
  id: string;
  userEmail: string;
  documentId: string;
  userId: string;
  type: NOTIFICATION_TYPE;
  title: string;
  description: string;
  entityId: string;
  entityType: string;
  createdOn: number;
  seenOn: number;
  status: string;
  icon?: string;
}
