export class ModalState {
  status: boolean;
  id?: number;
  isClearSelected?: boolean;
  orderPosition?: number;
}

export const initialState: ModalState = {
  status: false,
  orderPosition: 0,
  id: 0,
};