import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabletNavigationComponent } from './tablet-navigation.component';
import { HomeNavigationComponent } from './home-navigation/home-navigation.component';
import { SearchNavigationComponent } from './search-navigation/search-navigation.component';
import { AdminNavigationComponent } from './admin-navigation/admin-navigation.component';
import { ManageNavigationComponent } from './manage-navigation/manage-navigation.component';
import { MenuComponent } from './shared/components/menu/menu.component';

@NgModule({
  declarations: [
    TabletNavigationComponent,
    HomeNavigationComponent,
    SearchNavigationComponent,
    AdminNavigationComponent,
  ],
  imports: [CommonModule, ManageNavigationComponent, MenuComponent],
  exports: [TabletNavigationComponent],
})
export class TabletNavigationModule {}
