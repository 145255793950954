import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { SafePipe } from './pipes/safe.pipe';
import { ModalComponent } from './components/modal/modal.component';
import { PaginationComponent } from './components/workspace/paginator/pagination/pagination.component';
import { RouterModule } from '@angular/router';
import { CommonModalComponent } from './components/modal/common-modal/common-modal.component';
import { DataCyDirective } from './directives/data-cy.directive';
import { CardComponent } from '@shared/components/workspace/card/card.component';
import { CastActionModalType } from './pipes/cast-action-modal-type.pipe';
import { DmsCheckboxComponent } from './components/dms-checkbox/dms-checkbox.component';
import { ActionModalComponent } from './components/modal/action-modal/action-modal.component';
import { AlertComponent } from './components/alert/alert.component';
import { LetDirective } from './directives/let.directive';
import { EmptyValuePipe } from './pipes/empty-vaue.pipe';
import { BackdropComponent } from './components/backdrop/backdrop.component';
import { ModalHeaderComponent } from './components/modal/modal-header/modal-header.component';
import { ViewSwitcherComponent } from './components/workspace/view-switcher/view-switcher.component';
import { CommonModalFooterComponent } from './components/modal/common-modal/common-modal-footer/common-modal-footer.component';
import { DmsTagsComponent } from './components/standalone/tags/tags.component';
import { DmsSelectComponent } from './components/dms-select/dms-select.component';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  declarations: [
    EmptyValuePipe,
    CastActionModalType,
    ModalComponent,
    CommonModalComponent,
    CommonModalFooterComponent,
    ActionModalComponent,
    BackdropComponent,
    PaginationComponent,
    CardComponent,
    DataCyDirective,
    AlertComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ModalHeaderComponent,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DmsTagsComponent,
    SafePipe,
    DmsSelectComponent,
    ViewSwitcherComponent,
    LoaderComponent,
    DmsCheckboxComponent,
    LetDirective,
  ],
  exports: [
    CommonModule,
    EmptyValuePipe,
    CastActionModalType,
    MaterialModule,
    ModalComponent,
    CommonModalComponent,
    CommonModalFooterComponent,
    ActionModalComponent,
    ModalHeaderComponent,
    BackdropComponent,
    PaginationComponent,
    CardComponent,
    DataCyDirective,
    AlertComponent,
    LetDirective,
    SafePipe,
    DmsSelectComponent,
  ],
})
export class SharedModule {}
