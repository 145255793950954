import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef,
  OnDestroy,
} from '@angular/core';
import { SelectedRows } from '@shared/components/workspace/data-table/models/data-table.model';
import { Store } from '@ngrx/store';
import { TableAction } from '@shared/components/workspace/data-table/models/actions/table-action.model';
import { selectedItems } from 'src/app/state/select-items/select-items.selector';
import { setSelectedRow } from 'src/app/state/select-items/select-items.action';
import { ActionEvent } from '@shared/components/workspace/data-table/models/actions/action-event.model';
import { ICON_TYPE } from '@shared/components/dms-select/models/icon-type.model';
import { TableHeaders } from '../data-table/models/table-headers.model';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'dms-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit, OnDestroy {
  @Input() row: any; // needs to set type here
  @Input() actions: TableAction[];
  @Input() selectActions: TableAction[];
  @Input() tableHeaders: TableHeaders[];
  @Input() cardTpl: TemplateRef<any>;

  sub: Subscription;
  rowId: string;
  isSelected: boolean;
  ICON_TYPE = ICON_TYPE;

  @Output() actionEvent = new EventEmitter();

  constructor(private store: Store) {}

  ngOnInit() {
    if (!this.selectActions) {
      this.selectActions = _.cloneDeep(this.actions);
    }

    this.sub = this.store.select(selectedItems).subscribe(selectedItems => {
      // unsubscribe
      this.isSelected = !!selectedItems.find(v => v.key === this.row.id)?.isSelected;
    });

    this.rowId = this.rowId || this.row?.uid || this.row?.id;
  }

  selectRow(event: Event | PointerEvent) {
    this.isSelected = !this.isSelected;
    const selectedRow: SelectedRows = { key: this.row.id, isSelected: this.isSelected };

    if ((event as PointerEvent).ctrlKey) {
      this.store.dispatch(setSelectedRow({ selectedItem: selectedRow }));
    } else {
      // this.store.dispatch(selectAllRows({ : [selectedRow] }));
    }
  }

  takeActionClick(action: TableAction) {
    this.actionEvent.emit(new ActionEvent(action, this.row));
  }

  emitSelected(selectedRow: boolean) {
    this.store.dispatch(
      setSelectedRow({
        selectedItem: { key: this.row.id, isSelected: selectedRow },
      }),
    );
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
