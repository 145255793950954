import { fromEvent, Observable, map, startWith, distinctUntilChanged } from 'rxjs';
import { DEVICE } from '../models/device.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  deviceName: Observable<DEVICE>;
  width: Observable<number>;

  constructor() {
    const streem = fromEvent(self, 'resize');
    this.deviceName = streem.pipe(
      distinctUntilChanged(),
      map(this.checkDeviceName),
      startWith(this.checkDeviceName()),
    );

    this.width = streem.pipe(
      map(() => self.innerWidth),
      startWith(self.innerWidth),
    );
  }

  private checkDeviceName = () => {
    const width = self.innerWidth;

    if (width <= 850 && width > 768) {
      return DEVICE.TABLET;
    }
    if (width <= 768 && width > 480) {
      return DEVICE.PORTRIAT_TABLET;
    }
    if (width <= 480) {
      return DEVICE.MOBILE;
    }

    return DEVICE.DESKTOP;
  };
}
