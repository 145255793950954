const DocumentTypeIcons = {
  workspace: {
    iconClassName: `fa-server`,
    colorClassName: 'file-color-blue',
  },
  section: {
    iconClassName: `fa-folder-open`,
    colorClassName: 'file-color-orange',
  },
  picture: {
    iconClassName: `fa-image`,
    colorClassName: 'file-color-green',
  },
  file: {
    iconClassName: `fa-file`,
    colorClassName: 'file-color-red',
  },
  excel: {
    iconClassName: `fa-file-excel`,
    colorClassName: 'file-color-purple',
  },
  PDF: {
    iconClassName: `fa-file-pdf`,
    colorClassName: 'file-color-orange',
  },
  PEN: {
    iconClassName: `fa-pen`,
    colorClassName: 'file-color-lime',
  },
  document: {
    iconClassName: `fa-file-word`,
    colorClassName: 'file-color-teal',
  },
  folder: {
    iconClassName: `fa-folder`,
    colorClassName: 'file-color-yellow',
  },
  orderedfolder: {
    iconClassName: `fa-folder`,
    colorClassName: 'file-color-deep-orange',
  },
  hiddenfolder: {
    iconClassName: `fa-folder`,
    colorClassName: 'file-color-purple',
  },
  root: {
    iconClassName: `fa-code-branch`,
    colorClassName: 'file-color-blue',
  },
  relation: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-red',
  },
  domain: {
    iconClassName: `fa-globe`,
    colorClassName: 'file-color-orange',
  },
  workspaceroot: {
    iconClassName: `fa-sitemap`,
    colorClassName: 'file-color-blue',
  },
  templateroot: {
    iconClassName: `fa-sitemap`,
    colorClassName: 'file-color-blue',
  },
  sectionroot: {
    iconClassName: `fa-sitemap`,
    colorClassName: 'file-color-blue',
  },
  note: {
    iconClassName: `fa-note-sticky`,
    colorClassName: 'file-color-orange',
  },
  collection: {
    iconClassName: `fa-files`,
    colorClassName: 'file-color-green',
  },
  collections: {
    iconClassName: `fa-files`,
    colorClassName: 'file-color-red',
  },
  managementroot: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-blue',
  },
  administrativestatuscontainer: {
    iconClassName: `fa-user-gear`,
    colorClassName: 'file-color-orange',
  },
  administrativestatus: {
    iconClassName: `fa-user-gear`,
    colorClassName: 'file-color-lime',
  },
  permissionssearch: {
    iconClassName: `fa-file-magnifying-glass`,
    colorClassName: 'file-color-deep-orange',
  },
  assetssearch: {
    iconClassName: `fa-file-magnifying-glass`,
    colorClassName: 'file-color-blue',
  },
  userinvitation: {
    iconClassName: `fa-user`,
    colorClassName: 'file-color-orange',
  },
  userinvitationcontainer: {
    iconClassName: `fa-user`,
    colorClassName: 'file-color-lime',
  },
  basicauditsearch: {
    iconClassName: `fa-file-magnifying-glass`,
    colorClassName: 'file-color-deep-orange',
  },
  favorites: {
    iconClassName: `fa-star`,
    colorClassName: 'file-color-yellow',
  },
  commentroot: {
    iconClassName: `fa-comment`,
    colorClassName: 'file-color-blue',
  },
  comment: {
    iconClassName: `fa-comment`,
    colorClassName: 'file-color-lime',
  },
  annotation: {
    iconClassName: `fa-pen-field`,
    colorClassName: 'file-color-deep-orange',
  },
  commentrelation: {
    iconClassName: `fa-comment`,
    colorClassName: 'file-color-blue',
  },
  picturebook: {
    iconClassName: `fa-images`,
    colorClassName: 'file-color-orange',
  },
  publicationrelation: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-green',
  },
  defaultrelation: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-deep-orange',
  },
  tag: {
    iconClassName: `fa-tag`,
    colorClassName: 'file-color-blue',
  },
  tagging: {
    iconClassName: `fa-tag`,
    colorClassName: 'file-color-orange',
  },
  audio: {
    iconClassName: `fa-file-audio`,
    colorClassName: 'file-color-green',
  },
  taskroot: {
    iconClassName: `fa-file-taskroot`,
    colorClassName: 'file-color-deep-orange',
  },
  taskdoc: {
    iconClassName: `fa-book`,
    colorClassName: 'file-color-blue',
  },
  userworkspacesroot: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-blue',
  },
  video: {
    iconClassName: `fa-video`,
    colorClassName: 'file-color-green',
  },
  advancedsearch: {
    iconClassName: `fa-file-advancedsearch`,
    colorClassName: 'file-color-deep-orange',
  },
  advancedcontent: {
    iconClassName: `fa-folder-grid`,
    colorClassName: 'file-color-blue',
  },
  routingtask: {
    iconClassName: `fa-list-check`,
    colorClassName: 'file-color-orange',
  },
  documentrouteinstancesroot: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-blue',
  },
  documentroutemodelsroot: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-blue',
  },
  stepfolder: {
    iconClassName: `fa-folder-tree`,
    colorClassName: 'file-color-blue',
  },
  documentroute: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-orange',
  },
  documentroutestep: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-green',
  },
  conditionalstepfolder: {
    iconClassName: `fa-folder-tree`,
    colorClassName: 'file-color-deep-orange',
  },
  routenode: {
    iconClassName: `fa-link`,
    colorClassName: 'file-color-blue',
  },
  savedsearch: {
    iconClassName: `fa-magnifying-glass`,
    colorClassName: 'file-color-yellow',
  },
  defaultsearch: {
    iconClassName: `fa-magnifying-glass`,
    colorClassName: 'file-color-green',
  },
  expiredsearch: {
    iconClassName: `fa-magnifying-glass`,
    colorClassName: 'file-color-red',
  },
  userprofile: {
    iconClassName: `fa-circle-user`,
    colorClassName: 'file-color-blue',
  },
  group: {
    iconClassName: `fa-file-word`,
    colorClassName: 'file-color-orange',
  },
};

const FileFormatIcons = {
  'application/msword': {
    iconClassName: `fa-file-word`,
    colorClassName: 'format-color-word',
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    iconClassName: `fa-file-word`,
    colorClassName: 'format-color-word',
  },
  'application/vnd.ms-excel': {
    iconClassName: `fa-file-excel`,
    colorClassName: 'format-color-excel',
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    iconClassName: `fa-file-excel`,
    colorClassName: 'format-color-excel',
  },
  'application/vnd.ms-powerpoint': {
    iconClassName: `fa-file-powerpoint`,
    colorClassName: 'format-color-powerpoint',
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    iconClassName: `fa-file-powerpoint`,
    colorClassName: 'format-color-powerpoint',
  },
  'application/pdf': {
    iconClassName: `fa-file-pdf`,
    colorClassName: 'format-color-pdf',
  },
  'image/jpeg': {
    iconClassName: `fa-file-image`,
    colorClassName: 'format-color-image',
  },
  'image/png': {
    iconClassName: `fa-file-image`,
    colorClassName: 'format-color-image',
  },
  'video/mp4': {
    iconClassName: `fa-file-video`,
    colorClassName: 'format-color-video',
  },
  'audio/mp3': {
    iconClassName: `fa-file-audio`,
    colorClassName: 'format-color-audio',
  },
  'application/zip': {
    iconClassName: `fa-file-archive`,
    colorClassName: 'format-color-archive',
  },
  'text/plain': {
    iconClassName: `fa-file-alt`,
    colorClassName: 'format-color-text',
  },
  'text/html': {
    iconClassName: `fa-file-code`,
    colorClassName: 'format-color-code',
  },
  'application/x-indesign': {
    iconClassName: `fa-file-indesign`,
    colorClassName: 'format-color-design',
  },
  'model/vnd.collada+xml': {
    iconClassName: `fa-file-cube`,
    colorClassName: 'format-color-generic',
  },
  // Add more mappings as needed...
};

const createIcon = (type: string, format?: string) => {
  let file;

  if (format && FileFormatIcons[format.toLowerCase()]) {
    type = format.toLowerCase();
    file = FileFormatIcons[type];
  } else {
    file = DocumentTypeIcons[type];
    type = type?.toLowerCase();
  }

  const icon = `<i class="fa fa-solid ${file?.iconClassName} ${file?.colorClassName}"></li`;
  return icon;
};

const createFormatIcon = (format: string) => {
  format = format.toLowerCase();
  const file = FileFormatIcons[format];
  const icon = `<i class="fa fa-solid ${file.iconClassName} color-${format}"></li`;
  return icon;
};

const createMenuIcon = (type: string) => createIcon(type);
export const getMenuIcon = type => createMenuIcon(type);

export const getDocumentIcon = document => {
  let type;
  let properties;

  if (typeof document !== 'object') {
    if (typeof document === 'string') {
      type = document.toLowerCase();
    } else {
      console.error('Wrong type of document');
      return createIcon('root');
    }
  } else {
    type = document.type?.toLowerCase();
    properties = document.properties;
  }

  let format;

  if (
    properties &&
    properties['file:content'] &&
    'mime-type' in properties['file:content'] &&
    properties['file:content']['mime-type']
  ) {
    format = properties['file:content']['mime-type'];
  }

  return createIcon(type, format);
};
