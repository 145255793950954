import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NuxeoAuthInterceptor } from './interceptor/nuxeo.interceptor';
import { AlertService } from '@shared/components/alert/services/alert.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard } from '../auth/componentss/services/auth-guard';
import { AuthService } from '../auth/componentss/services/auth.service';
import { AuthLoginGuard } from '../auth/componentss/services/auth-login-guard';
import { LoginService } from '../auth/componentss/services/login.service';
import { FusionAuthService } from '../auth/componentss/services/fusion-auth.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    CookieService,
    AuthGuard,
    AuthLoginGuard,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: NuxeoAuthInterceptor,
    //   multi: true,
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: RequestInterceptor,
    //   multi: true,
    //   deps: [Store],
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NuxeoAuthInterceptor,
      deps: [AlertService, AuthService, FusionAuthService, LoginService],
      multi: true,
    },
  ],
})
export class DmsCoreModule {}
