import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ICustomCss } from '../model/custom-css.model';

@Injectable({
  providedIn: 'root',
})
export class CustomCssService {
  private nuxeoApiUrl = `${environment.nuxeoApiUrl}`;

  constructor(private http: HttpClient) {}

  getAllCss(): Observable<ICustomCss[]> {
    return this.http.get<ICustomCss[]>(`${this.nuxeoApiUrl}/tenant-css`);
  }

  createCss(css: any): Observable<ICustomCss> {
    return this.http.post<ICustomCss>(`${this.nuxeoApiUrl}/tenant-css`, css);
  }

  updateCss({id, css}) {
    return this.http.put(`${this.nuxeoApiUrl}/tenant-css/`, { id, css });
  }

  deleteCss(id: string) {
    return this.http.delete(`${this.nuxeoApiUrl}/tenant-css/${id}`);
  }
}
