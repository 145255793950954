// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
}
:host img {
  width: 100%;
  height: 97%;
  object-fit: contain;
}
:host div {
  gap: 5px;
}
:host dialog {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  text-align: center;
  border: none;
  background-color: rgba(0, 0, 0, 0.69);
}
:host dialog i {
  position: absolute;
  right: 10px;
  color: var(--danger);
  font-size: 36px;
}
:host dialog img {
  height: 100%;
  max-width: 100%;
}
:host input[type=file] {
  position: absolute;
  opacity: 0;
  width: 15px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/home/components/document-details/components/view-document/file/components/picture/picture.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AAAE;EACE,WAAA;EACA,WAAA;EACA,mBAAA;AAEJ;AAAE;EACE,QAAA;AAEJ;AAAE;EACE,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;EACA,YAAA;EACA,qCAAA;AAEJ;AADI;EACE,kBAAA;EACA,WAAA;EACA,oBAAA;EACA,eAAA;AAGN;AADI;EACE,YAAA;EACA,eAAA;AAGN;AACE;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,eAAA;AACJ","sourcesContent":[":host {\n  height: 100%;\n  img {\n    width: 100%;\n    height: 97%;\n    object-fit: contain;\n  }\n  div {\n    gap: 5px;\n  }\n  dialog {\n    position: absolute;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 9;\n    text-align: center;\n    border: none;\n    background-color: rgb(0 0 0 / 69%);\n    i {\n      position: absolute;\n      right: 10px;\n      color: var(--danger);\n      font-size: 36px;\n    }\n    img {\n      height: 100%;\n      max-width: 100%;\n    }\n  }\n\n  input[type='file'] {\n    position: absolute;\n    opacity: 0;\n    width: 15px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
