import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ManageCreateStore } from '../../../store/create-store';
import { FORM_TYPES } from '../../../store/create-state';
import { DmsTagsComponent } from '@shared/components/standalone/tags/tags.component';

@Component({
  selector: 'dms-collection-types',
  templateUrl: './collection-types.component.html',
  styleUrls: ['./collection-types.component.scss'],
  standalone: true,
  imports: [CommonModule, DmsTagsComponent],
})
export class CollectionTypeComponent implements OnInit {
  @Input() fileType: string;
  @Input() form: FormGroup;
  @Input() isValidForm: boolean;
  @Output() emitImportedFile = new EventEmitter<File[]>();

  private store = inject(ManageCreateStore);
  documentsList: any = [];

  ngOnInit() {
    this.store.collectionDocuments$.subscribe(collectionDocuments => {
      this.documentsList = collectionDocuments;
    });
  }

  openCreateDocument() {
    if (this.form.value) {
      this.store.setCollectionTitle('New Header');
      this.store.setFormType(FORM_TYPES.DOCUMENT_FOR_COLLECTION_TYPE);
    } else {
      console.log(`Form is Invalid`);
    }
  }

  removeCollection(doc) {
    this.store.removeCollectionDocument(doc);
  }
}
