import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RouterModule } from '@angular/router';
import { routes } from './app.routes';

import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { SharedModule } from '@shared/shared.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { NotFoundComponent } from './layout/not-found/not-found.component';

import { environment } from 'src/environments/environment';
import { appReducer } from './state/app.state';
import { SelectActionEffects } from './state/select-items/select-items.effects';
import { NotificationsModule } from './common/notifications/notifications.module';
import { DmsCoreModule } from './core/core.module';
import { CustomSerializer } from '@state/router-state/custom-serializer';
import { MainComponent } from './main/main.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DisplayedDataEffects } from '@state/displayed-data/displayed-data.effects';
import { EffectsModule } from '@ngrx/effects';
import { TabletNavigationModule } from './layout/tablet-navigation/tablet-navigation.module';
import { ManageEffects } from '@state/manage/manage.effects';
import { ThemeService } from './core/theme/theme.service';
import { ModalEffects } from '@state/modal/modal.effects';
import { homeEffects } from '@routes/home/state/home.effect';
import { DocumentEditorModule } from '@syncfusion/ej2-angular-documenteditor';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent, MainComponent],
  imports: [
    BrowserModule,
    HeaderComponent,
    BrowserAnimationsModule,
    HttpClientModule,
    NotificationsModule,
    TabletNavigationModule,
    SharedModule,
    DocumentEditorModule,
    DmsCoreModule,
    SidebarComponent,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot(routes, { useHash: true }),
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot([
      SelectActionEffects,
      DisplayedDataEffects,
      ManageEffects,
      ModalEffects,
      homeEffects,
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomSerializer,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private themeService: ThemeService) {
    const theme = localStorage.getItem('theme');
    themeService.setTheme(theme ?? 'light');
  }
}
