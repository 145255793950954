// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  border-radius: 16px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
:host.danger {
  background-color: var(--modal-layout);
}
:host.danger .title {
  color: var(--danger);
}
:host.danger .description {
  font-weight: 500;
}
:host.info {
  background-color: var(--modal-layout);
}
:host.info .title {
  color: var(--titlePrimary);
}

.header-container,
.modal-body {
  border-radius: 16px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--modal-layout);
  border-bottom: 1px solid #999;
}
.header-container .title {
  color: var(--darkGrey);
}

.close {
  position: absolute;
  right: 20px;
}

.modal-body {
  padding: 26px 30px;
}
.modal-body .body {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.modal-body .body .title {
  font-weight: 700;
  font-size: 25px;
  display: flex;
  justify-content: center;
}
.modal-body .body .description {
  color: var(--darkGrey);
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modal/common-modal/common-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;AACF;AAAE;EACE,qCAAA;AAEJ;AADI;EACE,oBAAA;AAGN;AADI;EACE,gBAAA;AAGN;AAAE;EACE,qCAAA;AAEJ;AADI;EACE,0BAAA;AAGN;;AAEA;;EAEE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,4BAAA;EACA,6BAAA;EACA,qCAAA;EACA,6BAAA;AACF;AAAE;EACE,sBAAA;AAEJ;;AAEA;EACE,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAEJ;AADI;EACE,gBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;AAGN;AADI;EACE,sBAAA;EACA,kBAAA;AAGN","sourcesContent":[":host {\n  border-radius: 16px;\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  &.danger {\n    background-color: var(--modal-layout);\n    .title {\n      color: var(--danger);\n    }\n    .description {\n      font-weight: 500;\n    }\n  }\n  &.info {\n    background-color: var(--modal-layout);\n    .title {\n      color: var(--titlePrimary);\n    }\n  }\n}\n\n.header-container,\n.modal-body {\n  border-radius: 16px;\n}\n\n.header-container {\n  display: flex;\n  justify-content: space-between;\n  padding: 20px 30px;\n  border-bottom-left-radius: 0;\n  border-bottom-right-radius: 0;\n  background-color: var(--modal-layout);\n  border-bottom: 1px solid #999;\n  .title {\n    color: var(--darkGrey);\n  }\n}\n\n.close {\n  position: absolute;\n  right: 20px;\n}\n\n.modal-body {\n  padding: 26px 30px;\n  .body {\n    display: flex;\n    flex-direction: column;\n    gap: 15px;\n    .title {\n      font-weight: 700;\n      font-size: 25px;\n      display: flex;\n      justify-content: center;\n    }\n    .description {\n      color: var(--darkGrey);\n      text-align: center;\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
