import chroma from 'chroma-js';

export const getVariables = (primary: string, isDark: boolean) => {
  const primaryColor = chroma(primary);

  // Generate gradient colors based on the primary color
  const gradientColor1 = isDark
    ? primaryColor.darken(0.5).hex()
    : primaryColor.brighten(0.5).hex();
  const gradientColor2 = isDark
    ? primaryColor.darken(1).hex()
    : primaryColor.brighten(1).hex();

  // Additional gradient colors
  const gradientColor3 = isDark
    ? primaryColor.set('hsl.h', '+30').darken(0.3).hex()
    : primaryColor.set('hsl.h', '+30').brighten(0.3).hex();
  const gradientColor4 = isDark
    ? primaryColor.set('hsl.h', '-30').darken(0.3).hex()
    : primaryColor.set('hsl.h', '-30').brighten(0.3).hex();

    const gradientColorX = isDark
    ? primaryColor.darken(0.8).hex()
    : primaryColor.darken(0.2).hex();

  const gradientColorY = isDark
    ? primaryColor.darken(0.6).hex()
    : primaryColor.darken(0.1).hex();

  return {
    '--primary': primary,
    '--primaryBg': isDark ? '#1c1c1c' : '#ffffff',
    '--primaryBg2': isDark ? chroma(primary).alpha(0.1).css() : '#f0f0f0',
    '--primaryBg3': isDark ? chroma(primary).brighten(0.5).hex() : chroma(primary).darken(0.5).hex(),
    '--info': primary,
    '--indigo': isDark ? '#999999' : '#333333',
    '--dateField': isDark ? chroma(primary).alpha(0.1).css() : '#f7f7f7',
    '--titlePrimary': primary,
    '--titlePrimary2': primary,
    '--primary-tag': primary,
    '--tagText': isDark ? '#ffffff' : '#000000',
    '--primaryRGB': chroma(primary).rgb().join(', '),
    '--lightPrimary': chroma(primary).alpha(0.3).css(),
    '--danger': primary,
    '--dangerRGB': chroma(primary).rgb().join(', '),
    '--checkboxChecked': primary,
    '--darkInfo': '#fb8500',
    '--darkInfo2': '#fb8500',
    '--lightInfo': chroma(primary).alpha(0.2).css(),
    '--success': '#77cd85',
    '--darkGreen': '#719e73',
    '--sectionGrey': isDark ? '#333333' : '#f7f7f7',
    '--darkLiver': 'rgba(77, 77, 77, 0.1)',
    '--grey': '#d9d9d9',
    '--darkGreySecond': primary,
    '--lightGrey': '#d9d9d9',
    '--lightGreySecond': '#d9d9d9',
    '--lightGreyRGB': '217, 217, 217',
    '--darkGreyRBG': '76, 76, 76',
    '--PDFOpacity': '0.08',
    '--darkGreyRGB2': 'rgba(76, 76, 76, 0.11)',
    '--layoutSecond': isDark ? '#1c1c1c' : '#f7f7f7',
    '--modal-layout': isDark ? '#1c1c1c' : '#f7f7f7',
    '--layoutRGB': isDark ? '28, 28, 28' : '247, 247, 247',
    '--permissionText': 'rgba(255, 255, 255, 0.5)',
    '--dms-page-radius': '21px',
    '--dms-content-outline-color': 'none',
    '--quickSearchIcon': 'unset',
    '--quickSearchIconTransparent': 'unset',
    '--documentBarButtonHighlight': 'unset',
    '--documentBarButtonPadding': '0',
    '--documentBarButtonGap': '25px',
    '--primaryUpload': '100%',
    '--foundation-light-blue-normal-hover': chroma(primary).brighten(1).hex(),
    '--foundation-light-blue-light-active': chroma(primary).alpha(0.1).css(),
    '--foundation-light-blue-light-active-dark': chroma(primary).alpha(0.1).css(),
    '--foundation-teal-dark-active': chroma(primary).darken(1).hex(),
    '--foundation-gray-400': '#4c4c4c',
    '--foundation-gray-500': isDark ? '#3a3a3a' : '#f7f7f7',
    '--foundation-gray-600': '#29292d',
    '--foundation-dark-gray': isDark ? '#2f2f2f' : '#e6e6e6',
    '--foundation-dark-red': isDark ? '#291414' : '#fbf0f0',
    '--foundation-white-800': isDark ? '#8b8b8b' : '#595959',
    '--full-screen-bg': isDark ? '#1c1c1c' : '#f7f7f7',
    '--basic-color': isDark ? '#1c1c1c' : '#f7f7f7',
    '--basic-color-reversed': isDark ? '#ffffff' : '#333333',
    '--basic-color-100': isDark ? '#2f2f2f' : '#ffffff',
    '--basic-color-50': '#595959',
    '--table-row-primary': isDark ? '#333333' : '#fff',
    '--primary-color': primary,
    '--primary-background': primary,
    '--grey-background': isDark ? '#22242e' : '#d9d9d9',
    '--left-menu-bg': isDark ? '#1c1c1c' : '#f7f7f7',
    '--folder-color': '#ffc837',
    '--empty-folder-background': chroma(primary).alpha(0.1).css(),
    '--messageTextPrimary': primary,
    '--layout': isDark ? '#1c1c1c' : '#f7f7f7',
    '--darkGrey': isDark ? '#fff' : '#4c4c4c',
    '--text-field-bg': chroma(primary).alpha(0.1).css(),
    '--hover-primary': chroma(primary).alpha(0.1).css(),
    '--text-field-border': primary,
    '--dropdown-body': primary,
    '--mdc-filled-text-field-input-text-color': isDark ? '#f0f0f0' : '#333333',
    '--mdc-filled-text-field-container-color': primary,
    '--mat-datepicker-toggle-icon-color': isDark ? '#f0f0f0' : '#333333',
    '--mat-select-panel-background-color': primary,
    '--mdc-filled-text-field-label-text-color': primary,
    '--mat-form-field-state-layer-color': primary,
    '--default-user-avatar': 'brightness(0.5)',

    // Dynamic linear gradients
    '--linear-gradient-1': `linear-gradient(135deg, ${gradientColor1} 0%, ${gradientColor2} 100%)`,
    // '--linear-gradient-2': `linear-gradient(225deg, ${gradientColor2} 0%, ${gradientColor1} 100%)`,
    '--linear-gradient-3': `linear-gradient(135deg, ${gradientColor3} 0%, ${gradientColor4} 100%)`,
    '--linear-gradient-3-1': `linear-gradient(135deg, ${gradientColor1} 0%, ${gradientColor3} 100%)`,
    '--linear-gradient-5': `linear-gradient(225deg, ${gradientColor3} 0%, ${gradientColor1} 100%)`,
    '--linear-gradient-6': `linear-gradient(97.53deg, ${gradientColor1} 0%, ${gradientColor2} 100%)`,
    '--linear-gradient-7': `linear-gradient(97.53deg, ${gradientColor2} 0%, ${gradientColor3} 100%)`,
    '--linear-gradient-8': `linear-gradient(265.44deg, ${gradientColor4} 0%, ${gradientColor1} 100%)`,
    '--linear-gradient-9': `linear-gradient(97.53deg, ${gradientColor1} 0%, ${gradientColor4} 100%)`,
    '--active-menu-gradient': `linear-gradient(175.83deg, ${gradientColorX} 0%, ${gradientColorY} 100%)`,
  };
};
