// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  position: relative;
  height: calc(100vh - 35px);
}
.main.search {
  height: calc(100vh - 70px);
}
.main.home {
  height: calc(100vh - 67px);
}
.main .workspace-container {
  height: 100%;
}
@media only screen and (max-width: 849px) and (min-width: 767px) {
  .main .workspace-container {
    width: 90%;
    margin-left: auto;
  }
}

.navigation {
  width: 100%;
  margin-bottom: -40px;
  color: var(--layout);
  background: var(--primaryBg);
  border: 1px solid var(--lightPrimary);
  border-radius: var(--dms-page-radius) 0 0 0;
  padding: 20px 15px 50px 15px;
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.25);
}`, "",{"version":3,"sources":["webpack://./src/app/main/main.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,0BAAA;AACF;AAAE;EACE,0BAAA;AAEJ;AAAE;EACE,0BAAA;AAEJ;AACE;EAEE,YAAA;AAAJ;AACI;EAHF;IAII,UAAA;IACA,iBAAA;EAEJ;AACF;;AAEA;EACE,WAAA;EACA,oBAAA;EACA,oBAAA;EACA,4BAAA;EACA,qCAAA;EACA,2CAAA;EACA,4BAAA;EACA,+CAAA;AACF","sourcesContent":[".main {\n  position: relative;\n  height: calc(100vh - 35px);\n  &.search {\n    height: calc(100vh - 70px);\n  }\n  &.home {\n    height: calc(100vh - 67px);\n  }\n\n  .workspace-container {\n    // overflow: hidden;\n    height: 100%;\n    @media only screen and (max-width: 849px) and (min-width: 767px) {\n      width: 90%;\n      margin-left: auto;\n    }\n  }\n}\n\n.navigation {\n  width: 100%;\n  margin-bottom: -40px;\n  color: var(--layout);\n  background: var(--primaryBg);\n  border: 1px solid var(--lightPrimary);\n  border-radius: var(--dms-page-radius) 0 0 0;\n  padding: 20px 15px 50px 15px;\n  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.25);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
