// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 95%;
}
:host ::ng-deep ejs-pdfviewer {
  min-height: auto !important;
}
:host ::ng-deep .e-pdfviewer:not(.e-pv-mobile-view) .e-pv-main-container {
  min-height: 400px !important;
}

dms-document-actions {
  position: absolute;
  bottom: 0px;
}

dialog {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
dialog i {
  cursor: pointer;
  font-size: 29px;
  position: absolute;
  right: 14px;
  top: 15px;
  color: var(--danger);
}`, "",{"version":3,"sources":["webpack://./src/app/routes/home/components/document-details/components/view-document/file/components/pdf/pdf.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;AACF;AACI;EACE,2BAAA;AACN;AACI;EACE,4BAAA;AACN;;AASA;EACE,kBAAA;EACA,WAAA;AANF;;AASA;EACE,eAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AANF;AAOE;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,oBAAA;AALJ","sourcesContent":[":host {\n  display: block;\n  height: 95%;\n  ::ng-deep {\n    ejs-pdfviewer {\n      min-height: auto !important;\n    }\n    .e-pdfviewer:not(.e-pv-mobile-view) .e-pv-main-container {\n      min-height: 400px !important;\n    }\n  }\n}\n\n// ngx-doc-viewer {\n//   height: 100%;\n//   width: 100%;\n// }\n\ndms-document-actions {\n  position: absolute;\n  bottom: 0px;\n}\n\ndialog {\n  position: fixed;\n  top: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 9;\n  i {\n    cursor: pointer;\n    font-size: 29px;\n    position: absolute;\n    right: 14px;\n    top: 15px;\n    color: var(--danger);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
