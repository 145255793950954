export const findLast = url => url.split('/').pop();

export const removeCustomPaths = (path: string) => {
  const routePaths = ['manage', 'edit', 'history', 'alerts'];
  const parts = path.split('/');
  const filteredParts = parts.filter(part => !routePaths.includes(part));

  return filteredParts.join('/');
};

export const takeManagePath = url => url.substring(7, -1);
