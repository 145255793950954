// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `video {
  min-width: 500px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/home/components/document-details/components/view-document/file/components/video.component.ts"],"names":[],"mappings":"AACM;EACE,gBAAA;EACA,WAAA;AAAR","sourcesContent":["\n      video {\n        min-width: 500px;\n        width: 100%;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
