import { createFeatureSelector, createSelector } from '@ngrx/store';
import { displayedDataAdapter, DisplayedDataState } from './displayed-data.adapter';

export const DISPLAYED_DATA_STATE_NAME = 'displayed-data';
const displayData = createFeatureSelector<DisplayedDataState>(DISPLAYED_DATA_STATE_NAME);

const { selectAll: SelectAllDisplayedData } = displayedDataAdapter.getSelectors();

const selectDisplayedData = (state: DisplayedDataState) =>
  SelectAllDisplayedData(state.displayData);

export const takeDisplayedData = createSelector(displayData, selectDisplayedData);

export const takeDisplayDataPagination = createSelector(
  displayData,
  state => state.pagination,
);

export const displayDataWithPagination = createSelector(displayData, state => ({
  displayData: SelectAllDisplayedData(state.displayData),
  pagination: state.pagination,
}));

export const takeFilteredDisplayedData = createSelector(
  displayData,
  state => state.filters,
);

export const takeDisplayDataChange = createSelector(
  displayData,
  state => state.pagination,
);
