export const MESSAGES = [
  {
    id: 1,
    userName: 'Username 1',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: true,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 2,
    userName: 'Username 2',
    message:
      'Lorem ipsaccusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 3,
    userName: 'Username 3',
    message: 'Lorem ipsum dolor sit.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 4,
    userName: 'Username 4',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: true,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 5,
    userName: 'Username 5',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 6,
    userName: 'Username 6',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 7,
    userName: 'Username 7',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 3,
    userName: 'Username 3',
    message: 'Lorem ipsum dolor sit.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 4,
    userName: 'Username 4',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 5,
    userName: 'Username 5',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 6,
    userName: 'Username 6',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 7,
    userName: 'Username 7',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 3,
    userName: 'Username 3',
    message: 'Lorem ipsum dolor sit.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 4,
    userName: 'Username 4',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 5,
    userName: 'Username 5',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 6,
    userName: 'Username 6',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 7,
    userName: 'Username 7',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 3,
    userName: 'Username 3',
    message: 'Lorem ipsum dolor sit.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 4,
    userName: 'Username 4',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 5,
    userName: 'Username 5',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 6,
    userName: 'Username 6',
    message:
      'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis! Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.',
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
  {
    id: 7,
    userName: 'Username 7',
    message: `
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Fuga accusamus, illo doloribus dolorem veniam aspernatur perspiciatis!
      Neque nesciunt assumenda hic accusantium ea distinctio, quasi magni magnam consectetur placeat eligendi facere.
    `,
    isViewed: false,
    isMe: false,
    time: 'Today, Jul 28, 2022',
  },
];