import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GetDocumentService {
  nuxeoApiUrl = `${environment.nuxeoApiUrl}`;

  constructor(private http: HttpClient) {}

  getDocxFuleById(id: string) {
    return this.http.get(`${this.nuxeoApiUrl}/documents/${id}`);
  }

  getDocumentByPath(path: string) {
    return this.http.get(`${this.nuxeoApiUrl}/documents/by-path?path=${path}`);
  }

  getDocumentsByIds(ids: string[], includeBlob: boolean = false) {
    return this.http.post(`${this.nuxeoApiUrl}/documents/by-ids`, ids, {
      params: { includeFileBlob: includeBlob },
    });
  }

  downloadDocument(id: string) {
    return this.http.get(`${this.nuxeoApiUrl}/documents/${id}/download`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  getDocumentsByParentId(id: string) {
    return this.http.get(`${this.nuxeoApiUrl}/documents/by-parentId`, {
      params: { parentId: id },
    });
  }

  getDocumentById(id: string): Observable<Blob> {
    return this.http
      .get(`${this.nuxeoApiUrl}/documents/${id}/download`, {
        responseType: 'blob',
      })
      .pipe(
        catchError(error => {
          console.error(`Failed to fetch document with ID ${id}: `, error);
          return throwError(() => new Error(`Failed to fetch document with ID ${id}.`));
        }),
      );
  }
}
