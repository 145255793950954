import { SelectedRows } from '@shared/components/workspace/data-table/models/data-table.model';

export interface SelectItemState {
  selectedItems: SelectedRows[];
  selectAllRows: boolean;
}

export const initialState: SelectItemState = {
  selectedItems: [],
  selectAllRows: false,
};
