import { ActionEvent } from '@shared/components/workspace/data-table/models/actions/action-event.model';
import { map } from 'rxjs';
import { deleteDisplayedDataSuccess } from '../displayed-data.action';

export const deleteSuccess = event =>
  map(() => {
    let id: string | string[];

    if (event instanceof ActionEvent) {
      id = event.data.id;
    } else {
      id = event.data.map(v => v.id);
    }
    return deleteDisplayedDataSuccess({ id });
  });
