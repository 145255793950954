// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:host i {
  color: var(--titlePrimary);
}`, "",{"version":3,"sources":["webpack://./src/app/layout/tablet-navigation/search-navigation/search-navigation.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,0BAAA;AAEJ","sourcesContent":[":host {\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  i {\n    color: var(--titlePrimary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
