import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IDocumentEntity } from '@shared/models/document.model';
import { IDateFilter, ManageMenuTree, NewManageMenuTree } from './manage.model';
import { ManageState } from './manage.state';

export const Manage = createActionGroup({
  source: '[manage]',
  events: {
    'Load Data By Path': props<{ path?: string, navigateByDisplayPath?: boolean, noNavigate?: boolean }>(),
    'Load Data By Path Success': props<ManageState>(),
    'Toggle Folder': props<{
      node: NewManageMenuTree;
      dispatchable?: boolean;
      documentsList?: IDocumentEntity[]; // exsitsing documents list
    }>(),
    'Toggle Folder Success': props<{
      menuTree: ManageMenuTree[];
    }>(),
    'Set Active Documents List': props<{ menu: NewManageMenuTree }>(),
    'Set Active Documents List Succcess': props<Omit<ManageState, 'menuTree'>>(),
    'Set Selected Document': props<{ selectedDocument: IDocumentEntity }>,
    'Filter Active Document List': props<{ range: IDateFilter }>(),
    'Filter Active Document List Success': props<{
      filteredDocumentsList: IDocumentEntity[];
      range: IDateFilter;
    }>(),
    'Toggle Mneu': props<{ open: boolean }>(),
    'Clear': emptyProps(),
  },
});
