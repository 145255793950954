// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

h2 {
  font-size: 24px;
}

.close {
  font-size: 30px;
  color: var(--danger);
  cursor: pointer;
  padding: 6px 15px;
  border-radius: 5px;
  transition: 0.2s;
}
.close * {
  transition: 0.2s;
}
.close:hover {
  background: linear-gradient(135deg, rgba(219, 106, 106, 0.15) 0%, rgba(133, 39, 39, 0.15) 100%);
}
.close:hover i {
  transition: 0.2s;
  transform: scale(0.8);
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/modal/modal-header/modal-header.component.ts"],"names":[],"mappings":"AACM;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;AAAR;;AAEM;EACE,eAAA;AACR;;AACM;EACE,eAAA;EACA,oBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AAER;AADQ;EACE,gBAAA;AAGV;AADQ;EACE,+FAAA;AAGV;AAEU;EACE,gBAAA;EACA,qBAAA;AAAZ","sourcesContent":["\n      :host {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        width: 100%;\n      }\n      h2 {\n        font-size: 24px;\n      }\n      .close {\n        font-size: 30px;\n        color: var(--danger);\n        cursor: pointer;\n        padding: 6px 15px;\n        border-radius: 5px;\n        transition: 0.2s;\n        * {\n          transition: 0.2s;\n        }\n        &:hover {\n          background: linear-gradient(\n            135deg,\n            rgba(219, 106, 106, 0.15) 0%,\n            rgba(133, 39, 39, 0.15) 100%\n          );\n          i {\n            transition: 0.2s;\n            transform: scale(0.8);\n          }\n        }\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
