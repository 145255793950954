import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { ManageHeaderComponent } from '@routes/manage/components/workspace/manage-header/manage-header.component';
import { Manage } from '@state/manage/manage.action';

@Component({
  selector: 'dms-manage-navigation',
  templateUrl: './manage-navigation.component.html',
  styleUrls: ['./manage-navigation.component.scss'],
  imports: [ManageHeaderComponent],
  standalone: true,
})
export class ManageNavigationComponent {
  open = false;
  constructor(private store: Store) {}

  toggleNavigationTree() {
    this.open = !this.open;
    this.store.dispatch(Manage.toggleMneu({ open: this.open }));
  }
}
