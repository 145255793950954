import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayedValue',
  standalone: true,
})
export class DisplayedValue<T> implements PipeTransform {
  transform(item: T, displayName: string): T | string {
    if (displayName && item && item.hasOwnProperty(displayName)) {
      return item[displayName];
    }
    return item;
  }
}
