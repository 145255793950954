interface Body {
  title: string;
  description?: string; // description to content
}

export class ModalTheme {
  constructor(
    public themeClassName: string = 'danger',
    public modalClassName: string = 'common center',
  ) {
    if (!modalClassName) {
      // do it
    }
  }
}

export class ModalParams {
  constructor(
    public body: Body,
    public submitTpl: string,
    public cancel = 'cancel',
    public header: string = 'DMS Platform Message',
    public submit?: any,
    public timeout?: number,
    public isConfirmModal = false,
  ) {}
}

export class CommonModal {
  constructor(
    public params: ModalParams,
    public theme: ModalTheme = new ModalTheme(),
  ) {}
}
