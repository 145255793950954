import { IActiveFilter, ManageMenuTree } from './manage.model';
import { IDocumentEntity } from '@shared/models/document.model';

export class ManageState {
  menuTree: ManageMenuTree[];
  documentsList: IDocumentEntity[];
  currentDocument: IDocumentEntity | null;
  filters?: IActiveFilter;
  isMenuOpen?: boolean;
}

export const initialState: ManageState = {
  menuTree: [],
  documentsList: [],
  currentDocument: null,
  isMenuOpen: false,
};
