import { DisplayedUser, IUser } from '@common/models/users.model';

const create = (user: IUser) => {
  let gender = 'M';
  let avatar = '';

  if (user?.contextParameters) {
    gender = user?.contextParameters?.userprofile?.gender ? 'M' : 'W';
    avatar = user?.contextParameters?.userprofile?.avatar;
  } else {
    console.error(`User ${user} is not corret`);
  }

  return new DisplayedUser(
    user.id,
    avatar,
    user?.properties?.username,
    user?.properties?.firstName,
    user?.properties?.lastName,
    gender,
    user?.properties?.company,
    user?.properties?.email,
    user?.properties?.groups,
  );
};

export const nuxeoEntityToDisplayedUser = (_users: IUser[]): DisplayedUser[] => {
  const users: DisplayedUser[] = [];

  _users.forEach(user => {
    users.push(create(user));
  });

  return users;
};

export const convertUserToDisplayedUser = (user: IUser) => create(user);

export const convertUserCardToUser = (_user: DisplayedUser) => ({
  id: _user.id,
  'entity-type': 'user',
  isAdministrator: true,
  isAnonymous: false,
  properties: {
    ..._user,
  },
});
