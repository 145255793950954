import { Component, Input } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { ModalService } from '@shared/components/modal/services/modal.service';
import { MessagesComponent } from './messages/messages.component';
import { NotificationComponent } from './notification/notification.component';
import { MESSAGE } from '@icons/dms-fontawesome-icons';
import { INotification } from './notification/model/notification.model';

type NotificationType = ComponentType<NotificationComponent | MessagesComponent>;

const notificationComponents = {
  message: 'MessagesComponent',
  notification: 'NotificationComponent',
};

@Component({
  selector: 'dms-notification',
  templateUrl: 'notifications.component.html',
  styleUrls: ['notifications.component.scss'],
})
export class NotificationsComponent {
  private _notification: INotification | null = null;
  private timeoutId: any = null;
  animate = false;

  @Input()
  get notification(): INotification | null {
    return this._notification;
  }

  set notification(value: INotification | null) {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    if (value && (!this._notification || this._notification.id !== value.id)) {
      this.animate = true;

      this.timeoutId = setTimeout(() => {
        this.animate = false;
      }, 2000);
    } else {
      this.animate = false;
    }

    this._notification = value;
  }

  messageIcon = MESSAGE;

  constructor(private modalService: ModalService) {}

  openNotificationModal(name: string) {
    const componentName = notificationComponents[name];
    let _component: NotificationType;
    if (componentName === 'MessagesComponent') {
      _component = MessagesComponent;
    } else {
      _component = NotificationComponent;
    }

    this.modalService.createModalComponent(_component);
  }
}
