// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-mdc-icon-button.mat-mdc-button-base {
  height: 0;
  padding: 0;
  width: 30px;
}
::ng-deep .mat-mdc-button-touch-target {
  height: 0 !important;
  width: 0 !important;
}
::ng-deep .mat-mdc-menu-content {
  padding: 0 !important;
  background-color: var(--layout);
  color: var(--darkGrey);
}
::ng-deep .dms-select-menu .dms-select-item {
  color: var(--basic-color-reversed) !important;
  border-radius: 0;
}
::ng-deep .dms-select-menu.dms-action-select-menu {
  border-radius: 15px !important;
}

button i {
  color: var(--darkGreySecond);
  font-size: 22px;
  transition: 0.2s ease-in-out;
}
button:hover i {
  transform: scale(0.8) !important;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/dms-select/dms-select.component.scss"],"names":[],"mappings":"AAGE;EACE,SAAA;EACA,UAAA;EACA,WAAA;AAFJ;AAIE;EACE,oBAAA;EACA,mBAAA;AAFJ;AAIE;EACE,qBAAA;EACA,+BAAA;EACA,sBAAA;AAFJ;AAKI;EACE,6CAAA;EACA,gBAAA;AAHN;AAME;EACE,8BAAA;AAJJ;;AASE;EACE,4BAAA;EACA,eAAA;EACA,4BAAA;AANJ;AASI;EACE,gCAAA;AAPN","sourcesContent":["@import './../../../../assets/styles/custom-material.scss';\n\n::ng-deep {\n  .mat-mdc-icon-button.mat-mdc-button-base {\n    height: 0;\n    padding: 0;\n    width: 30px;\n  }\n  .mat-mdc-button-touch-target {\n    height: 0 !important;\n    width: 0 !important;\n  }\n  .mat-mdc-menu-content {\n    padding: 0 !important;\n    background-color: var(--layout);\n    color: var(--darkGrey);\n  }\n  .dms-select-menu {\n    .dms-select-item {\n      color: var(--basic-color-reversed) !important;\n      border-radius: 0;\n    }\n  }\n  .dms-select-menu.dms-action-select-menu {\n    border-radius: 15px !important;\n  }\n}\n\nbutton {\n  i {\n    color: var(--darkGreySecond);\n    font-size: 22px;\n    transition: 0.2s ease-in-out;\n  }\n  &:hover {\n    i {\n      transform: scale(0.8) !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
