import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ManageState } from './manage.state';
import { convertDocumentToView } from '@shared/util/convert-document-view';
import { getDocumentIcon } from '@icons/file/icons';

export const MANAGE_STATE_NAME = 'manage';

const manageState = createFeatureSelector<ManageState>(MANAGE_STATE_NAME);

export const takeMenuTree = createSelector(manageState, state => state.menuTree);
export const takeDisplayedDocumentsList = createSelector(manageState, state => {
  const documents = convertDocumentToView(state.documentsList);
  return documents;
});

export const takeDocumentsList = createSelector(
  manageState,
  state => state.documentsList,
);

export const takeCurrentDocument = createSelector(manageState, state => {
  let currentDocument = state.currentDocument;
  let icon;

  if (currentDocument) {
    icon = getDocumentIcon(currentDocument);
    currentDocument = { ...currentDocument, icon };
  }

  return currentDocument;
});

export const takeActiveFilter = createSelector(manageState, state =>
  state.filters?.date ? state.filters?.date : { start: '', end: '' },
);

export const takeMenuStatus = createSelector(manageState, state => !!state.isMenuOpen);

// export const selectDocument = (id: string) =>
//   createSelector(manageState, state => state.documentsList.find(v => v.uid === id));

// export const takeMenuNode = (id: string) =>
//   createSelector(manageState, state => {
//     console.log(state);
//     console.log(id);
//     return state.menuTree.find(v => v.uid === id);
//   });
