import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { toggleSearchMenu } from '@routes/search/state/search.action';

@Component({
  selector: 'dms-search-navigation',
  templateUrl: './search-navigation.component.html',
  styleUrls: ['./search-navigation.component.scss'],
})
export class SearchNavigationComponent {
  open = false;

  constructor(private store: Store) {}

  tablist = [
    { title: 'Search', path: '/search/results' },
    { title: 'Saved Searches', path: '/search/saved' },
  ];

  toggleMenu() {
    this.open = !this.open;
    this.store.dispatch(toggleSearchMenu({ isOpend: this.open }));
  }
}
