import {
  Renderer2,
  Inject,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomCssService } from '@routes/admin/settings/components/css/services/custom-css.service';
import { DEVICE } from '@shared/models/device.model';
import { DeviceService } from '@shared/services/device.service';
import { currentRoute } from '@state/router-state/router.selector';
import { takeSidebarMenuStatus } from '@state/sidebar-menu/sidebar-menu.selector';
import { filter, map, Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  device$: Observable<DEVICE>;
  currentUrl$: Observable<string>;
  currentModule$: Observable<any>;
  isOpendSidebar$: Observable<boolean>;

  constructor(
    private store: Store,
    private deviceService: DeviceService,
    private customCssService: CustomCssService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.takeCurrentModule();
    this.isOpendSidebar$ = this.store.select(takeSidebarMenuStatus);
    this.device$ = this.deviceService.deviceName;

    this.customCssService.getAllCss().subscribe((css: any) => {
      this.applyCssToPage(css);
    });
  }

  private applyCssToPage(cssArray: any[]) {
    cssArray.forEach(cssItem => {
      const styleElement = this.renderer.createElement('style');
      this.renderer.setProperty(styleElement, 'innerHTML', cssItem.css);
      this.renderer.appendChild(this.document.head, styleElement);
    });
  }

  takeCurrentModule() {
    this.currentModule$ = this.store.select(currentRoute).pipe(
      filter(v => !!v?.url),
      map(route => route?.url.split('/')[1]),
    );
  }
}
