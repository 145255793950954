import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { FORM_TYPES, ManageCreateState } from './create-state';

@Injectable()
export class ManageCreateStore extends ComponentStore<ManageCreateState> {
  constructor() {
    super({
      formType: FORM_TYPES.DOCUMENT_TYPE,
      collectionTitle: '',
      collectionDocuments: [],
    });
  }

  readonly setFormType = this.updater((state, formType: FORM_TYPES) => ({
    ...state,
    formType,
  }));

  readonly setCollectionTitle = this.updater((state, collectionTitle: string) => ({
    ...state,
    collectionTitle,
  }));

  readonly addCollectionDocument = this.updater((state, newDocument: any) => ({
    ...state,
    collectionDocuments: [...state.collectionDocuments, newDocument],
  }));

  readonly removeCollectionDocument = this.updater((state, document: any) => ({
    ...state,
    collectionDocuments: [...state.collectionDocuments.filter(v => v !== document)],
  }));

  readonly formType$ = this.select(state => state.formType);
  readonly collectionDocuments$ = this.select(state => state.collectionDocuments);
}
