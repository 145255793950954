import { Component, Input, OnInit } from '@angular/core';
import { Message } from '../models/message.model';

@Component({
  selector: 'dms-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  @Input() message: Message;

  constructor() {}

  ngOnInit() {
    console.log();
  }
}
