// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
:host .dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
}
:host .dialog i {
  cursor: pointer;
  font-size: 29px;
  position: absolute;
  right: 14px;
  top: 15px;
  color: var(--danger);
}
:host ::ng-deep .e-sheet-panel {
  height: 50%;
}`, "",{"version":3,"sources":["webpack://./src/app/routes/home/components/document-details/components/view-document/file/components/office/office.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AACJ;AAAI;EACE,eAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,oBAAA;AAEN;AAGI;EACE,WAAA;AADN","sourcesContent":[":host {\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n\n  .dialog {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    z-index: 9;\n    i {\n      cursor: pointer;\n      font-size: 29px;\n      position: absolute;\n      right: 14px;\n      top: 15px;\n      color: var(--danger);\n    }\n  }\n\n  ::ng-deep {\n    .e-sheet-panel {\n      height: 50%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
