import { Renderer2, inject } from '@angular/core';
import { INPUT_TYPE } from '@shared/models/input-type.model';

export abstract class InputDirevtive {
  renderer = inject(Renderer2);

  protected calculatePosition = (input: HTMLElement, icon: HTMLElement) => {
    const classList = Array.from(input.classList);
    const contains = classList.find(v => v === INPUT_TYPE.LG || v === INPUT_TYPE.MD);

    const width: number = input.getBoundingClientRect().width;
    const height: number = input.getBoundingClientRect().height;

    const X = width - 30;
    let Y = 0;

    switch (contains) {
      case INPUT_TYPE.LG:
        this.renderer.setStyle(icon, 'fontSize', '20px');
        Y = height / 2 + 10;
        break;
      case INPUT_TYPE.MD:
        this.renderer.setStyle(icon, 'fontSize', '13px');
        Y = height / 2 + 5;
        break;
    }

    return { X, Y };
  };

  protected addClass(element: HTMLElement, classList: string[]) {
    for (let i = 0; i < classList.length; i++) {
      this.renderer.addClass(element, classList[i]);
    }
  }
}
