import { createAction, props } from '@ngrx/store';
import { ModalState } from './modal.state';

export const SET_MODAL = '[modal] set modal';
export const setModal = createAction(SET_MODAL, props<{ modal: ModalState }>());

export const SET_MODAL_SUCCESS = '[modal] open';
export const setModalSuccess = createAction(
  SET_MODAL_SUCCESS,
  props<{ modal: ModalState }>(),
);

export const setBackdropOrderPosition = createAction(
  '[Modal] Set Backdrop Order Position',
  props<{ orderPosition: number }>()
);
