import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { getVariables } from './themes';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeConfig = {
    light: ['default-light', 'cyan', 'teal', 'blue-gray', 'blue'],
    dark: ['default-dark', 'red', 'purple', 'deep-purple', 'indigo'],
  };
  currentColor: string | null = null;

  private currentTheme: string | null = null;
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
  ) {
    const savedTheme = localStorage.getItem('theme');
    const savedColor = localStorage.getItem('color');

    if (savedTheme) {
      this.setTheme(savedTheme);
    }

    if (savedColor) {
      this.changeColors(savedColor);
    }
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  setTheme(name: string) {
    if (!this.themeConfig[name]) {
      console.warn(`Theme ${name} is not valid`);
      return;
    }

    if (this.currentColor) {
      localStorage.setItem('color', this.currentColor);
    }

    Object.keys(this.themeConfig).forEach(theme =>
      this.document.body.classList.remove(theme),
    );

    this.clearColors();

    this.document.body.classList.add(name);
    this.currentTheme = name;
    localStorage.setItem('theme', name);

    const defaultColor = this.themeConfig[name][0];
    this.changeColors(defaultColor);
  }

  changeColors(color: string) {
    const themeForColor = Object.keys(this.themeConfig).find(theme =>
      this.themeConfig[theme].includes(color),
    );
    const currentColor = localStorage.getItem('color');
    const themeType = localStorage.getItem('theme') || 'light';

    if (currentColor === color) {
      return;
    }

    if (!themeForColor) {
      this.clearColors();
      console.warn(`Color ${color} is not valid for any theme`);
      return;
    }

    if (this.currentTheme !== themeForColor) {
      this.setTheme(themeForColor);
    }

    // Clear previous colors and custom themes
    this.clearColors();

    // Remove previous custom theme class
    const classList = this.document.body.classList;
    const regex = new RegExp(`^custom-theme-${themeType}-`);
    classList.forEach(className => {
      if (regex.test(className)) {
        classList.remove(className);
      }
    });

    // Add new color class
    this.document.body.classList.add(color);
    localStorage.setItem('color', color);
  }

  generateThemeStyle(baseColor: string, isDark: boolean, theme: string) {
    const primary = baseColor;
    const variables = getVariables(primary, isDark);

    const style = `
    body.${theme}.${primary.replace('#', '')} {
      ${Object.entries(variables)
        .map(([key, value]) => `${key}: ${value};`)
        .join('\n')}
    }
  `;

    return style;
    // this.sanitizer.bypassSecurityTrustStyle(style);
  }

  applyTheme(baseColor: string) {
    const themeType = localStorage.getItem('theme');
    this.currentColor = baseColor;
    localStorage.setItem('color', this.currentColor);

    const primary = baseColor;
    // const lightPrimary = chroma(primary).brighten(2).css();
    // const darkPrimary = chroma(primary).darken(2).css();

    const isDark = themeType === 'dark';
    const themeName = `custom-theme-${themeType}-${primary.replace('#', '')}`;

    const variables = getVariables(primary, isDark);
    const styleElement = document.createElement('style');

    styleElement.innerHTML = `
      body.${themeType}.${themeName} {
        ${Object.entries(variables)
          .map(([key, value]) => `${key}: ${value};`)
          .join('\n')}
      }
    `;

    // Check for and remove any previous custom theme class
    const classList = this.document.body.classList;
    const regex = new RegExp(`^custom-theme-${themeType}-`);
    classList.forEach(className => {
      if (regex.test(className)) {
        classList.remove(className);
      }
    });

    this.document.head.appendChild(styleElement);
    this.document.body.classList.add(themeName);
  }

  private clearColors() {
    if (this.currentTheme) {
      this.themeConfig[this.currentTheme].forEach(color => {
        this.document.body.classList.remove(color);
      });
    }
  }

  injectCSS(css: string) {
    const styleElement = this.renderer.createElement('style');
    this.renderer.setProperty(styleElement, 'type', 'text/css');
    this.renderer.appendChild(styleElement, this.renderer.createText(css));
    this.renderer.appendChild(this.document.head, styleElement);
  }
}
