import { getDocumentIcon } from '@icons/file/icons';
import { IDocumentEntity } from '@shared/models/document.model';
import {
  ManageMenuTree,
  NewManageMenuTree,
  transformToMenuTree,
} from '@state/manage/manage.model';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from 'lodash';

export const convertDocumentToNewMenuTree = (
  nuxeoDocument: IDocumentEntity,
  children: IDocumentEntity[] = [],
): NewManageMenuTree => {
  if (!nuxeoDocument) {
    console.error('Smt wrong');
  }

  const isFolder = v => !!v.facets.includes('Folderish');

  const convertedChildren = children.map(child => convertDocumentToNewMenuTree(child));

  convertedChildren.map((converted, i) => {
    converted.icon = getDocumentIcon(children[i]);
    return converted;
  });

  const result = new NewManageMenuTree(
    nuxeoDocument.type.toLowerCase() !== 'root'
      ? nuxeoDocument.title
      : nuxeoDocument.type, // check root
    isFolder(nuxeoDocument),
    nuxeoDocument.uid,
    nuxeoDocument.type,
    convertedChildren,
  );
  return result;
};

const setMenuChildren = (menu: NewManageMenuTree, children: IDocumentEntity[] = []) => {
  const childrenMenu = children.length
    ? children.map(v => convertDocumentToNewMenuTree(v))
    : [];

  childrenMenu.map((converted, i) => {
    converted.icon = getDocumentIcon(children[i]);
    return converted;
  });

  return new NewManageMenuTree(
    menu.name, // check root
    menu.isFolder,
    menu.uid,
    menu.type,
    childrenMenu,
    menu.loading,
    menu.expand,
    menu.level,
    menu.emptyFolder,
    menu.active,
  );
};

export const toggleFolder = ({ node, menuTree, documentsList }) => {
  const menu = { ...node };
  // const isFolder = documentsList[0].contextParameters.hasFolderishChild; // check isFolder
  menu.loading = false;
  let updatedMenu;

  if (!menu.expand) {
    menu.expand = false;
    updatedMenu = setMenuChildren(menu, []);
  } else {
    updatedMenu = setMenuChildren(menu, documentsList);
  }

  const updateMenuItem = (
    menu: NewManageMenuTree[],
    uid: string,
    updatedItem: NewManageMenuTree,
  ) =>
    menu.map(item => {
      if (item.uid === uid) {
        item = updatedItem;
      }

      if (item.children && item.children.length > 0) {
        const updatedChildren = updateMenuItem(item.children, uid, updatedItem);

        if (updatedChildren.some(child => child !== item.children)) {
          return transformToMenuTree(item, updatedChildren);
        }
      }

      return item;
    });

  const updatedMenuTree = updateMenuItem(menuTree, menu.uid, updatedMenu);
  return updatedMenuTree;
};

// change folder for this
export const navigateWithinManage = (url: ManageMenuTree[] | string[]) => {
  let manageUrl: string[] = [];

  if (typeof url[0] === 'object' && 'name' in url[0]) {
    const manageMenuTree = url as ManageMenuTree[];
    manageUrl = [
      `manage/${manageMenuTree
        .map(v => v.name)
        .join('/')
        .toLowerCase()}`,
    ];
  } else {
    manageUrl = [
      `manage/${url
        .map(v => v.toLowerCase())
        .join('/')
        .toLowerCase()}`,
    ];
  }

  return manageUrl;
};

// change folder for this
