// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.44);
  top: 0;
  left: 0;
}

:host .in {
  animation: backdrop-in 1s;
}

:host .out {
  animation: backdrop-out 0.9s;
}

@keyframes backdrop-in {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.44);
  }
}
@keyframes backdrop-out {
  from {
    background: rgba(0, 0, 0, 0.44);
  }
  to {
    background: rgba(0, 0, 0, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/backdrop/backdrop.component.ts"],"names":[],"mappings":"AACM;EACE,eAAA;EACA,WAAA;EACA,aAAA;EACA,+BAAA;EACA,MAAA;EACA,OAAA;AAAR;;AAGM;EACE,yBAAA;AAAR;;AAGM;EACE,4BAAA;AAAR;;AAGM;EACE;IACE,4BAAA;EAAR;EAEM;IACE,+BAAA;EAAR;AACF;AAEM;EACE;IACE,+BAAA;EAAR;EAEM;IACE,4BAAA;EAAR;AACF","sourcesContent":["\n      div {\n        position: fixed;\n        width: 100%;\n        height: 100vh;\n        background: rgb(0 0 0 / 44%);\n        top: 0;\n        left: 0;\n      }\n\n      :host .in {\n        animation: backdrop-in 1s;\n      }\n\n      :host .out {\n        animation: backdrop-out 0.9s;\n      }\n\n      @keyframes backdrop-in {\n        from {\n          background: rgb(0 0 0 / 0%);\n        }\n        to {\n          background: rgb(0 0 0 / 44%);\n        }\n      }\n      @keyframes backdrop-out {\n        from {\n          background: rgb(0 0 0 / 44%);\n        }\n        to {\n          background: rgb(0 0 0 / 0%);\n        }\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
