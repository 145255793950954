import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICON_TYPE } from './models/icon-type.model';
import { MaterialModule } from '@shared/material.module';
import { CommonModule } from '@angular/common';
import { DisplayedValue } from '@shared/pipes/display-name.pipe';
import { SafePipe } from '@shared/pipes/safe.pipe';

@Component({
  selector: 'dms-select',
  templateUrl: `./dms-select.component.html`,
  styleUrls: [`./dms-select.component.scss`],
  standalone: true,
  imports: [MaterialModule, CommonModule, DisplayedValue, SafePipe],
})
export class DmsSelectComponent implements OnInit {
  @Input() items: any[];
  @Input() placeholder: string;
  @Input() type: ICON_TYPE = ICON_TYPE.dropdown;
  @Input() displayName: string;
  private _isAction: boolean;
  @Input() set isAction(value: boolean) {
    this._isAction = value;
    this.displayName = 'template';
  }

  get isAction() {
    return this._isAction;
  }

  @Output() emitSelected = new EventEmitter();

  ngOnInit() {
    if (!this.placeholder && this.items.length) {
      try {
        this.placeholder = this.displayName
          ? this.items[0][this.displayName]
          : this.items[0];
      } catch {
        console.error('Something wrong');
      }
    }
  }
}
