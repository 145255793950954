import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { createRequestOption } from '@shared/util/request.util';
import { INewUserProfile } from '@common/models/users.model';

@Injectable({
  providedIn: 'root',
})
export class MeService {
  private nuxeoUrl = `${environment.nuxeoURL}`;
  private nuxeoApiUrl = `${environment.nuxeoApiUrl}`;

  public userInfoUpdated: Subject<void> = new Subject();

  constructor(private http: HttpClient) {}

  getMe(headers?: HttpHeaders): Observable<any> {
    const params = createRequestOption({ q: '*', 'enrichers.user': 'userprofile' });
    return this.http.get<any>(`${this.nuxeoUrl}/me`, { params, headers });
  }

  getUserBuyId(id: string, headers?: HttpHeaders): Observable<any> {
    const params = createRequestOption({ q: '*', 'enrichers.user': 'userprofile', 'includeProfileImage': true });
    return this.http.get<any>(`${this.nuxeoApiUrl}/admin/users/${id}`, {
      params,
      headers,
    });
  }

  getCurrentUserProfile(): Observable<INewUserProfile> {
    const params = createRequestOption({ includeProfileImage: true });

    return this.http.get<INewUserProfile>(`${this.nuxeoApiUrl}/admin/profile`, {
      params,
    });
  }

  changeProfilePicture(id: string, base64Image: string): Observable<any> {
    return this.http.put(`${this.nuxeoApiUrl}/admin/userProfileImage`, {
      id,
      base64Image,
    });
  }

  changeProfileInfo(info): Observable<any> {
    return this.http.put(`${this.nuxeoApiUrl}/admin/users`, info);
  }

  deleteUserProfileImage(): Observable<any> {
    return this.http.delete(`${this.nuxeoApiUrl}/admin/userProfileImage`);
  }

  getUserProfile(id: string, headers?: HttpHeaders): Observable<any> {
    const params = createRequestOption({ q: '*', 'enrichers.user': 'userprofile' });
    return this.http.get<any>(`${this.nuxeoApiUrl}/admin/users/${id}`, {
      params,
      headers,
    });
  }

  changePassword(user: any): Observable<any> {
    const params = createRequestOption({ q: '*', 'enrichers.user': 'userprofile' });
    return this.http.put(`${this.nuxeoUrl}/me/changepassword`, user, { params });
  }
}
