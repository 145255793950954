/* eslint-disable no-use-before-define */
export interface DataTemplate {
  template: string;
  value: any;
}

interface IAudit {
  'entity-type': string;
  id: number;
  category: string;
  principalName: string;
  comment: string | null;
  docLifeCycle: string;
  docPath: string;
  docType: string;
  docUUID: string;
  eventId: string;
  repositoryId: string;
  eventDate: string;
  logDate: string;
  extended: {
    blobFilename: string;
    downloadReason: string;
    blobXPath: string;
  };
}

export interface IContextParameters {
  breadcrumb: IDocument;
  children: IDocument;
  hasFolderishChild: boolean;
  tags: string[];
  audit: IAudit[] | any;
  collections: any[];
  favorites: {
    isFavorite: boolean;
  };
}

export interface IDocumentTypes {
  doctypes: {
    [key: string]: {
      facets: string[];
      parent: string;
      schemas: string[];
    };
  };
}

export interface IDocumentEntity {
  'entity-type': string;
  changeToken: string;
  facets: string[];
  hasLegalHold: boolean;
  isCheckedOut: boolean;
  isProxy: boolean;
  isRecord: boolean;
  isTrashed: boolean;
  isUnderRetentionOrLegalHold: boolean;
  isVersion: boolean;
  lastModified: string;
  parentRef: string;
  contextParameters: IContextParameters;
  path: string;
  proxyTargetId: string;
  repository: string;
  retainUntil: any;
  schemas: any[];
  state: string;
  title: string;
  type: string;
  uid: string;
  id: string;
  versionableId: string;
  errorMessage: boolean;
  hasError: boolean;
  isLastPageAvailable: boolean;
  isNextPageAvailable: boolean;
  isPaginable: boolean;
  isPreviousPageAvailable: boolean;
  isSortable: boolean;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  quickFilters: any[];
  resultsCount: number;
  resultsCountLimit: number;
  totalSize: number;
  icon: DataTemplate;
  expand?: boolean; // for menu
  active?: boolean; // for menu
  properties?: IDocumentProperties;
  date?: string;
  description: string;
}

export interface IDocument {
  'entity-type': 'document';
  entries: IDocumentEntity[];
  aggregations?: any;
  currentPageIndex?: number;
  currentPageOffset?: number;
  currentPageSize?: number;
}

export interface IContributors {
  'entity-type': string;
  id: string;
  properties: {
    firstName: string;
    lastName: string;
    tenantId: any;
    groups: any[];
    company: string;
    email: string;
    username: string;
  };
  extendedGroups: any[];
  isPartial: boolean;
  isAdministrator: boolean;
  isAnonymous: boolean;
}

export interface IDocumentProperties {
  'common:icon-expanded': string | null;
  'common:icon': string | null;
  'dc:description': string | null;
  'dc:language': string | null;
  'dc:coverage': string | null;
  'dc:valid': string | null;
  'dc:creator': string | any;
  'dc:modified': string | null;
  'dc:lastContributor': string | null;
  'dc:rights': string | null;
  'dc:expired': string | null;
  'dc:format': string | null;
  'dc:created': string | null;
  'dc:title': string | null;
  'dc:issued': string | null;
  'dc:nature': string | null;
  'dc:subjects': string[] | null;
  'dc:contributors': string[] | any | IContributors;
  'dc:source': string | null;
  'dc:publisher': string | null;
  'uid:uid': string | null;
  'uid:major_version': number | null;
  'uid:minor_version': number | null;
  favorites: {
    isFavorite: boolean;
  };
}

export const DOCUMENT_PROPERTIES_QUERY_PARAMETER =
  'uid,image_metadata,file,common,dublincore,relatedtext,picture,facetedTag';

export const DOCUMENT_ENTRICHERS_QUERY_PARAMETER = 'audit,breadcrumb,thumbnail';
